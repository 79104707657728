import * as React from 'react';
import { ScreenSizeEnum } from '../../../models/screen-size-enum';
import { Icon } from '@iconify/react';
import {
  desktopHambergurItemGroups,
  mobileHambergurItemGroups,
  navItemGroups,
  tabletHambergurItemGroups,
} from './nav-item-groups';
import { Text } from '../../../components/text';
import HamburgerMenu from './hamburger-menu';
import { DownloadAppButton } from '../../buttons/download-app-button';
import { MenuButton } from './menu-button';
import { LogoButton } from './logo-button';
import { DESKTOP_NAVBAR_LINK_FONT_SIZE } from '../../../styles/styles';

type Props = {
  screenSize: ScreenSizeEnum;
};

export const NavbarSection: React.FC<Props> = ({ screenSize }) => {
  const [hoveredNavBarItemTitle, setHoveredNavBarItemTitle] = React.useState<string | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const openHamburgerMenu = () => {
    setIsOpen(true);
  };

  const closeHamburgerMenu = () => {
    setIsOpen(false);
  };

  return (
    <section style={{ position: 'sticky', top: 0, left: 0, zIndex: 9999 }}>
      <div
        style={{
          width: '100%',
          height: '65px',
          padding: screenSize === ScreenSizeEnum.Desktop ? '15px 70px' : '15px 20px',
          backgroundColor: 'white',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {screenSize === ScreenSizeEnum.Mobile && (
          <section
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'inherit',
            }}
          >
            <LogoButton />
            <MenuButton onClick={openHamburgerMenu} />
            <HamburgerMenu isOpen={isOpen} navItems={mobileHambergurItemGroups} closeMenu={closeHamburgerMenu} />
          </section>
        )}
        {screenSize === ScreenSizeEnum.Tablet && (
          <section
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'inherit',
            }}
          >
            <LogoButton />
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                gap: '14px',
              }}
            >
              <DownloadAppButton copy="Download App" width="205px" height="44px" />
              <MenuButton onClick={openHamburgerMenu} />
            </div>
            <HamburgerMenu isOpen={isOpen} navItems={tabletHambergurItemGroups} closeMenu={closeHamburgerMenu} />
          </section>
        )}
        {screenSize === ScreenSizeEnum.Desktop && (
          <section
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'inherit',
            }}
          >
            <LogoButton />
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              {navItemGroups.map((navItem) => (
                <a
                  key={navItem.title}
                  style={{
                    height: '32px',
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    flexShrink: 0,
                  }}
                  href={navItem.path}
                  target={navItem.newTab ? '_blank' : undefined}
                  onMouseEnter={() => setHoveredNavBarItemTitle(navItem.title)}
                  onMouseLeave={() => setHoveredNavBarItemTitle(null)}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <Text
                      tag="span"
                      fontFamily="NewAvertaPE"
                      fontWeight="400"
                      fontSize={`${DESKTOP_NAVBAR_LINK_FONT_SIZE}px`}
                      color="#4D4D4D"
                      lineHeight="1"
                    >
                      {navItem.title}
                    </Text>
                    {navItem.icon && (
                      <Icon icon={navItem.icon} color="#4D4D4D" style={{ width: '24px', height: '24px' }} />
                    )}
                  </div>
                </a>
              ))}
              <MenuButton onClick={openHamburgerMenu} />
            </div>
            <HamburgerMenu isOpen={isOpen} navItems={desktopHambergurItemGroups} closeMenu={closeHamburgerMenu} />
          </section>
        )}
      </div>
    </section>
  );
};
