import * as React from 'react';
import appStoreUrl from './app-store.webp';
import googlePlayUrl from './google-play.webp';

type Props = {
  buttonWidth: string;
  buttonHeight: string;
};

export function DownloadAppButtons({ buttonWidth, buttonHeight }: Props) {
  return (
    <section style={{ display: 'flex', flexFlow: 'row', gap: '18px' }}>
      <a href="https://itunes.apple.com/app/id448639966" target="__blank">
        <img alt="piccollage app store" src={appStoreUrl} width={buttonWidth} height={buttonHeight} />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.cardinalblue.piccollage.google" target="__blank">
        <img alt="piccollage google play" src={googlePlayUrl} width={buttonWidth} height={buttonHeight} />
      </a>
    </section>
  );
}
