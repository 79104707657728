import * as React from 'react';
import { PicColor, PicTag } from '@cardinalblue/pic-collage-cms-ui';
import { AccessTypeEnum } from '../../../models/access-type-enum';

type Props = {
  accessType: AccessTypeEnum;
};

export function PriceTag({ accessType }: Props) {
  let priceTag = <></>;
  if (accessType === AccessTypeEnum.Free) {
    priceTag = <PicTag copy="FREE" color={PicColor.Pink500} />;
  } else if (accessType === AccessTypeEnum.Subscription) {
    priceTag = <PicTag copy="VIP" color={PicColor.Yellow500} />;
  } else if (accessType === AccessTypeEnum.Iap) {
    priceTag = <PicTag copy="IAP" color={PicColor.Blue500} />;
  }

  return priceTag;
}
