import * as React from 'react';
import { CommonContextProvider } from '../features/common/contexts/common-context';
import { StartFeedCardDto, parseStartFeedCardDto } from '../features/start-feed-cards/dtos/start-feed-card-dto';
import { ActionableHeader } from '../features/common/components/headers/actionable-header';
import { PicButton, PicConfirmer, PicDivider, PicLink, PicText } from '@cardinalblue/pic-collage-cms-ui';
import { StartFeedCardApi } from '../features/start-feed-cards/apis/start-feed-card-api';
import { CountryDto, parseCountryDto } from '../features/common/dtos/country-dto';
import { ShowStartFeedCardPanel } from '../features/start-feed-cards/components/show-start-feed-card-panel';
import { dispatchPersistedStartFeedCardModel } from '../features/start-feed-cards/models/utils';
import { StartFeedCardTypeEnum } from '../features/start-feed-cards/models/start-feed-card-type-enum';
import { ShowLinkStartFeedCardPanel } from '../features/start-feed-cards/components/show-link-start-feed-card-panel';
import { ShowTemplateCategoryStartFeedCardPanel } from '../features/start-feed-cards/components/show-template-category-start-feed-card-panel';
import { TemplateTagDto, parseTemplateTagDto } from '../features/common/dtos/template-tag-dto';

type Props = {
  formAuthenticityToken: string;
  countryDtos: CountryDto[];
  startFeedCardDto: StartFeedCardDto;
  templateTagDtos: TemplateTagDto[];
};

const ShowStartFeedCardPageContent: React.FC<Props> = ({
  formAuthenticityToken,
  countryDtos,
  startFeedCardDto,
  templateTagDtos,
}) => {
  const countries = React.useMemo(() => countryDtos.map((dto) => parseCountryDto(dto)), [countryDtos]);
  const templateTags = React.useMemo(() => templateTagDtos.map((dto) => parseTemplateTagDto(dto)), [templateTagDtos]);

  const picConfirmer = React.useMemo(() => new PicConfirmer(), []);

  const startFeedCardApi = React.useMemo(() => StartFeedCardApi.create(), []);
  const startFeedCard = React.useMemo(
    () => parseStartFeedCardDto(countries, startFeedCardDto),
    [countries, startFeedCardDto],
  );

  const [isDeletingStartFeedCard, setIsDeletingStartFeedCard] = React.useState(false);
  const handleDeleteStartFeedCardClick = React.useCallback(async () => {
    const [confirmed, close] = await picConfirmer.pop({
      title: 'Delete Start Feed Card',
      message: `Are you sure you want to delete the start feed card "${startFeedCard.getTitle()}"?`,
    });
    if (!confirmed) {
      close();
      return;
    }

    setIsDeletingStartFeedCard(true);
    const error = await startFeedCardApi.delete(formAuthenticityToken, startFeedCard.getId());
    if (error) {
      close();
      return;
    }

    window.location.href = '/admin/start_feed_cards';
  }, [formAuthenticityToken, startFeedCardApi]);

  return (
    <div>
      <PicLink
        copy="< Back"
        onClick={() => {
          window.location.href = '/admin/start_feed_cards';
        }}
      />
      <ActionableHeader
        title="Start Feed Card"
        tags={[startFeedCard.getType()]}
        ctaButtons={
          <>
            <PicButton
              type="primary"
              outlined={false}
              copy="Edit"
              onClick={() => {
                window.location.href = `/admin/start_feed_cards/${startFeedCard.getId()}/edit`;
              }}
              disabled={isDeletingStartFeedCard}
            />
            <PicButton
              type="primary"
              outlined={false}
              copy="Clone"
              onClick={() => {
                window.location.href = `/admin/start_feed_cards/${startFeedCard.getId()}/clone`;
              }}
            />
            <PicButton
              type="primary"
              outlined
              copy="Delete"
              onClick={handleDeleteStartFeedCardClick}
              loading={isDeletingStartFeedCard}
            />
          </>
        }
      />
      <PicDivider marginTop="12px" marginBottom="12px" />
      <div style={{ display: 'flex', gap: '20px' }}>
        <div style={{ flexBasis: '50%' }}>
          <ShowStartFeedCardPanel startFeedCard={startFeedCard} />
        </div>
        <div style={{ flexBasis: '50%' }}>
          {dispatchPersistedStartFeedCardModel(startFeedCard, {
            [StartFeedCardTypeEnum.Link]: (model) => <ShowLinkStartFeedCardPanel linkStartFeedCard={model} />,
            [StartFeedCardTypeEnum.TemplateCategory]: (model) => (
              <ShowTemplateCategoryStartFeedCardPanel
                templateTags={templateTags}
                templateCategroyStartFeedCard={model}
              />
            ),
          })}
        </div>
      </div>
    </div>
  );
};

const ShowStartFeedCardPage: React.FC<Props> = (props) => {
  return (
    <CommonContextProvider>
      <ShowStartFeedCardPageContent {...props} />
    </CommonContextProvider>
  );
};

export default ShowStartFeedCardPage;
