import * as React from 'react';

type Props = {
  value: string;
  options: string[];
  onClick: (val: string) => void;
};

export function CarousellTab({ value, options, onClick }: Props) {
  return (
    <div
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        gap: '8px',
      }}
    >
      {options.map((option) => (
        <button
          key={option}
          onClick={() => onClick(option)}
          style={{
            width: value === option ? '14px' : '8px',
            height: '8px',
            borderRadius: '9999px',
            backgroundColor: value === option ? '#4FC3C4' : '#D1D5DB',
            transition: 'all 300ms',
            border: 'none',
            padding: 0,
            cursor: 'pointer',
          }}
        />
      ))}
    </div>
  );
}
