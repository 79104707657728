import * as React from 'react';
import { PicField } from '@cardinalblue/pic-collage-cms-ui';

type Props = {
  title: string;
};

export function PlainPagePanel({ title, children }: React.PropsWithChildren<Props>) {
  return <PicField label={title}>{children}</PicField>;
}
