import * as React from 'react';
import { ScreenSizeEnum } from '../../../models/screen-size-enum';
import { TitleSection } from './title-section';
import { ReviewScroller } from './review-scroller';
import {
  DESKTOP_TITLE_EMPHASIZE_FONT_SIZE,
  DESKTOP_TITLE_FONT_SIZE,
  DESKTOP_TITLE_LINE_HEIGHT,
  MOBILE_SIDE_PADDING,
  SECTION_GAP,
  TABLET_SIDE_PADDING,
} from '../../../styles/styles';

type Props = {
  screenSize: ScreenSizeEnum;
};

export const ReviewsSection: React.FC<Props> = ({ screenSize }) => {
  return (
    <div>
      {screenSize === ScreenSizeEnum.Desktop && (
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            padding: `${SECTION_GAP / 2}px 0 ${SECTION_GAP}px`,
          }}
        >
          <TitleSection
            fontSize={`${DESKTOP_TITLE_FONT_SIZE}px`}
            lineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
            emphasisFontSize={`${DESKTOP_TITLE_EMPHASIZE_FONT_SIZE}px`}
            emphasisLineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
            breakLines
          />
          <div style={{ marginTop: '92px', width: '100vw' }}>
            <ReviewScroller cardWidth={390} />
          </div>
        </div>
      )}
      {screenSize === ScreenSizeEnum.Tablet && (
        <div
          style={{
            padding: `${SECTION_GAP / 2}px ${TABLET_SIDE_PADDING}px ${SECTION_GAP}px`,
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          <TitleSection
            fontSize="32px"
            lineHeight="48px"
            emphasisFontSize="36px"
            emphasisLineHeight="48px"
            breakLines
          />
          <div style={{ marginTop: '73px', width: '100vw' }}>
            <ReviewScroller cardWidth={390} />
          </div>
        </div>
      )}
      {screenSize === ScreenSizeEnum.Mobile && (
        <div
          style={{
            padding: `${SECTION_GAP / 2}px ${MOBILE_SIDE_PADDING}px ${SECTION_GAP}px`,
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          <TitleSection
            fontSize="24px"
            lineHeight="35px"
            emphasisFontSize="28px"
            emphasisLineHeight="35px"
            breakLines
          />
          <div style={{ marginTop: '68px', width: '100vw' }}>
            <ReviewScroller cardWidth={390} />
          </div>
        </div>
      )}
    </div>
  );
};
