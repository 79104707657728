import * as React from 'react';
import { images } from './images';

type Props = {
  onClick: () => void;
};

export const MenuButton: React.FC<Props> = ({ onClick }) => {
  const [hovered, setHovered] = React.useState<boolean>(false);

  return (
    <button
      style={{ display: 'inline-flex', justifyContent: 'center', outline: 'none', border: 'none', background: 'none' }}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img src={images.menuIconWebp} style={{ width: '24px', height: '24px' }} />
    </button>
  );
};
