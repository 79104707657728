import * as React from 'react';
import { PicColor, PicIcon, PicIconName } from '@cardinalblue/pic-collage-cms-ui';

type Props = {
  height: string;
};

export function LoadingSection({ height }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height,
      }}
    >
      <PicIcon size="24px" name={PicIconName.Processing} color={PicColor.Teal500} />
    </div>
  );
}
