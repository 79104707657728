import * as React from 'react';
import { ScreenSizeEnum } from '../../../models/screen-size-enum';
import { DownloadAppButtons } from './download-app-buttons/index';
import { SocialsSection } from './socials-section';
import { PatentSection } from './patent-section';
import { LinksSection } from './links-section';
import background from './background.png';

type Props = {
  screenSize: ScreenSizeEnum;
};

export const FooterSection: React.FC<Props> = ({ screenSize }) => {
  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}>
      {screenSize === ScreenSizeEnum.Desktop && (
        <div
          style={{ padding: '127px 45px 46px 50px', display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}
        >
          <div style={{ padding: '12px 0 0', display: 'flex', flexFlow: 'column', justifyContent: 'space-between' }}>
            <LinksSection width="360px" flexFlow="row" justifyContent="space-between" />
            <PatentSection />
          </div>
          <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-end', gap: '84px' }}>
            <DownloadAppButtons buttonWidth="165px" buttonHeight="54px" gap="24px" />
            <SocialsSection size="50px" gap="24px" />
          </div>
        </div>
      )}
      {screenSize === ScreenSizeEnum.Tablet && (
        <div style={{ padding: '80px 20px 20px', display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
          <div style={{ padding: '11px 0 0', display: 'flex', flexFlow: 'column', justifyContent: 'space-between' }}>
            <LinksSection width="320px" flexFlow="row" justifyContent="space-between" />
            <PatentSection />
          </div>
          <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-end', gap: '84px' }}>
            <DownloadAppButtons buttonWidth="136px" buttonHeight="45px" gap="12px" />
            <SocialsSection size="40px" gap="8px" />
          </div>
        </div>
      )}
      {screenSize === ScreenSizeEnum.Mobile && (
        <div
          style={{
            padding: '80px 40px 20px',
            display: 'flex',
            flexFlow: 'column-reverse',
            gap: '50px',
          }}
        >
          <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', gap: '50px' }}>
            <LinksSection width="100%" flexFlow="column" gap="8px" />
            <PatentSection />
          </div>
          <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', gap: '50px' }}>
            <DownloadAppButtons buttonWidth="165px" buttonHeight="54px" gap="12px" />
            <SocialsSection size="40px" gap="8px" />
          </div>
        </div>
      )}
    </div>
  );
};
