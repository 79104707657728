import * as React from 'react';
import { Text } from '../../../text';

type Props = {
  title: string;
  titleFontSize: string;
  titleLineHeight: string;
  titleTextAlign?: 'center';
  hasTitleIndex?: boolean;
  description: string;
  descriptionFontSize: string;
  descriptionLineHeightInPixel: number;
  descriptionTextAlign?: 'center';
  hasDescription?: boolean;
  titleDescriptionGap: string;
  onClick?: () => void;
};

export function CardInfoSection({
  title,
  titleFontSize,
  titleLineHeight,
  titleTextAlign,
  hasTitleIndex,
  description,
  descriptionFontSize,
  descriptionLineHeightInPixel,
  descriptionTextAlign,
  hasDescription,
  titleDescriptionGap,
  onClick,
}: Props) {
  return (
    <div
      role="button"
      style={{ display: 'flex', flexFlow: 'column', gap: titleDescriptionGap, cursor: 'pointer' }}
      onClick={onClick}
    >
      <div style={{ position: 'relative' }}>
        {hasTitleIndex && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '-12px',
              width: '7px',
              height: '24px',
              borderRadius: '24px',
              background: '#4FC3C4',
              transform: 'translateX(-100%) translateY(-50%)',
            }}
          />
        )}
        <Text
          tag="h2"
          fontFamily="NewAvertaPE"
          fontSize={titleFontSize}
          fontWeight="bold"
          color="#4FC3C4"
          lineHeight={titleLineHeight}
          textAlign={titleTextAlign}
        >
          {title}
        </Text>
      </div>
      <div
        style={{
          opacity: hasDescription ? 1 : 0,
          height: hasDescription ? descriptionLineHeightInPixel * 4 : 0,
          transition: 'all 0.3s',
          overflow: 'hidden',
        }}
      >
        <Text
          tag="p"
          fontFamily="NewAvertaPE"
          fontSize={descriptionFontSize}
          fontWeight="400"
          color="#666666"
          lineHeight={`${descriptionLineHeightInPixel}px`}
          textAlign={descriptionTextAlign}
        >
          {description}
        </Text>
      </div>
    </div>
  );
}
