import React from "react";
import PropTypes from "prop-types";

export const DownloadButtons = ({ buttons_info }) => (
  <nav className="app-download-links">
    {buttons_info.map((info, idx) => (
      <a
        key={`download-btn-${idx}`}
        data-event-track-id={info.eventTrackId}
        data-ga-name={info.gaTagName}
        data-ga-category={info.gaCategory}
        data-ga-label={info.gaLabel}
        href={info.url}
      >
        <img src={info.imageSrc} alt={info.imageAlt} />
      </a>
    ))}
  </nav>
);

DownloadButtons.propTypes = {
  buttons_info: PropTypes.object,
};