import * as React from 'react';
import { PicDropdown, PicLink } from '@cardinalblue/pic-collage-cms-ui';
import { TemplateFilterVipStatusEnum } from '../models/template-filter-vip-status-enum';
import { TemplateFilterAnimatedTypeEnum } from '../models/template-filter-animated-type-enum';
import { TemplateFilterSizeCategoryEnum } from '../models/template-filter-size-category-enum';
import { TemplateFilterSlotCountEnum } from '../models/template-filter-slot-count-enum';
import { TemplateOrderEnum } from '../models/template-order-enum';
import { TemplateFilterSettingsVo } from '../models/template-filter-settings-vo';

const generateSlotsCountItems = (maxSlotCount: number) => {
  const showingMaxSlotCount = 4;
  const defaultSlotsCountItem = [{ label: 'All', value: TemplateFilterSlotCountEnum.All }];
  let slotsCountItem = defaultSlotsCountItem;
  if (maxSlotCount !== 0) {
    for (let i = 1; i <= Math.min(maxSlotCount, showingMaxSlotCount); i++) {
      slotsCountItem.push({ label: `${i} slot`, value: i });
    }
  }
  if (maxSlotCount > showingMaxSlotCount) {
    slotsCountItem.push({ label: '5+ slot', value: TemplateFilterSlotCountEnum.FivePlus });
  }
  return slotsCountItem;
};

export const templateFilterConfig = (maxSlotCount: number) => {
  return {
    vipStatus: [
      {
        title: '',
        items: [
          { label: 'All', value: TemplateFilterVipStatusEnum.All },
          { label: 'VIP Only', value: TemplateFilterVipStatusEnum.VipOnly },
          { label: 'Free Only', value: TemplateFilterVipStatusEnum.FreeOnly },
        ],
      },
    ],
    animatedType: [
      {
        title: '',
        items: [
          { label: 'All', value: TemplateFilterAnimatedTypeEnum.All },
          { label: 'Animated', value: TemplateFilterAnimatedTypeEnum.Animated },
          { label: 'Still', value: TemplateFilterAnimatedTypeEnum.Still },
        ],
      },
    ],
    sizeCategory: [
      {
        title: '',
        items: [
          { label: 'All', value: TemplateFilterSizeCategoryEnum.All },
          { label: 'Square', value: TemplateFilterSizeCategoryEnum.Square },
          { label: 'Story', value: TemplateFilterSizeCategoryEnum.Story },
          { label: 'Portrait', value: TemplateFilterSizeCategoryEnum.Portrait },
          { label: 'Landscape', value: TemplateFilterSizeCategoryEnum.Landscape },
        ],
      },
    ],
    slotsCount: [
      {
        title: '',
        items: generateSlotsCountItems(maxSlotCount),
      },
    ],
  };
};

const templateOrderConfig = () => ({
  order: [
    {
      title: '',
      items: [
        { label: 'All', value: TemplateOrderEnum.Default },
        { label: 'Created (Old -> New)', value: TemplateOrderEnum.CreatedAtAsc },
        { label: 'Created (New -> Old)', value: TemplateOrderEnum.CreatedAtDesc },
        { label: 'Updated (Old -> New)', value: TemplateOrderEnum.UpdatedAtAsc },
        { label: 'Updated (New -> Old)', value: TemplateOrderEnum.UpdatedAtDesc },
      ],
    },
  ],
});

type Props = {
  templateFilterSetting: TemplateFilterSettingsVo;
  onTemplateFilterSettingUpdate: (templateFilterSettings: TemplateFilterSettingsVo) => void;
  templateOrder: TemplateOrderEnum;
  onTemplateOrderUpdate: (templateOrderEnum: TemplateOrderEnum) => void;
  maxSlotCount: number;
};

export function TemplateFilterOrderController({
  templateFilterSetting,
  onTemplateFilterSettingUpdate,
  templateOrder,
  onTemplateOrderUpdate,
  maxSlotCount,
}: Props) {
  return (
    <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', flexFlow: 'row', gap: '4px', alignItems: 'center' }}>
        <div style={{ width: '140px' }}>
          <PicDropdown
            label="Vip Status"
            itemGroups={templateFilterConfig(maxSlotCount).vipStatus}
            value={templateFilterSetting.getVipStatus()}
            itemLabelGetter={(item) => item.label}
            itemValueGetter={(item) => item.value}
            onSelect={(item) => {
              onTemplateFilterSettingUpdate(templateFilterSetting.updateVipStatus(item.value));
            }}
          />
        </div>
        <div style={{ width: '140px' }}>
          <PicDropdown
            label="Animated Type"
            itemGroups={templateFilterConfig(maxSlotCount).animatedType}
            value={templateFilterSetting.getAnimatedType()}
            itemLabelGetter={(item) => item.label}
            itemValueGetter={(item) => item.value}
            onSelect={(item) => {
              onTemplateFilterSettingUpdate(templateFilterSetting.updateAnimatedType(item.value));
            }}
          />
        </div>
        <div style={{ width: '200px' }}>
          <PicDropdown
            label="Size Category"
            itemGroups={templateFilterConfig(maxSlotCount).sizeCategory}
            value={templateFilterSetting.getSizeCategory()}
            itemLabelGetter={(item) => item.label}
            itemValueGetter={(item) => item.value}
            onSelect={(item) => {
              onTemplateFilterSettingUpdate(templateFilterSetting.updateSizeCategory(item.value));
            }}
          />
        </div>
        <div style={{ width: '160px' }}>
          <PicDropdown
            label="Slot Count"
            itemGroups={templateFilterConfig(maxSlotCount).slotsCount}
            value={templateFilterSetting.getSlotCount().toString()}
            itemLabelGetter={(item) => item.label}
            itemValueGetter={(item) => item.value.toString()}
            onSelect={(item) => {
              onTemplateFilterSettingUpdate(templateFilterSetting.updateSlotCount(item.value));
            }}
          />
        </div>
        <div style={{ paddingLeft: '4px' }}>
          <PicLink
            copy="Clear Filter"
            onClick={() => {
              onTemplateFilterSettingUpdate(TemplateFilterSettingsVo.createDefault());
            }}
          />
        </div>
      </div>
      <div style={{ width: '240px' }}>
        <PicDropdown
          label="Order"
          itemGroups={templateOrderConfig().order}
          value={templateOrder}
          itemLabelGetter={(item) => item.label}
          itemValueGetter={(item) => item.value}
          onSelect={(item) => {
            onTemplateOrderUpdate(item.value);
          }}
        />
      </div>
    </div>
  );
}
