import * as React from 'react';
import { CommonContextProvider } from '../features/common/contexts/common-context';
import { TemplateTagDto, parseTemplateTagDto } from '../features/common/dtos/template-tag-dto';
import { StartFeedCardDto, parseStartFeedCardDto } from '../features/start-feed-cards/dtos/start-feed-card-dto';
import { ActionableHeader } from '../features/common/components/headers/actionable-header';
import { PicButton, PicConfirmer, PicDivider, PicLink, PicNotifier, PicText } from '@cardinalblue/pic-collage-cms-ui';
import { EditStartFeedCardPanel } from '../features/start-feed-cards/components/edit-start-feed-card-panel';
import { StartFeedCardTypeEnum } from '../features/start-feed-cards/models/start-feed-card-type-enum';
import { dispatchNewStartFeedCardModel } from '../features/start-feed-cards/models/utils';
import { EditTemplateCategoryStartFeedCardPanel } from '../features/start-feed-cards/components/edit-template-category-start-feed-card-panel';
import { EditLinkStartFeedCardPanel } from '../features/start-feed-cards/components/edit-link-start-feed-card-panel';
import { StartFeedCardApi } from '../features/start-feed-cards/apis/start-feed-card-api';
import { CountryDto, parseCountryDto } from '../features/common/dtos/country-dto';
import { NewStartFeedCardModel } from '../features/start-feed-cards/models/new-start-feed-card-model';

type Props = {
  startFeedCardDto: StartFeedCardDto;
  countryDtos: CountryDto[];
  templateTagDtos: TemplateTagDto[];
};

const CloneStartFeedCardPageContent: React.FC<Props> = ({ startFeedCardDto, countryDtos, templateTagDtos }) => {
  const countries = React.useMemo(() => countryDtos.map((dto) => parseCountryDto(dto)), [countryDtos]);
  const templateTags = React.useMemo(() => templateTagDtos.map((dto) => parseTemplateTagDto(dto)), [templateTagDtos]);
  const sourceStartFeedCard = React.useMemo(() => parseStartFeedCardDto(countries, startFeedCardDto), []);
  const [startFeedCard, setStartFeedCard] = React.useState<NewStartFeedCardModel>(() =>
    sourceStartFeedCard.toNewStartFeedCard(),
  );

  const picConfirmer = React.useMemo(() => new PicConfirmer(), []);
  const picNotifier = React.useMemo(() => new PicNotifier(), []);

  const startFeedCardApi = React.useMemo(() => StartFeedCardApi.create(), []);
  const handleCreateClick = React.useCallback(async () => {
    const [confirmed, close] = await picConfirmer.pop({
      title: 'Create Start Feed Card',
      message: `Are you sure you want to save your changes to the start feed card "${startFeedCard.getTitle()}"?`,
    });
    if (!confirmed) {
      close();
      return;
    }

    const [error, newStartFeedCardId] = await startFeedCardApi.create(startFeedCard);
    if (error) {
      close();
      picNotifier.notify({
        type: 'error',
        message: error.message,
      });
      return;
    }

    window.location.href = `/admin/start_feed_cards/${newStartFeedCardId}`;
  }, [startFeedCardApi, startFeedCard, picConfirmer]);

  return (
    <div>
      <PicLink
        copy="< Back"
        onClick={() => {
          window.location.href = `/admin/start_feed_cards/${sourceStartFeedCard.getId()}`;
        }}
      />
      <ActionableHeader
        title="New Start Feed Card"
        tags={[startFeedCard.getType()]}
        ctaButtons={
          <>
            <PicButton
              type="primary"
              outlined={false}
              copy="Create"
              onClick={handleCreateClick}
              disabled={!startFeedCard.isComplete()}
            />
            <PicButton
              type="primary"
              outlined
              copy="Cancel"
              onClick={() => {
                window.location.href = '/admin/start_feed_cards';
              }}
            />
          </>
        }
      />
      <PicDivider marginTop="12px" marginBottom="12px" />
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          gap: '20px',
        }}
      >
        <div style={{ flexBasis: '50%' }}>
          <EditStartFeedCardPanel
            countries={countries}
            startFeedCard={startFeedCard}
            onStartFeedCardUpdate={(updatedNewStartFeedCard) => {
              setStartFeedCard(updatedNewStartFeedCard);
            }}
            startFeedCardCloner={(startFeedCard) => startFeedCard.clone()}
          />
        </div>
        <div style={{ flexBasis: '50%' }}>
          {dispatchNewStartFeedCardModel(startFeedCard, {
            [StartFeedCardTypeEnum.Link]: (model) => (
              <EditLinkStartFeedCardPanel
                linkStartFeedCard={model}
                onLinkStartFeedCardUpdate={(updatedNewStartFeedCard) => {
                  setStartFeedCard(updatedNewStartFeedCard);
                }}
                linkStartFeedCardCloner={(linkStartFeedCard) => linkStartFeedCard.clone()}
              />
            ),
            [StartFeedCardTypeEnum.TemplateCategory]: (model) => (
              <EditTemplateCategoryStartFeedCardPanel
                templateTags={templateTags}
                templateCategroyStartFeedCard={model}
                onTemplateCategoryStartFeedCardUpdate={(updatedNewStartFeedCard) => {
                  setStartFeedCard(updatedNewStartFeedCard);
                }}
                templateCategoryStartFeedCardCloner={(templateCategoryStartFeedCard) =>
                  templateCategoryStartFeedCard.clone()
                }
              />
            ),
          })}
        </div>
      </div>
    </div>
  );
};

const CloneStartFeedCardPage: React.FC<Props> = (props) => {
  return (
    <CommonContextProvider>
      <CloneStartFeedCardPageContent {...props} />
    </CommonContextProvider>
  );
};

export default CloneStartFeedCardPage;
