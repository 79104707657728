import {
  templatesImage,
  backgroundImage,
  stickersImage,
  fontsImage,
  templatesIcon,
  fontsIcon,
  stickersIcon,
  backgroundIcon,
} from './images';

export enum KeyMatricesCardNameEnum {
  Templates = 'Templates',
  Backgrounds = 'Backgrounds',
  Fonts = 'Fonts',
  Stickers = 'Stickers',
}

export const cards = [
  {
    image: templatesImage,
    imageWidth: 768,
    imageHeight: 324,
    iconImage: templatesIcon,
    title: KeyMatricesCardNameEnum.Templates,
    color: '#FFFAE8',
  },
  {
    image: backgroundImage,
    imageWidth: 496,
    imageHeight: 324,
    iconImage: backgroundIcon,
    title: KeyMatricesCardNameEnum.Backgrounds,
    color: '#FFE1DD',
  },
  {
    image: fontsImage,
    imageWidth: 496,
    imageHeight: 324,
    iconImage: fontsIcon,
    title: KeyMatricesCardNameEnum.Fonts,
    color: '#EAF3D4',
  },
  {
    image: stickersImage,
    imageWidth: 768,
    imageHeight: 324,
    iconImage: stickersIcon,
    title: KeyMatricesCardNameEnum.Stickers,
    color: '#E6EDFF',
  },
];
