import * as React from 'react';
import { Text } from '../../../text';
import { DESKTOP_DESCRIPTION_COLOR } from '../../../../styles/styles';

type Props = {
  title: string;
  titleFontSize: string;
  titleLineHeight: string;
  description: string;
  descriptionFontSize: string;
  descriptionLineHeight: string;
  textAlign?: 'center';
  gap: string;
};

export function TitleSection({
  title,
  titleFontSize,
  titleLineHeight,
  description,
  descriptionFontSize,
  descriptionLineHeight,
  textAlign,
  gap,
}: Props) {
  return (
    <section
      style={{
        position: 'relative',
        display: 'flex',
        flexFlow: 'column',
        gap,
      }}
    >
      <Text
        tag="h1"
        fontFamily="NewAvertaPE"
        lineHeight={titleLineHeight}
        fontSize={titleFontSize}
        fontWeight="bold"
        background="linear-gradient(87.36deg, #8235B8 -9.23%, #974DCB 16.56%, #EF4967 73.21%, #EE604D 91.93%)"
        textFillColor="transparent"
        backgroundClip="text"
        textAlign={textAlign}
      >
        {title}
      </Text>
      <Text
        tag="span"
        fontFamily="NewAvertaPE"
        lineHeight={descriptionLineHeight}
        fontSize={descriptionFontSize}
        fontWeight="400"
        color={DESKTOP_DESCRIPTION_COLOR}
        textAlign={textAlign}
      >
        {description}
      </Text>
    </section>
  );
}
