import * as React from 'react';
import { CommonContextProvider } from '../features/common/contexts/common-context';
import { TemplateTagDto, parseTemplateTagDto } from '../features/common/dtos/template-tag-dto';
import { StartFeedCardDto, parseStartFeedCardDto } from '../features/start-feed-cards/dtos/start-feed-card-dto';
import { ActionableHeader } from '../features/common/components/headers/actionable-header';
import { PicButton, PicConfirmer, PicDivider, PicLink, PicNotifier } from '@cardinalblue/pic-collage-cms-ui';
import { EditStartFeedCardPanel } from '../features/start-feed-cards/components/edit-start-feed-card-panel';
import { StartFeedCardTypeEnum } from '../features/start-feed-cards/models/start-feed-card-type-enum';
import { dispatchPersistedStartFeedCardModel } from '../features/start-feed-cards/models/utils';
import { EditTemplateCategoryStartFeedCardPanel } from '../features/start-feed-cards/components/edit-template-category-start-feed-card-panel';
import { EditLinkStartFeedCardPanel } from '../features/start-feed-cards/components/edit-link-start-feed-card-panel';
import { StartFeedCardApi } from '../features/start-feed-cards/apis/start-feed-card-api';
import { CountryDto, parseCountryDto } from '../features/common/dtos/country-dto';
import { PersistedLinkStartFeedCardModel } from '../features/start-feed-cards/models/link-start-feed-cards/persisted-link-start-feed-card-model';
import { PersistedTemplateCategoryStartFeedCardModel } from '../features/start-feed-cards/models/template-category-start-feed-cards/persisted-template-category-start-feed-card-model';

type Props = {
  startFeedCardDto: StartFeedCardDto;
  countryDtos: CountryDto[];
  templateTagDtos: TemplateTagDto[];
};

const EditStartFeedCardPageContent: React.FC<Props> = ({ startFeedCardDto, countryDtos, templateTagDtos }) => {
  const countries = React.useMemo(() => countryDtos.map((dto) => parseCountryDto(dto)), [countryDtos]);
  const templateTags = React.useMemo(() => templateTagDtos.map((dto) => parseTemplateTagDto(dto)), [templateTagDtos]);
  const [startFeedCard, setStartFeedCard] = React.useState(() => parseStartFeedCardDto(countries, startFeedCardDto));

  const picConfirmer = React.useMemo(() => new PicConfirmer(), []);
  const picNotifier = React.useMemo(() => new PicNotifier(), []);

  const startFeedCardApi = React.useMemo(() => StartFeedCardApi.create(), []);
  const handleEditStartFeedCardConfirm = React.useCallback(async () => {
    const [confirmed, close] = await picConfirmer.pop({
      title: 'Save Changes',
      message: `Are you sure you want to save your changes to the start feed card "${startFeedCard.getTitle()}"?`,
    });
    if (!confirmed) {
      close();
      return;
    }

    const error = await startFeedCardApi.update(startFeedCard);
    if (error) {
      close();
      picNotifier.notify({
        type: 'error',
        message: error.message,
      });
      return;
    }

    window.location.href = `/admin/start_feed_cards/${startFeedCard.getId()}`;
  }, [startFeedCardApi, startFeedCard]);

  return (
    <div>
      <PicLink
        copy="< Back"
        onClick={() => {
          window.location.href = `/admin/start_feed_cards/${startFeedCard.getId()}`;
        }}
      />
      <ActionableHeader
        title="Start Feed Card"
        tags={[startFeedCard.getType()]}
        ctaButtons={
          <>
            <PicButton
              type="primary"
              outlined={false}
              copy="Save"
              onClick={handleEditStartFeedCardConfirm}
              disabled={!startFeedCard.isComplete()}
            />
            <PicButton
              type="primary"
              outlined
              copy="Cancel"
              onClick={() => {
                window.location.href = `/admin/start_feed_cards/${startFeedCard.getId()}`;
              }}
            />
          </>
        }
      />
      <PicDivider marginTop="12px" marginBottom="12px" />
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          gap: '20px',
        }}
      >
        <div style={{ flexBasis: '50%' }}>
          <EditStartFeedCardPanel
            countries={countries}
            startFeedCard={startFeedCard}
            onStartFeedCardUpdate={(updatedNewStartFeedCard) => {
              setStartFeedCard(updatedNewStartFeedCard);
            }}
            startFeedCardCloner={(setStartFeedCard) => setStartFeedCard.clone()}
          />
        </div>
        <div style={{ flexBasis: '50%' }}>
          {dispatchPersistedStartFeedCardModel(startFeedCard, {
            [StartFeedCardTypeEnum.Link]: (model) =>
              model instanceof PersistedLinkStartFeedCardModel && (
                <EditLinkStartFeedCardPanel
                  linkStartFeedCard={model}
                  onLinkStartFeedCardUpdate={(updatedNewStartFeedCard) => {
                    setStartFeedCard(updatedNewStartFeedCard);
                  }}
                  linkStartFeedCardCloner={(linkStartFeedCard) => linkStartFeedCard.clone()}
                />
              ),
            [StartFeedCardTypeEnum.TemplateCategory]: (model) =>
              model instanceof PersistedTemplateCategoryStartFeedCardModel && (
                <EditTemplateCategoryStartFeedCardPanel
                  templateTags={templateTags}
                  templateCategroyStartFeedCard={model}
                  onTemplateCategoryStartFeedCardUpdate={(updatedNewStartFeedCard) => {
                    setStartFeedCard(updatedNewStartFeedCard);
                  }}
                  templateCategoryStartFeedCardCloner={(templateCategoryStartFeedCard) =>
                    templateCategoryStartFeedCard.clone()
                  }
                />
              ),
          })}
        </div>
      </div>
    </div>
  );
};

const EditStartFeedCardPage: React.FC<Props> = (props) => {
  return (
    <CommonContextProvider>
      <EditStartFeedCardPageContent {...props} />
    </CommonContextProvider>
  );
};

export default EditStartFeedCardPage;
