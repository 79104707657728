import { TemplateModel } from '../../common/models/template-model';
import { TemplateFilterSettingsVo } from '../models/template-filter-settings-vo';
import { TemplateFilterVipStatusEnum } from '../models/template-filter-vip-status-enum';
import { TemplateFilterAnimatedTypeEnum } from '../models/template-filter-animated-type-enum';
import { TemplateFilterSizeCategoryEnum } from '../models/template-filter-size-category-enum';
import { TemplateFilterSlotCountEnum } from '../models/template-filter-slot-count-enum';
import { TemplateOrderEnum } from '../models/template-order-enum';

export const filterTemplateWithTemplateFilterSettings = (
  template: TemplateModel,
  templateFilterSettings: TemplateFilterSettingsVo,
) => {
  return (
    filterWithVipStatus(template, templateFilterSettings.getVipStatus()) &&
    filterWithAnimatedType(template, templateFilterSettings.getAnimatedType()) &&
    filterWithSizeCategory(template, templateFilterSettings.getSizeCategory()) &&
    filterWithSlotCount(template, templateFilterSettings.getSlotCount())
  );
};

export const orderTemplatesWithTemplateFilterSettings = (
  templates: TemplateModel[],
  templateOrder: TemplateOrderEnum,
) => {
  return orderWithDate(templates, templateOrder);
};

function filterWithVipStatus(template: TemplateModel, vipStatus: TemplateFilterVipStatusEnum): boolean {
  if (vipStatus === TemplateFilterVipStatusEnum.All) return true;
  return vipStatus === TemplateFilterVipStatusEnum.VipOnly ? template.getIsVip() : !template.getIsVip();
}

function filterWithAnimatedType(template: TemplateModel, animatedType: TemplateFilterAnimatedTypeEnum): boolean {
  if (animatedType === TemplateFilterAnimatedTypeEnum.All) return true;
  return animatedType === TemplateFilterAnimatedTypeEnum.Animated
    ? template.getIsAnimated()
    : !template.getIsAnimated();
}

function filterWithSizeCategory(template: TemplateModel, sizeCategory: TemplateFilterSizeCategoryEnum): boolean {
  if (sizeCategory === TemplateFilterSizeCategoryEnum.All) return true;
  return template.getSizeCategory().toString() === sizeCategory;
}

function filterWithSlotCount(template: TemplateModel, slotCount: TemplateFilterSlotCountEnum): boolean {
  if (slotCount === TemplateFilterSlotCountEnum.All) return true;
  if (slotCount === TemplateFilterSlotCountEnum.FivePlus) return template.getSlotsCount() >= 5;
  else return template.getSlotsCount() === slotCount;
}

function orderWithDate(templates: TemplateModel[], order: TemplateOrderEnum): TemplateModel[] {
  if (order === TemplateOrderEnum.Default) return templates;
  return [...templates].sort((a, b) => {
    if (order === TemplateOrderEnum.CreatedAtAsc) {
      return a.getCreatedAt() > b.getCreatedAt() ? 1 : -1;
    } else if (order === TemplateOrderEnum.CreatedAtDesc) {
      return a.getCreatedAt() < b.getCreatedAt() ? 1 : -1;
    } else if (order === TemplateOrderEnum.UpdatedAtAsc) {
      return a.getUpdatedAt() > b.getUpdatedAt() ? 1 : -1;
    } else if (order === TemplateOrderEnum.UpdatedAtDesc) {
      return a.getUpdatedAt() < b.getUpdatedAt() ? 1 : -1;
    } else {
      return 0;
    }
  });
}
