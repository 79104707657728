import React from "react";
import PropTypes from "prop-types";

export const Footer = ({
  jobs_path,
  blog_path,
  partnership_path,
  facebook,
  instagram,
  twitter,
  pinterest,
  youtube,
  facebook_img,
  instagram_img,
  twitter_img,
  pinterest_img,
  youtube_img,
  footer_logo_img
}) => {
  const socialLinkData = [
    {
      img: instagram_img,
      href: instagram,
      alt: "instagram",
      gaTagName: "instagram_click",
      gaCategory: "instagram_opens",
      gaLabel: "social media"
    },
    {
      img: pinterest_img,
      href: pinterest,
      alt: "pinterest",
      gaTagName: "pinterest_click",
      gaCategory: "pinterest_opens",
      gaLabel: "social media"
    },
    {
      img: facebook_img,
      href: facebook,
      alt: "facebook",
      gaTagName: "facebook_click",
      gaCategory: "facebook_opens",
      gaLabel: "social media"
    },
    {
      img: youtube_img,
      href: youtube,
      alt: "youtube",
      gaTagName: "youtube_click",
      gaCategory: "youtube_opens",
      gaLabel: "social media"
    },
    {
      img: twitter_img,
      href: twitter,
      alt: "twitter",
      gaTagName: "twitter_click",
      gaCategory: "twitter_opens",
      gaLabel: "social media"
    }
  ];

  const companyLinkData = [
    {
      text: "Blog",
      href: blog_path,
      gaTagName: "down_blog_click",
      gaCategory: "blog_opens",
      gaLabel: "footer"
    },
    {
      text: "Careers",
      href: jobs_path,
      gaTagName: "down_careers_click",
      gaCategory: "careers_opens",
      gaLabel: "footer"
    },
    {
      text: "Contact Us",
      href: partnership_path,
      gaTagName: "down_partnerships_click",
      gaCategory: "partnerships_opens",
      gaLabel: "footer"
    }
  ];

  let socialLinks = socialLinkData.map((link, idx) => (
    <a
      key={`social-link-${idx}`}
      data-ga-name={link.gaTagName}
      data-ga-category={link.gaCategory}
      data-ga-label={link.gaLabel}
      target="_blank"
      rel="noopener noreferrer"
      href={link.href}>
      <img src={link.img} alt={link.alt} />
    </a>
  ));

  let companyLinks = companyLinkData.map((link, idx) => (
    <a
      key={`company-link-${idx}`}
      data-ga-name={link.gaTagName}
      data-ga-category={link.gaCategory}
      data-ga-label={link.gaLabel}
      target="_blank"
      rel="noopener noreferrer"
      href={link.href}>
      {link.text}
    </a>
  ));

  let currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="nav-group">
        <nav className="company-link">{companyLinks}</nav>
        <nav className="social-link">{socialLinks}</nav>
      </div>
      <p class="footer-copy-and-logo">
        <span>© {currentYear}</span>
        <img className="footer-logo" src={footer_logo_img} />
      </p>
    </footer>
  );
};

Footer.propTypes = {
  jobs_path: PropTypes.string,
  blog_path: PropTypes.string,
  partnership_path: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  twitter: PropTypes.string,
  pinterest: PropTypes.string,
  youtube: PropTypes.string
};
