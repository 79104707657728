import * as React from 'react';
import { NoContentSection } from '../../sections/no-content-section';
import { LoadingSection } from '../../sections/loading-section';

type Props = {
  loading: boolean;
  noContent: boolean;
  noContentCopy?: string;
};

export function ContentRenderer({
  loading,
  noContent,
  noContentCopy = 'There are no data',
  children,
}: React.PropsWithChildren<Props>) {
  let content: React.ReactNode = <></>;
  if (loading) content = <LoadingSection height="30px" />;
  else if (noContent) content = <NoContentSection message={noContentCopy} />;
  else content = children;

  return content;
}
