import * as React from 'react';
import { Text } from '../../../text';

type Props = {
  fontSize: string;
  lineHeight: string;
};

export function DescriptionSection({ fontSize, lineHeight }: Props) {
  return (
    <Text
      tag="span"
      fontFamily="NewAvertaPE"
      fontSize={fontSize}
      fontWeight="600"
      color="white"
      lineHeight={lineHeight}
      textAlign="center"
    >
      The easiest photo and video editing app to <br /> add magic to your treasured memories
    </Text>
  );
}
