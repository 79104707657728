import cardImage1 from './images/card-image-1.webp';
import cardImage2 from './images/card-image-2.webp';
import cardImage3 from './images/card-image-3.webp';

export const cardInfos = [
  {
    title: 'Intuitive Tools',
    description:
      "PicCollage's tools are built to be helpful, easy-to-learn, and so good that (after trying them once) you can't live without them!",
    imageSrc: cardImage1,
  },
  {
    title: 'Create With AI-Powered Magic',
    description:
      'Our promise to you - our AI tools will always act as an assistant to super-charge your creative ideas. They blend in seamlessly and never take the creative process out of your hands.',
    imageSrc: cardImage2,
  },
  {
    title: 'Endless Designs',
    description:
      'Our design library is updated weekly for all occasions big and small. Never run out of ideas with templates, stickers, backgrounds, and fonts for literally everything.',
    imageSrc: cardImage3,
  },
];
