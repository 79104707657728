import * as React from 'react';

/**
 * @returns The size of the current screen
 */
export function useWindowSize(): { width: number; height: number } | undefined {
  const [windowSize, setWindowSize] = React.useState<{ width: number; height: number } | undefined>(undefined);

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({ width: window.innerWidth || 0, height: window.innerHeight || 0 });
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
