import * as React from 'react';
import { reviews } from '../reviews';
import { ReviewCard } from '../review-card';

type Props = {
  cardWidth: number;
};

export function ReviewScroller({ cardWidth }: Props) {
  const cardGap = 24;
  const cloneFactor = 4;
  const clonedReviews = React.useMemo(() => {
    let res: {
      title: string;
      date: string;
      reviewer: string;
      color: string;
      content: string;
    }[] = [];
    for (let i = 0; i < cloneFactor; i += 1) {
      res = res.concat(reviews);
    }
    return res;
  }, [cloneFactor, reviews]);

  const scrollerContentWidth = React.useMemo(
    () => clonedReviews.length * cardWidth + cardGap * (clonedReviews.length - 1),
    [cardGap, cardWidth, clonedReviews],
  );

  const [scrollerDom, setScrollerDom] = React.useState<HTMLElement | null>(null);
  const handleScrollerScroll = React.useCallback(() => {
    if (!scrollerDom) return;

    if (scrollerDom.scrollLeft < scrollerContentWidth / cloneFactor) {
      scrollerDom.scrollBy({ left: scrollerContentWidth / cloneFactor, behavior: 'instant' });
    } else if (scrollerDom.scrollLeft > scrollerContentWidth * (2 / cloneFactor)) {
      scrollerDom.scrollBy({ left: -scrollerContentWidth / cloneFactor, behavior: 'instant' });
    }
  }, [scrollerDom, scrollerContentWidth, cloneFactor]);

  React.useEffect(() => {
    if (!scrollerDom) return;

    scrollerDom.scrollTo({ left: scrollerContentWidth / cloneFactor, behavior: 'instant' });
  }, [scrollerDom, scrollerContentWidth]);

  const [contentRelativeLeft, setContentRelativeLeft] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setContentRelativeLeft((prev) => {
        if (prev - 2 < -scrollerContentWidth / cloneFactor) {
          return 0;
        }
        return prev - 2;
      });
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, [scrollerContentWidth, cloneFactor]);

  return (
    <div
      ref={setScrollerDom}
      style={{
        overflow: 'auto',
        scrollSnapType: 'x mandatory',
        scrollBehavior: 'smooth',
        WebkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
      onScroll={handleScrollerScroll}
    >
      <div
        style={{
          position: 'relative',
          left: `${contentRelativeLeft}px`,
          width: scrollerContentWidth,
          display: 'flex',
          flexFlow: 'row',
          gap: cardGap,
        }}
      >
        {clonedReviews.map((review, reviewIndex) => (
          <div key={`${review.title}-${reviewIndex}`} style={{ flexShrink: 0 }}>
            <ReviewCard
              width={cardWidth}
              title={review.title}
              date={review.date}
              color={review.color}
              reviewer={review.reviewer}
              content={review.content}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
