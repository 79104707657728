/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
import * as ActiveStorage from "@rails/activestorage"

// cocoon-vanilla-js https://github.com/oddcamp/cocoon-vanilla-js
// A vanilla JS replacement for (Rails) Cocoon's jQuery script
import "@oddcamp/cocoon-vanilla-js";

require.context("../legacy/assets", true)

var componentRequireContext = require.context("../pages", true)
var ReactRailsUJS = require("react_ujs")


ActiveStorage.start()
ReactRailsUJS.useContext(componentRequireContext)

import '../legacy/scripts/bootstrap'
import '../legacy/scripts/cb'
import '../legacy/scripts/util'

import '../legacy/stylesheets/index.scss'