import * as React from 'react';
import { Text } from '../../../text';
import starIcon from './star-icon.webp';

type Props = {
  title: string;
  date: string;
  color: string;
  reviewer: string;
  content: string;
  width: number;
};

export function ReviewCard({ width, title, date, color, reviewer, content }: Props) {
  return (
    <div
      style={{
        width,
        height: '280px',
        background: color,
        padding: '26px 20px',
        display: 'inline-flex',
        flexFlow: 'column',
        borderRadius: '16px',
        boxShadow: ' 0px 0px 12px 0px #E8E8E8',
      }}
    >
      <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-between', gap: '12px' }}>
          <Text tag="h2" lineHeight="22px" fontSize="18px" fontFamily="NewAvertaPE" fontWeight="600">
            {title}
          </Text>
          <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center', gap: '4px' }}>
            <img alt="review star 1" src={starIcon} width="12px" height="12px" />
            <img alt="review star 2" src={starIcon} width="12px" height="12px" />
            <img alt="review star 3" src={starIcon} width="12px" height="12px" />
            <img alt="review star 4" src={starIcon} width="12px" height="12px" />
            <img alt="review star 5" src={starIcon} width="12px" height="12px" />
          </div>
        </div>
        <div style={{ flexShrink: 0, display: 'flex', flexFlow: 'column', alignItems: 'flex-end', gap: '4px' }}>
          <Text tag="span" lineHeight="20px" fontSize="16px" fontFamily="NewAvertaPE" fontWeight="400">
            {date}
          </Text>
          <Text tag="span" lineHeight="20px" fontSize="16px" fontFamily="NewAvertaPE" fontWeight="400">
            {reviewer}
          </Text>
        </div>
      </div>
      <div style={{ marginTop: '24px' }}>
        <Text tag="p" lineHeight="20px" fontSize="16px" fontFamily="NewAvertaPE" fontWeight="400">
          {content}
        </Text>
      </div>
    </div>
  );
}
