import React from 'react';

export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          zIndex: '102',
          width: '100%',
          height: '65px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#FDFDFE',
        }}
      >
        <a
          style={{
            width: '265px',
            height: '48px',
            borderRadius: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#4FC3C4',
            fontFamily: 'AvertaPE',
            fontSize: '16px',
            fontWeight: '600',
            color: 'white',
          }}
          data-event-track-id="sticky-footer-download-app-button"
          data-ga-name="cta_download_button_click"
          data-ga-category="mobile_store_opens"
          data-ga-label="cta"
          href={this.props.apps_flyer_one_link_path}
        >
          Download
        </a>
      </div>
    );
  }
}
