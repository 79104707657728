export class CountryModel {
  constructor(
    private id: number,
    private isoCode: string,
  ) {}

  static create(id: number, isoCode: string) {
    return new CountryModel(id, isoCode);
  }

  public getId(): number {
    return this.id;
  }

  public getIsoCode(): string {
    return this.isoCode;
  }
}
