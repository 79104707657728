export const reviews = [
  {
    title: 'LOVE, LOVE, LOVE, LOVE, LOVE this app!!! ❤️❤️❤️❤️❤️❤️',
    date: 'Sep 19',
    reviewer: 'Ithai',
    color: '#F8F8F8',
    content:
      'If I could give it 10 stars I would. My favorite thing to use it for is, count downs to annoy my friends with. I also love to use it for cards, posters, and SO much more! And they have so many cute backgrounds and stickers! So I completely suggest you get this app, no matter if you are, like me and aspire to be a graphic designer when your older, or just like to make fun cards.',
  },
  {
    title: 'Love it',
    date: 'Jun 28',
    reviewer: 'Amburleena',
    color: '#F8F8F8',
    content:
      'I do love this app and I use it so much. You can do a lot of different things with it. I even just edited a video for the first time on there.',
  },
  {
    title: 'Amazing, Five Stars all the Way',
    date: 'Aug 20',
    reviewer: 'Nicholasii',
    color: '#F8F8F8',
    content:
      "This app is amazing! I've been using it for a long time, and it's great for making cards and collages without any annoying ads. I always recommend it to my friends because it's so fun and easy to use.",
  },
  {
    title: 'Honest review: this is an awesome app',
    date: 'Aug 20',
    reviewer: 'Nicholasii',
    color: '#F8F8F8',
    content:
      "I love this app! My mom showed it to me and I downloaded it, and I love it! If I need to make a last minute birthday card, sometimes I use this. It celebrates so many holidays and has a bunch of cool templates too! It also supports lots of different causes, so that's cool. Anyways, it's really useful for making invitations or cards.",
  },
  {
    title: 'THANK YOU!!!!',
    date: 'Aug 20',
    reviewer: 'Nicholasii',
    color: '#F8F8F8',
    content:
      "I'm so happy I found this app after losing access to another one that went to a subscription model. This app gives me everything I need for family photos, and I love that I can buy features as I need them instead of paying monthly.",
  },
  {
    title: 'Easy to use.',
    date: 'Nov 3',
    reviewer: 'Lynnfrombarrie',
    color: '#F8F8F8',
    content: 'Give it a try. Have some fun.',
  },
  {
    title: 'Love It!',
    date: 'Oct 31',
    reviewer: 'LeiaDD13',
    color: '#F8F8F8',
    content: 'I make my kids bday invitation, Diwali greetings, etc here! Works out well!',
  },
  {
    title: 'So easy n fun',
    date: 'Oct 26',
    reviewer: 'FrannaA',
    color: '#F8F8F8',
    content: 'Love how easy it is to put a little image together. ♥️♥️♥️♥️♥️ Go to for digital invites!!!',
  },
  {
    title: 'I ♥️it!',
    date: 'Oct 28',
    reviewer: 'Joyjoyjoy2004',
    color: '#F8F8F8',
    content:
      'This is my absolute favorite app for making collages and editing pictures! Once I learned how to find images from the web and remove backgrounds, it became the only app I needed usually just use the free version a few months I have bought the pro version because of the cute font, but I usually end up going back to the free one',
  },
  {
    title: 'The best editing/collage making app',
    date: 'Oct 21',
    reviewer: 'BrentEandCarmenD',
    color: '#F8F8F8',
    content: 'This app is very user friendly. I love it!',
  },
  {
    title: 'Definitely worth the app!',
    date: 'Oct 9',
    reviewer: 'Asharoo',
    color: '#F8F8F8',
    content:
      'I totally forgot I had this app and I’m so glad I found it, I was easily able to get some pictures of me as a baby & my child to compare side by side. Saving to my phone is easy enough & uploading to social media 😃',
  },
];
