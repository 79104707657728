import React from "react";
import PropTypes from "prop-types";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      ishoverMenuBtn: false,
      ishoverMenuContent: false
    };
    this.navRef = React.createRef();
    this.toggleMenuContent = this.toggleMenuContent.bind(this);
    this.toggleMenuBtn = this.toggleMenuBtn.bind(this);
    this.logoRef = React.createRef();
  }
  toggleMenuBtn(value) {
    this.setState({ishoverMenuBtn: value});
  }
  toggleMenuContent(value) {
    this.setState({ishoverMenuContent: value});
  }
  updateDimensions(windowWidth) {
    const BREAK_POINT_1 = 900;
    if (windowWidth < BREAK_POINT_1) {
      return 2;
    }
    return 3;
  }

  render() {
    const {ishoverMenuBtn, ishoverMenuContent} = this.state;
    const outerLinkNum = this.updateDimensions(this.props.windowWidth);
    const {
      login_page_path,
      blog_path,
    } = this.props.paths;
    const {
      support,
      artwork_submit,
      company,
    } = this.props.external_paths;

    const links = [
      {
        text: "Login",
        href: login_page_path,
        gaTagName: "login_click",
        gaCategory: "login_page_opens",
        gaLabel: "header",
      },
      {
        text: "Blog",
        href: blog_path,
        gaTagName: "top_blog_click",
        gaCategory: "blog_opens",
        gaLabel: "header",
      },
      {
        text: "Support & FAQ",
        href: support,
        gaTagName: "top_faq_click",
        gaCategory: "faq_opens",
        gaLabel: "header",
      },
      {
        text: "Company",
        href: company,
        gaTagName: "company_click",
        gaCategory: "company_page_opens",
        gaLabel: "header",
      },
      {
        text: "Submit Artwork",
        href: artwork_submit,
        gaTagName: "submit_artwork_click",
        gaCategory: "submit_artwork_opens",
        gaLabel: "header"
      },
    ];

    const outerLinks = links.slice(0, outerLinkNum).map((link, idx) => {
      return (
        <li key={`outer-link-${idx}`}>
          <a
            data-ga-name={link.gaTagName}
            data-ga-category={link.gaCategory}
            data-ga-label={link.gaLabel}
            href={link.href}>
            {link.text}
          </a>
        </li>
      );
    });
    const innerLinks = links.slice(outerLinkNum).map((link, idx) => {
      return (
        <li key={`inner-link-${idx}`}>
          <a
            data-ga-name={link.gaTagName}
            data-ga-category={link.gaCategory}
            data-ga-label={link.gaLabel}
            href={link.href}>
            {link.text}
          </a>
        </li>
      );
    });

    const mobileInnerLinks = links.map((link, idx) => {
      return (
        <li key={`mobile-link-${idx}`}>
          <a
            data-ga-name={link.gaTagName}
            data-ga-category={link.gaCategory}
            data-ga-label={link.gaLabel}
            href={link.href}>
            {link.text}
          </a>
        </li>
      );
    });

    return (
      <>
        <header>
          <div className="desktop-view">
            <div className="logo-block">
              <h1 ref={this.logoRef}> Pic collage </h1>
              <h2
                className={
                  this.props.picCollageTextY >= 30
                    ? "fade-out"
                    : "fade-in"
                }
              />
            </div>
            <ul ref={this.navRef} className="nav">
              {outerLinks}
              <li>
                <a
                  onMouseEnter={() => this.toggleMenuBtn(true)}
                  onMouseLeave={() => this.toggleMenuBtn(false)}
                  className={`hamburger ${
                    ishoverMenuBtn || ishoverMenuContent
                      ? "menu-hover"
                      : ""
                  }`}
                  href="#">
                  <span />
                </a>
                <ul
                  className={
                    ishoverMenuBtn || ishoverMenuContent
                      ? "inner-nav inner-nav-open"
                      : "inner-nav"
                  }
                  onMouseEnter={() =>
                    this.toggleMenuContent(true)
                  }
                  onMouseLeave={() =>
                    this.toggleMenuContent(false)
                  }>
                  {innerLinks}
                </ul>
              </li>
            </ul>
          </div>
          <div className="mobile-view">
            <div className="navbar_inner">
              <div className="left-section">
                <div className="logo" />
                <div className="wordmark" />
              </div>
              <a
                className="open-btn"
                data-ga-name="hamburgermenu_click"
                data-ga-category="hamburgermenu_expands"
                data-ga-label="hamburger menu"
                onClick={() => {
                  this.setState({visible: true});
                }}>
                <span>Menu</span>
              </a>
            </div>
          </div>
        </header>
        <div
          className={
            this.state.visible
              ? "nav-menu-wrapper open"
              : "nav-menu-wrapper"
          }>
          <ul>{mobileInnerLinks}</ul>
          <a
            onClick={() => {
              this.setState({visible: false});
            }}
            className="close-btn">
            <span>close</span>
          </a>
        </div>
      </>
    );
  }
}

Header.propTypes = {
  windowWidth: PropTypes.number,
  paths: PropTypes.object,
  external_paths: PropTypes.object,
  picCollageTextY: PropTypes.number
};
