import * as React from 'react';
import { PicText } from '@cardinalblue/pic-collage-cms-ui';

type Props = {
  message: string;
};

export function NoContentSection({ message }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        border: '1px solid #c6c6c6',
        borderRadius: '4px',
        padding: '20px',
      }}
    >
      <PicText>{message}</PicText>
    </div>
  );
}
