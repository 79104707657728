import * as React from 'react';
import { PicColor, PicContainer, PicField } from '@cardinalblue/pic-collage-cms-ui';

type Props = {
  title?: string;
  column?: number;
};

export function ListDataPagePanel({ title, column = 1, children }: React.PropsWithChildren<Props>) {
  let wrapper = (children: React.ReactNode) => <>{children}</>;
  if (title) {
    wrapper = (children: React.ReactNode) => <PicField label={title}>{children}</PicField>;
  }

  return wrapper(
    <PicContainer background={PicColor.White}>
      <div
        style={{
          padding: '28px',
          display: 'grid',
          gridTemplateColumns: `repeat(${column}, 1fr)`,
          gap: '24px',
          borderRadius: '5px',
          boxShadow: `0px 0px 15px 0px rgba(0, 0, 0, 0.05)`,
        }}
      >
        {children}
      </div>
    </PicContainer>,
  );
}
