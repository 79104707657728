export class AppVersionVo {
  constructor(
    private major: number,
    private minor: number,
    private patch: number,
  ) {}

  static create(major: number, minor: number, patch: number) {
    return new AppVersionVo(major, minor, patch);
  }

  public getMajor(): number {
    return this.major;
  }

  public updateMajor(major: number): AppVersionVo {
    return new AppVersionVo(major, this.minor, this.patch);
  }

  public getMinor(): number {
    return this.minor;
  }

  public updateMinor(minor: number): AppVersionVo {
    return new AppVersionVo(this.major, minor, this.patch);
  }

  public getPatch(): number {
    return this.patch;
  }

  public updatePatch(patch: number): AppVersionVo {
    return new AppVersionVo(this.major, this.minor, patch);
  }

  public toNumbers(): [number, number, number] {
    return [this.major, this.minor, this.patch];
  }

  public toString(): string {
    return `${this.major}.${this.minor}.${this.patch}`;
  }
}
