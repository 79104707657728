import * as React from 'react';
import { images } from '../images';
import { getUsecaseImages, usecases } from '../usecases';
import { useWindowSize } from '../../../../hooks/use-window-size';

type UsecaseImagesMap = Record<string, { key: string; src: string }[]>;

type Props = {
  imageSize: {
    width: number;
    height: number;
  };
  imagePadding: number;
  phoneSize: {
    width: number;
    height: number;
  };
  scrollOffset: number;
  activeUsecaseKey: string;
};

const INITIAL_LEFT_FILLER_WIDTH = 100000;
const INITIAL_RIGHT_FILLER_WIDTH = 100000;

const UseCaseScroller: React.FC<Props> = ({ imageSize, imagePadding, phoneSize, scrollOffset, activeUsecaseKey }) => {
  const windowSize = useWindowSize();

  const activeUsecaseImages = React.useMemo(() => getUsecaseImages(activeUsecaseKey), [activeUsecaseKey]);
  const activeUsecaseImagesCount = React.useMemo(() => activeUsecaseImages.length, [activeUsecaseImages]);
  const getActiveUsecaseImageIndex = React.useCallback(
    (key: string) => activeUsecaseImages.findIndex((image) => image.key === key),
    [activeUsecaseImages],
  );

  const prolivedUsecaseImagesMap = React.useMemo<{ [usecaseKey: string]: { key: string; src: string }[] }>(() => {
    return usecases.reduce<UsecaseImagesMap>((sum, curr) => {
      sum[curr.key] = [...curr.images, ...curr.images, ...curr.images];
      return sum;
    }, {});
  }, []);
  const activeProlivedUsecaseImages = React.useMemo(
    () => prolivedUsecaseImagesMap[activeUsecaseKey],
    [prolivedUsecaseImagesMap, activeUsecaseKey],
  );

  const cardWidth = React.useMemo(() => imageSize.width, []);
  const cardHeight = React.useMemo(() => imageSize.height, []);
  const cardContainerPadding = React.useMemo(() => imagePadding, []);
  const cardContainerWidth = React.useMemo(
    () => cardWidth + cardContainerPadding * 2,
    [cardWidth, cardContainerPadding],
  );

  const [carousellDom, setCarousellDom] = React.useState<HTMLElement | null>(null);
  const [leftFillerWidth, setLeftFillerWidth] = React.useState(() => INITIAL_LEFT_FILLER_WIDTH);

  const initialCarousellScrollLeft = React.useMemo(() => {
    return (
      INITIAL_LEFT_FILLER_WIDTH +
      cardContainerWidth * activeUsecaseImagesCount -
      (scrollOffset - cardContainerWidth / 2)
    );
  }, [scrollOffset, cardContainerWidth, activeUsecaseImagesCount]);

  React.useEffect(() => {
    if (!windowSize) return;

    currentCarousellScrollLeft.current = initialCarousellScrollLeft;
    setLeftFillerWidth(INITIAL_LEFT_FILLER_WIDTH);
    scrollContainerByCardIndexOffset(0, 'instant');
  }, [windowSize, initialCarousellScrollLeft]);

  const currentCarousellScrollLeft = React.useRef(initialCarousellScrollLeft);

  const scrollContainerByCardIndexOffset = React.useCallback(
    (cardIndexOffset: number, behavior: 'instant' | 'smooth') => {
      if (!carousellDom) return;

      carousellDom.scrollTo({
        left: currentCarousellScrollLeft.current + cardIndexOffset * cardContainerWidth,
        behavior,
      });
    },
    [carousellDom, cardContainerWidth],
  );

  const generateUsecaseImageDataId = React.useCallback((usecaseImageKey: string) => {
    return `usecase-image-${usecaseImageKey}`;
  }, []);

  const scrollToNextUsecaseImage = React.useCallback(() => {
    const nextActiveUsecaseImageIndex = getActiveUsecaseImageIndex(activeUsecaseImageKey.current) + 1;
    scrollContainerByCardIndexOffset(nextActiveUsecaseImageIndex, 'smooth');
  }, [scrollContainerByCardIndexOffset, getActiveUsecaseImageIndex]);

  const prevActiveUsecaseImageDataId = React.useRef<string | null>(null);
  const activeUsecaseImageKey = React.useRef('');
  const activateUsecaseImage = React.useCallback((key: string) => {
    const dataId = generateUsecaseImageDataId(key);

    if (prevActiveUsecaseImageDataId.current) {
      const prevDataId = generateUsecaseImageDataId(prevActiveUsecaseImageDataId.current);
      // @ts-expect-error
      const prevActiveUsecaseImagesElems = document.querySelectorAll(`[data-id="${prevDataId}"]`) as HTMLElement[];
      prevActiveUsecaseImagesElems.forEach((prevActiveUsecaseImagesElem) => {
        prevActiveUsecaseImagesElem.style.transform = 'scale(1)';
        prevActiveUsecaseImagesElem.style.borderRadius = '20px';
      });
    }

    // @ts-expect-error
    const activeUsecaseImagesElems = document.querySelectorAll(`[data-id="${dataId}"]`) as HTMLElement[];
    activeUsecaseImagesElems.forEach((activeUsecaseImagesElem) => {
      activeUsecaseImagesElem.style.transform = 'scale(1.24)';
      activeUsecaseImagesElem.style.borderRadius = '0';
      prevActiveUsecaseImageDataId.current = key;
      activeUsecaseImageKey.current = key;
    });
  }, []);

  React.useEffect(() => {
    activateUsecaseImage(activeUsecaseImageKey.current);
  }, [activeUsecaseImages]);

  const carousellScrollStopTimeout = React.useRef<NodeJS.Timer | null>(null);
  const handleCarouselScroll = React.useCallback(() => {
    if (!carousellDom) return;

    if (carousellScrollStopTimeout.current) clearTimeout(carousellScrollStopTimeout.current);
    carousellScrollStopTimeout.current = setTimeout(() => {
      scrollToNextUsecaseImage();
    }, 1500);

    const carousellScrollLeftUsecaseImageIndexOffset = Math.round(
      (carousellDom.scrollLeft - currentCarousellScrollLeft.current) / cardContainerWidth,
    );

    if (carousellScrollLeftUsecaseImageIndexOffset >= activeUsecaseImagesCount) {
      currentCarousellScrollLeft.current =
        currentCarousellScrollLeft.current + cardContainerWidth * activeUsecaseImagesCount;
      setLeftFillerWidth((prev) => prev + cardContainerWidth * activeUsecaseImagesCount);
    } else if (carousellScrollLeftUsecaseImageIndexOffset < 0) {
      currentCarousellScrollLeft.current =
        currentCarousellScrollLeft.current - cardContainerWidth * activeUsecaseImagesCount;
      setLeftFillerWidth((prev) => prev - cardContainerWidth * activeUsecaseImagesCount);
    }

    const activeSourceUsecaseImageIndex =
      (carousellScrollLeftUsecaseImageIndexOffset + activeUsecaseImagesCount) % activeUsecaseImagesCount;
    activateUsecaseImage(activeUsecaseImages[activeSourceUsecaseImageIndex].key);
  }, [
    activateUsecaseImage,
    activeUsecaseImages,
    activeUsecaseImagesCount,
    cardContainerWidth,
    carousellDom,
    scrollToNextUsecaseImage,
  ]);

  React.useEffect(() => {
    if (!carousellDom) return;
    if (!scrollOffset) return;

    scrollContainerByCardIndexOffset(0, 'instant');
    activateUsecaseImage(prolivedUsecaseImagesMap[activeUsecaseKey][0].key);
  }, [carousellDom, scrollContainerByCardIndexOffset, scrollOffset]);

  return (
    <div style={{ position: 'relative', width: '100%', display: 'flex' }}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          transform: `translateX(calc(${scrollOffset}px - 50%)) translateY(-50%)`,
          zIndex: 2,
          pointerEvents: 'none',
          flexShrink: 0,
        }}
      >
        <img
          style={{
            position: 'relative',
            width: `${phoneSize.width}px`,
            height: `${phoneSize.height}px`,
            objectFit: 'cover',
            zIndex: 2,
          }}
          width={phoneSize.width}
          height={phoneSize.height}
          src={images.phoneMockup}
          alt="usecase phone mockup"
        />
        <img
          style={{
            position: 'absolute',
            left: '50%',
            bottom: '-47px',
            transform: 'translateX(-50%)',
            width: '500px',
            height: '98px',
            zIndex: 1,
          }}
          alt="hero mobile shadow"
          src={images.phoneShadow}
          width="500px"
          height="98px"
        />
      </div>
      <div
        ref={(ref) => {
          setCarousellDom(ref);
        }}
        style={{
          height: phoneSize.height,
          display: 'flex',
          alignItems: 'center',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          scrollBehavior: 'smooth',
          WebkitOverflowScrolling: 'touch',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
        onScroll={handleCarouselScroll}
      >
        <div style={{ flexShrink: 0, width: leftFillerWidth, height: imageSize.height }} />
        <div
          style={{
            position: 'relative',
            width: activeProlivedUsecaseImages.length * cardContainerWidth,
            height: imageSize.height,
          }}
        >
          {Object.entries(prolivedUsecaseImagesMap).map(([usecaseKey, usecaseImages]) => {
            return (
              <div
                key={usecaseKey}
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  display: 'flex',
                  flexFlow: 'row',
                  opacity: usecaseKey === activeUsecaseKey ? 1 : 0,
                  WebkitTransition: 'opacity 0.3s',
                }}
              >
                {usecaseImages.map((usecaseImage, usecaseImageIndex) => (
                  <div
                    key={`usecase-template-${usecaseImage.key}-${usecaseImageIndex}`}
                    style={{
                      flexShrink: '0',
                      width: `${cardContainerWidth}px`,
                      height: `${cardHeight}px`,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      data-id={generateUsecaseImageDataId(usecaseImage.key)}
                      style={{
                        width: `${cardWidth}px`,
                        height: `${cardHeight}px`,
                        filter: 'drop-shadow(0.927536px 0.927536px 9.27536px rgba(0, 0, 0, 0.1))',
                        WebkitTransition: 'transform 0.3s ease, border-radius 0.3s ease',
                        WebkitTransform: 'scale(1)',
                        borderRadius: '0',
                      }}
                      src={usecaseImage.src}
                      loading="eager"
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <div style={{ flexShrink: 0, width: INITIAL_RIGHT_FILLER_WIDTH }} />
      </div>
    </div>
  );
};

export default UseCaseScroller;
