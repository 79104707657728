export interface NavItem {
  title: string;
  path: string;
  icon?: string;
  newTab: boolean;
}

export const navItemGroups: NavItem[] = [
  { title: 'Support & FAQ', path: 'https://piccollage.zendesk.com/hc/en-us', newTab: true },
  { title: 'Try Web Editor', path: 'https://www.photocards.ai', newTab: true },
  {
    title: 'Download The App',
    path: 'https://apps.apple.com/us/app/piccollage-photo-video-editor/id448639966',
    icon: 'prime:mobile',
    newTab: true,
  },
];

export const desktopHambergurItemGroups: NavItem[] = [
  { title: 'Login', path: '/login_page', newTab: false },
  { title: 'Company', path: 'https://picc.co/', newTab: true },
  { title: 'Blog', path: 'https://blog.piccollage.com/', newTab: true },
];

export const tabletHambergurItemGroups: NavItem[] = [
  { title: 'Support & FAQ', path: 'https://piccollage.zendesk.com/hc/en-us', newTab: true },
  { title: 'Login', path: '/login_page', newTab: false },
  { title: 'Company', path: 'https://picc.co/', newTab: true },
  { title: 'Blog', path: 'https://blog.piccollage.com/', newTab: true },
];

export const mobileHambergurItemGroups: NavItem[] = [
  { title: 'Support & FAQ', path: 'https://piccollage.zendesk.com/hc/en-us', newTab: true },
  { title: 'Login', path: '/login_page', newTab: false },
  { title: 'Company', path: 'https://picc.co/', newTab: true },
  { title: 'Blog', path: 'https://blog.piccollage.com/', newTab: true },
];
