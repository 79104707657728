import * as React from 'react';
import { PicButton, PicReorderItemsPanel, PicText } from '@cardinalblue/pic-collage-cms-ui';
import { TemplateCategoryStartFeedCardModel } from '../models/template-category-start-feed-cards/template-category-start-feed-card-model';
import { TemplateTagModel } from '../../common/models/template-tag-model';
import { NewTemplateCategoryStartFeedCardChipModel } from '../models/template-category-start-feed-cards/template-category-start-feed-card-chips/new-template-category-start-feed-card-chip-model';
import { EditTemplateCategoryStartFeedCardChipPanel } from './edit-template-category-start-feed-card-chip-panel';
import { PlainPagePanel } from '../../common/components/panels/plain-page-panel';
import { TemplateCategoryStartFeedCardChipModel } from '../models/template-category-start-feed-cards/template-category-start-feed-card-chips/template-category-start-feed-card-chip-model';

type Props<T> = {
  templateTags: TemplateTagModel[];
  templateCategroyStartFeedCard: T;
  onTemplateCategoryStartFeedCardUpdate: (updatedStartFeedCard: T) => void;
  templateCategoryStartFeedCardCloner: (templateCategoryStartFeedCard: T) => T;
};

export function EditTemplateCategoryStartFeedCardPanel<T extends TemplateCategoryStartFeedCardModel>({
  templateTags,
  templateCategroyStartFeedCard,
  onTemplateCategoryStartFeedCardUpdate,
  templateCategoryStartFeedCardCloner,
}: Props<T>) {
  const cloneTemplateCategroyStartFeedCardToUpdate = React.useCallback(
    (cb: (clonedTemplateCategoryStartFeedCard: T) => T) => {
      const updatedClonedCard = cb(templateCategoryStartFeedCardCloner(templateCategroyStartFeedCard));
      onTemplateCategoryStartFeedCardUpdate(updatedClonedCard);
    },
    [templateCategroyStartFeedCard],
  );

  const chips = React.useMemo(() => templateCategroyStartFeedCard.getChips(), [templateCategroyStartFeedCard]);
  const [selectedChipKeys, setSelectedChipKeys] = React.useState<number[]>([]);

  const chipKeyGetter = React.useCallback(
    (chip: TemplateCategoryStartFeedCardChipModel) => chips.findIndex((c) => c === chip),
    [chips],
  );

  return (
    <div style={{ display: 'flex', flexFlow: 'column', gap: '12px' }}>
      <PlainPagePanel title="Chips">
        <PicReorderItemsPanel
          items={chips}
          column={1}
          itemKeyGetter={chipKeyGetter}
          itemRenderer={({ item, itemIndex }) => (
            <EditTemplateCategoryStartFeedCardChipPanel
              templateTags={templateTags}
              chip={item}
              chipIndex={itemIndex}
              removable={chips.length > 1}
              isNameRequired={chips.length > 1}
              onChipUpdate={(newChip) => {
                cloneTemplateCategroyStartFeedCardToUpdate((clonedTemplateCategoryStartFeedCard) => {
                  clonedTemplateCategoryStartFeedCard.updateChip(itemIndex, newChip);
                  return clonedTemplateCategoryStartFeedCard;
                });
              }}
              onRemoveClick={() => {
                cloneTemplateCategroyStartFeedCardToUpdate((clonedTemplateCategoryStartFeedCard) => {
                  clonedTemplateCategoryStartFeedCard.removeChip(itemIndex);
                  return clonedTemplateCategoryStartFeedCard;
                });
              }}
              chipCloner={(chip) => chip.clone()}
            />
          )}
          selectedItemKeys={selectedChipKeys}
          disableSelection={false}
          onSelectedItemKeysChange={setSelectedChipKeys}
          onItemsReorder={(chipsInNewOrder) => {
            cloneTemplateCategroyStartFeedCardToUpdate((clonedTemplateCategoryStartFeedCard) => {
              clonedTemplateCategoryStartFeedCard.updateChips(chipsInNewOrder);
              return clonedTemplateCategoryStartFeedCard;
            });
          }}
          onClickOutside={() => {
            setSelectedChipKeys([]);
          }}
        />
        {chips.length === 0 && <PicText>You have no chips yet</PicText>}
      </PlainPagePanel>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PicButton
          type="primary"
          outlined
          copy="Add Chip"
          onClick={() => {
            cloneTemplateCategroyStartFeedCardToUpdate((clonedTemplateCategoryStartFeedCard) => {
              clonedTemplateCategoryStartFeedCard.addChip(NewTemplateCategoryStartFeedCardChipModel.create('', null));
              return clonedTemplateCategoryStartFeedCard;
            });
          }}
        />
      </div>
    </div>
  );
}
