import * as React from 'react';
import { Text } from '../../../text';

interface Props {
  image: string;
  imageWidth: string;
  imageHeight: string;
  iconImage: string;
  iconSize: string;
  title: string;
  titleFontSize: string;
  titleLineHeight: string;
  titleTop: string;
  titleLeft: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  backgroundColor: string;
  borderRadius?: string;
}

export const KeyMatrixCard: React.FC<Props> = ({
  image,
  imageWidth,
  imageHeight,
  iconImage,
  iconSize,
  title,
  titleFontSize,
  titleLineHeight,
  titleTop,
  titleLeft,
  width,
  height,
  maxWidth,
  maxHeight,
  backgroundColor,
  borderRadius,
}) => {
  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
        maxWidth,
        maxHeight,
        borderRadius: borderRadius,
        overflow: 'hidden',
        background: backgroundColor,
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '0',
          bottom: '0',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          height={height}
          style={{
            height: height,
            objectFit: 'cover',
          }}
          src={image}
          alt="Key Natrices Card"
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: titleTop,
          left: titleLeft,
          objectFit: 'contain',
        }}
      >
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <Text
            fontSize={titleFontSize}
            fontFamily="NewAvertaPE"
            fontWeight="bold"
            lineHeight={titleLineHeight}
            textAlign="center"
            tag={'h3'}
          >
            {title}
          </Text>
          {iconImage && <img style={{ width: iconSize, height: iconSize }} src={iconImage} alt="Icon Image" />}
        </div>
      </div>
    </div>
  );
};
