import * as React from 'react';
import { Text } from '../../text';
import { navItemGroups } from '../../sections/navbar-section/nav-item-groups';
import downloadIconWebp from './images/download.webp';

type Props = {
  copy: string;
  width?: string;
  height?: string;
};

export const DownloadAppButton: React.FC<Props> = ({ copy, width, height }) => {
  const [hovered, setHovered] = React.useState(false);

  const gotoNavItemPage = (path: string) => {
    window.location.href = path;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: width,
        height: height,
        borderRadius: '30px',
        backgroundColor: '#4FC3C4',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
        gap: '10px',
        cursor: 'pointer',
      }}
      onClick={() => gotoNavItemPage(navItemGroups[4].path)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img src={downloadIconWebp} alt="cta download button icon" width="28px" height="28px" />
      <Text tag="span" fontFamily="NewAvertaPE" fontWeight="bold" fontSize="16px" color="white" lineHeight="1">
        {copy}
      </Text>
    </div>
  );
};
