import React from "react";
import PropTypes from "prop-types";
import {DownloadButtons} from "./downloadButtons.js";

export const TextBanner = ({
  headline,
  description = "",
  blockName = "",
  btn = null
}) => {
  return (
    <div className={`text-banner ${blockName}`}>
      <h4 dangerouslySetInnerHTML={{ __html: headline }} />
      {description.length > 0 && <p>{description}</p>}
      { btn && (
          <div className="download-block">
            <a
              data-event-track-id={btn.eventTrackId}
              data-ga-name={btn.gaTagName}
              data-ga-category={btn.gaCategory}
              data-ga-label={btn.gaLabel}
              className={btn.className}
              href={btn.link}>
              {btn.text}
            </a>
            <DownloadButtons
              buttons_info={btn.buttons_info}
            />
          </div>
        )
      }
    </div>
  );
};

TextBanner.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  blockName: PropTypes.string,
  btn: PropTypes.object
};
