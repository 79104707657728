import * as React from 'react';
import { KeyMatricesCardNameEnum, cards } from '../cards';
import { KeyMatrixCard } from '../key-matrix-card';
import { useWindowSize } from '../../../../hooks/use-window-size';
import { CarousellTab } from '../../../tabs/carousell-tab';

interface Props {}

const INITIAL_LEFT_FILLER_WIDTH = 100000;
const INITIAL_RIGHT_FILLER_WIDTH = 100000;

const KeyMatricesCarousell: React.FC<Props> = ({}: Props) => {
  const windowSize = useWindowSize();

  const cardWidth = React.useMemo(() => 680, []);
  const cardHeight = React.useMemo(() => 520, []);
  const cardContainerPadding = React.useMemo(() => 12, []);
  const cardContainerWidth = React.useMemo(
    () => cardWidth + cardContainerPadding * 2,
    [cardWidth, cardContainerPadding],
  );
  const cardsCount = React.useMemo(() => cards.length, []);

  const cloneFactor = 4;

  const cardsSectionWidth = React.useMemo(
    () => cards.length * cloneFactor * cardContainerWidth,
    [cloneFactor, cardContainerWidth],
  );

  const [carousellDom, setCarousellDom] = React.useState<HTMLElement | null>(null);

  const [leftFillerWidth, setLeftFillerWidth] = React.useState(() => INITIAL_LEFT_FILLER_WIDTH);

  const carousellScrollOffset = React.useMemo(() => {
    if (!windowSize) return;

    return (windowSize.width % cardContainerWidth) / 2;
  }, [windowSize, cardContainerWidth]);

  const initialCarousellScrollLeft = React.useMemo(() => {
    if (!carousellScrollOffset) return;

    return INITIAL_LEFT_FILLER_WIDTH + cardsCount * cardContainerWidth - carousellScrollOffset;
  }, [carousellScrollOffset, cardContainerWidth, cardsCount]);

  const currentCarousellScrollLeft = React.useRef(initialCarousellScrollLeft);

  const scrollCarousellByCardIndexOffset = React.useCallback(
    (cardIndexOffset: number, behavior: 'instant' | 'smooth') => {
      if (!carousellDom) return;
      if (!currentCarousellScrollLeft.current) return;
      if (!carousellScrollOffset) return;

      carousellDom.scrollTo({
        left: currentCarousellScrollLeft.current + cardIndexOffset * cardContainerWidth,
        behavior,
      });
    },
    [carousellDom, cardContainerWidth, carousellScrollOffset],
  );

  const prolivedCards = React.useMemo(() => [...cards, ...cards, ...cards], []);

  React.useEffect(() => {
    if (!carousellDom) return;

    currentCarousellScrollLeft.current = initialCarousellScrollLeft;
    setLeftFillerWidth(INITIAL_LEFT_FILLER_WIDTH);
    scrollCarousellByCardIndexOffset(0, 'instant');
    setActiveCardTitle(cards[0].title);
  }, [carousellDom, initialCarousellScrollLeft]);

  const [activeCardTitle, setActiveCardTitle] = React.useState(KeyMatricesCardNameEnum.Templates);

  const scrollToNextCard = React.useCallback(() => {
    const cardIndex = cards.findIndex((card) => card.title === activeCardTitle);
    scrollCarousellByCardIndexOffset(cardIndex + 1, 'smooth');
  }, [scrollCarousellByCardIndexOffset, activeCardTitle]);

  const lastCarousellScrollLeft = React.useRef<number>(0);
  const carousellScrollToRightPositionTimeout = React.useRef<NodeJS.Timer | null>(null);
  const carousellScrollToNextTimeout = React.useRef<NodeJS.Timer | null>(null);
  const handleCarousellScroll = React.useCallback(() => {
    if (!carousellDom) return;
    if (!currentCarousellScrollLeft.current) return;

    // Check when scroll stops for a while
    if (carousellScrollToNextTimeout.current) clearTimeout(carousellScrollToNextTimeout.current);
    carousellScrollToNextTimeout.current = setTimeout(() => {
      scrollToNextCard();
    }, 1500);

    const newScrollVelocity = carousellDom.scrollLeft - lastCarousellScrollLeft.current;
    lastCarousellScrollLeft.current = carousellDom.scrollLeft;

    if (carousellScrollToRightPositionTimeout.current) clearTimeout(carousellScrollToRightPositionTimeout.current);
    carousellScrollToRightPositionTimeout.current = setTimeout(() => {
      // Detect when it just stopped
      console.log(newScrollVelocity);
    }, 50);

    const cardIndex = Math.round((carousellDom.scrollLeft - currentCarousellScrollLeft.current) / cardContainerWidth);

    if (cardIndex >= cardsCount) {
      currentCarousellScrollLeft.current = currentCarousellScrollLeft.current + cardContainerWidth * cardsCount;
      setLeftFillerWidth((prev) => prev + cardContainerWidth * cardsCount);
    } else if (cardIndex < 0) {
      currentCarousellScrollLeft.current = currentCarousellScrollLeft.current - cardContainerWidth * cardsCount;
      setLeftFillerWidth((prev) => prev - cardContainerWidth * cardsCount);
    }

    const activeCardIndex = (cardIndex + cardsCount) % cardsCount;
    setActiveCardTitle(cards[activeCardIndex].title);
  }, [carousellDom, scrollToNextCard]);

  const scrollToCard = React.useCallback(
    (cardTitle: string) => {
      if (!carousellDom) return;
      if (!carousellScrollOffset) return;

      const targetCardIndex = cards.findIndex((card) => card.title === cardTitle);
      const activeCardIndex = cards.findIndex((card) => card.title === activeCardTitle);
      const cardIndexOffset = targetCardIndex - activeCardIndex;

      if (Math.abs(cardIndexOffset) <= cards.length / 2) {
        const targetLeft =
          leftFillerWidth +
          cardsSectionWidth / cloneFactor +
          targetCardIndex * cardContainerWidth -
          carousellScrollOffset;

        carousellDom.scrollTo({
          left: targetLeft,
          behavior: 'smooth',
        });
      } else {
        if (targetCardIndex < activeCardIndex) {
          const targetLeft =
            leftFillerWidth +
            cardsSectionWidth / cloneFactor +
            (targetCardIndex + cards.length) * cardContainerWidth -
            carousellScrollOffset;

          carousellDom.scrollTo({
            left: targetLeft,
            behavior: 'smooth',
          });
        } else {
          const targetLeft =
            leftFillerWidth +
            cardsSectionWidth / cloneFactor +
            (targetCardIndex - cards.length) * cardContainerWidth -
            carousellScrollOffset;

          carousellDom.scrollTo({
            left: targetLeft,
            behavior: 'smooth',
          });
        }
      }
    },
    [
      carousellDom,
      cards,
      activeCardTitle,
      carousellScrollOffset,
      cardContainerWidth,
      cardsSectionWidth,
      cloneFactor,
      leftFillerWidth,
    ],
  );

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        ref={setCarousellDom}
        style={{
          padding: '0 16px',
          display: 'flex',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          scrollBehavior: 'smooth',
          paddingBottom: '16px',
          WebkitOverflowScrolling: 'touch',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
        onScroll={handleCarousellScroll}
      >
        <div style={{ width: leftFillerWidth, flexShrink: 0 }} />
        {prolivedCards.map((card, cardIndex) => (
          <div
            key={`${card.title}-${cardIndex}`}
            style={{
              flexShrink: '0',
              width: cardContainerWidth,
              display: 'flex',
              justifyContent: 'center',
              padding: `0 ${cardContainerPadding}px`,
              cursor: 'pointer',
            }}
            onClick={() => {
              scrollToCard(card.title);
            }}
          >
            <KeyMatrixCard
              image={card.image}
              iconImage={card.iconImage}
              imageWidth={`${cards[1].imageWidth}px`}
              imageHeight={`${cards[1].imageHeight}px`}
              iconSize="40px"
              title={card.title}
              titleFontSize="30px"
              titleLineHeight="37px"
              titleTop="62px"
              titleLeft="62px"
              width={`${cardWidth}px`}
              height={`${cardHeight}px`}
              borderRadius="40px"
              backgroundColor={card.color}
            />
          </div>
        ))}
        <div style={{ width: INITIAL_RIGHT_FILLER_WIDTH, flexShrink: 0 }} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
          marginTop: '5px',
        }}
      >
        <CarousellTab value={activeCardTitle} options={cards.map((card) => card.title)} onClick={scrollToCard} />
      </div>
    </div>
  );
};

export default KeyMatricesCarousell;
