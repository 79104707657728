import * as React from 'react';
import { Text } from '../../../text';

type Props = {
  fontSize: string;
  lineHeight: string;
  emphasisFontSize: string;
  emphasisLineHeight: string;
  breakLines?: boolean;
};

export function TitleSection({ fontSize, lineHeight, emphasisFontSize, emphasisLineHeight, breakLines }: Props) {
  return (
    <Text
      tag="h1"
      fontFamily="NewAvertaPE"
      fontSize={fontSize}
      fontWeight="bold"
      color="#333333"
      lineHeight={lineHeight}
      textAlign="center"
    >
      {`Loved by `}
      <Text
        tag="strong"
        background="linear-gradient(87.36deg, #8235B8 -9.23%, #974DCB 16.56%, #EF4967 73.21%, #EE604D 91.93%)"
        textFillColor="transparent"
        backgroundClip="text"
        fontSize={emphasisFontSize}
        lineHeight={emphasisLineHeight}
      >
        270 Million
      </Text>
      {breakLines ? <br /> : ' '}
      People Around the World
    </Text>
  );
}
