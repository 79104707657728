import * as React from 'react';
import { useScreenSize } from '../features/landing-page/hooks/use-screen-size';
import { FixedBottomSection } from '../features/landing-page/components/sections/fixed-bottom-section';
import { NavbarSection } from '../features/landing-page/components/sections/navbar-section';
import { HeroSection } from '../features/landing-page/components/sections/hero-section';
import { UsecaseSection } from '../features/landing-page/components/sections/usecase-section';
import { KeySellingPointsSection } from '../features/landing-page/components/sections/key-selling-points-section';
import { KeyCreativeToolsSection } from '../features/landing-page/components/sections/key-creative-tools-section';
import { ReviewsSection } from '../features/landing-page/components/sections/reviews-section';
import { FooterSection } from '../features/landing-page/components/sections/footer-section';

import './landing-page.scss';
import { ScreenSizeEnum } from '../features/landing-page/models/screen-size-enum';

type Props = {};

const LandingPageContent: React.FC<Props> = ({}) => {
  const screenSize = useScreenSize();

  return (
    <div style={{ display: screenSize === ScreenSizeEnum.None ? 'none' : undefined }}>
      <FixedBottomSection screenSize={screenSize} />
      <NavbarSection screenSize={screenSize} />
      <HeroSection screenSize={screenSize} />
      <UsecaseSection screenSize={screenSize} />
      <KeySellingPointsSection screenSize={screenSize} />
      <KeyCreativeToolsSection screenSize={screenSize} />
      <ReviewsSection screenSize={screenSize} />
      <FooterSection screenSize={screenSize} />
    </div>
  );
};

const LandingPage: React.FC<Props> = (props) => {
  return (
    <>
      <LandingPageContent {...props} />
    </>
  );
};

export default LandingPage;
