import * as React from 'react';
import { Text } from '../../../text';
import deco from './deco.webp';

type Props = {
  fontSize: string;
  lineHeight: string;
  emphasisFontSize: string;
  emphasisLineHeight: string;
  decoImgTop: string;
  decoImgRight: string;
  breakLine: boolean;
};

export function TitleSection({
  fontSize,
  lineHeight,
  emphasisFontSize,
  emphasisLineHeight,
  decoImgTop,
  decoImgRight,
  breakLine,
}: Props) {
  return (
    <section style={{ position: 'relative', display: 'inline-flex' }}>
      <img
        alt="key selling point title decoration"
        src={deco}
        width="56px"
        height="56px"
        style={{ position: 'absolute', top: decoImgTop, right: decoImgRight }}
      />
      <Text
        tag="h1"
        fontFamily="NewAvertaPE"
        fontSize={fontSize}
        fontWeight="bold"
        color="#333333"
        lineHeight={lineHeight}
        textAlign="center"
      >
        ​​Where Creativity Meets {breakLine ? <br /> : ' '}
        <Text
          tag="strong"
          background="linear-gradient(87.36deg, #8235B8 -9.23%, #974DCB 16.56%, #EF4967 73.21%, #EE604D 91.93%)"
          textFillColor="transparent"
          backgroundClip="text"
          fontSize={emphasisFontSize}
          lineHeight={emphasisLineHeight}
        >
          Magic
        </Text>
      </Text>
    </section>
  );
}
