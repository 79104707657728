import phoneMockup from './images/phone-mockup.webp';
import phoneShadow from './images/shadow.webp';
import template_1_0 from './images/template_1_0.webp';
import template_1_1 from './images/template_1_1.webp';
import template_1_2 from './images/template_1_2.webp';
import template_1_3 from './images/template_1_3.webp';
import template_1_4 from './images/template_1_4.webp';
import template_1_5 from './images/template_1_5.webp';
import template_1_6 from './images/template_1_6.webp';
import template_2_0 from './images/template_2_0.webp';
import template_2_1 from './images/template_2_1.webp';
import template_2_2 from './images/template_2_2.webp';
import template_2_3 from './images/template_2_3.webp';
import template_2_4 from './images/template_2_4.webp';
import template_2_5 from './images/template_2_5.webp';
import template_2_6 from './images/template_2_6.webp';
import template_3_0 from './images/template_3_0.webp';
import template_3_1 from './images/template_3_1.webp';
import template_3_2 from './images/template_3_2.webp';
import template_3_3 from './images/template_3_3.webp';
import template_3_4 from './images/template_3_4.webp';
import template_3_5 from './images/template_3_5.webp';
import template_3_6 from './images/template_3_6.webp';
import template_4_0 from './images/template_4_0.webp';
import template_4_1 from './images/template_4_1.webp';
import template_4_2 from './images/template_4_2.webp';
import template_4_3 from './images/template_4_3.webp';
import template_4_4 from './images/template_4_4.webp';
import template_4_5 from './images/template_4_5.webp';
import template_4_6 from './images/template_4_6.webp';
import decorUc1 from './images/decor_uc_1.webp';
import decorUc2 from './images/decor_uc_2.webp';
import decorUc3 from './images/decor_uc_3.webp';
import decorUc4 from './images/decor_uc_4.webp';
import decorUc5 from './images/decor_uc_5.webp';
import decorUc6 from './images/decor_uc_6.webp';
import decorUc7 from './images/decor_uc_7.webp';
import decorUc8 from './images/decor_uc_8.webp';
import backIcon from './images/icon_back.webp';
import nextIcon from './images/icon_next.webp';

export const images = {
  phoneMockup,
  phoneShadow,
  images: [
    template_1_0,
    template_1_1,
    template_1_2,
    template_1_3,
    template_1_4,
    template_1_5,
    template_1_6,
    template_2_0,
    template_2_1,
    template_2_2,
    template_2_3,
    template_2_4,
    template_2_5,
    template_2_6,
    template_3_0,
    template_3_1,
    template_3_2,
    template_3_3,
    template_3_4,
    template_3_5,
    template_3_6,
    template_4_0,
    template_4_1,
    template_4_2,
    template_4_3,
    template_4_4,
    template_4_5,
    template_4_6,
  ],
  decos: [decorUc1, decorUc2, decorUc3, decorUc4, decorUc5, decorUc6, decorUc7, decorUc8],
  buttons: [backIcon, nextIcon],
};
