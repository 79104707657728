import React from "react";
import iphoneVideoMp4 from "./iphone-video.mp4";
import iphoneVideoJpg from "./iphone-video-placeholder.jpg";
import edgyUpPng from "./edgy-up.png";
import topLeftDecoratorPng from "./top-left-decorator.png";
import bottomRightDecoratorPng from "./bottom-right-decorator.png";

const FirstSection = ({ children, paddingBottom }) => (
  <div style={{
    position: 'relative',
    display: 'flex',
    paddingBottom: paddingBottom,
    flexFlow: 'column',
    alignItems: 'center',
    zIndex: 1,
  }}>
    {children}
  </div>
);

const TitleAndVideoSection = ({ children, marginTop, flexFlow, alignItems }) => (
  <div
    style={{
      position: 'relative',
      zIndex: '1',
      marginTop,
      display: 'flex',
      flexFlow,
      alignItems,
    }}
  >
    {children}
  </div>
);

const TitleAndVideoLeftSection = ({ children, top, width, alignItems }) => (
  <div
    style={{
      position: 'relative',
      top,
      width,
      display: 'flex',
      flexFlow: 'column',
      alignItems,
    }}
  >
    {children}
  </div>
);

const Title = ({ fontSize, lineHeight, textAlign }) => {
  return (
    <p style={{
      fontFamily: 'Weekly',
      fontSize,
      lineHeight,
      fontWeight: '400',
      color: '#666565',
      textAlign,
    }}>
      Create &<br />Celebrate
    </p>
  );
};

const Subtitle = ({ marginTop, fontSize, lineHeight, textAlign }) => {
  return (
    <p style={{
      fontFamily: 'AvertaPE',
      marginTop,
      fontSize,
      lineHeight,
      color: '#686868',
      textAlign,
    }}>
      Make your treasured memories<br />even more special
    </p>
  );
}

const AppStoreButtonsWrapper = ({ children, marginTop }) => (
  <div style={{
    display: 'flex',
    marginTop,
    flexFlow: 'row',
  }}>
    {children}
  </div>
);

const AppleStoreButton = ({ width, height, external_paths, appStoreImg }) => (
  <a
    data-event-track-id="top-apple-store-button"
    data-ga-name="top_ios_download_click"
    data-ga-category="apple_store_opens"
    data-ga-label="top download"
    href={external_paths["appstore"]}
  >
    <img
      width={width}
      height={height}
      src={appStoreImg}
      alt="Download on the App Store"
    />
  </a>
);

const GooglePlayButton = ({ width, height, external_paths, googlePlayImg }) => (
  <a
    style={{ marginLeft: '17px' }}
    data-event-track-id="top-google-play-button"
    data-ga-name="top_android_download_click"
    data-ga-category="google_store_opens"
    data-ga-label="top download"
    href={external_paths["googleplay"]}
  >
    <img
      width={width}
      height={height}
      src={googlePlayImg}
      alt="Get it on google play"
    />
  </a>
);

const WebEditorLink = ({ enable_web_editor_link }) => 
  enable_web_editor_link && (
    <div style={{
      display: 'block',
      marginTop: '24px',
    }}>
      <a
        style={{
          fontSize: '20px',
          fontFamily: 'AvertaPE',
          fontWeight: 500,
          color: '#4C4C4C',
          textDecoration: 'underline',
        }}
        href="https://www.photocards.ai"
        target="_blank"
        data-event-track-id="web-editor-link"
        data-ga-name="piccollage_web_editor_link_click"
        data-ga-category="piccollage_web_editor_link_opens"
        data-ga-label="picollage web editor link"
      >
        Try our new online web editor
      </a>
    </div>
  );

const Video = ({ borderRadius, marginTop, marginLeft, width, height }) => (
  <div 
    style={{
      position: 'relative',
      borderRadius,
      marginTop,
      marginLeft,
      filter: 'drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.1))',
      overflow: 'hidden',
      width,
      height,
    }}
  >
    <img style={{ position: 'absolute', top: '0', left: '0' }} width="100%" height="100%" src={iphoneVideoJpg} />
    <video
      style={{ position: 'relative', zIndex: '1' }}
      width="100%"
      height="100%"
      autoPlay
      playsInline
      muted
      loop
    >
      <source src={iphoneVideoMp4} type="video/mp4" />
    </video>
  </div>
);

const TopLeftDecoImage = ({ left, width, height }) => (
  <img
    style={{
      position: 'absolute',
      top: '0',
      left,
    }}
    width={width}
    height={height}
    src={topLeftDecoratorPng}
  />
);

const BottomRightDecoImage = ({ bottom, right, width, height }) => (
  <img
    style={{
      position: 'absolute',
      bottom,
      right,
    }}
    width={width}
    height={height}
    src={bottomRightDecoratorPng}
  />
);

const DownloadAppButtonWrapper = ({ children }) => (
  <div style={{
    zIndex: '1',
    display: 'block',
    marginTop: '40px',
  }}>{children}</div>
)

const DownloadAppButton = ({ apps_flyer_one_link_path }) => (
  <a
    style={{ width: '235px', height: '60px', borderRadius: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#55c0bb', fontFamily: 'AvertaPE', fontSize: '20px', fontWeight: '400', color: 'white', boxShadow: '0 6px 6px 0 rgba(0, 0, 0, 0.05)' }}
    data-event-track-id="top-download-app-button"
    data-ga-name="top_download_button_click"
    data-ga-category="mobile_store_opens"
    data-ga-label="top smartlink"
    href={apps_flyer_one_link_path}
  >
    Download App
  </a>
)

const BottomText = ({ bottom, fontSize, lineHeight }) => (
  <p
    style={{
      position: 'absolute',
      bottom,
      zIndex: 1,
      width: '100%',
      fontFamily: 'Weekly',
      textAlign: 'center',
      fontSize,
      lineHeight,
      letterSpacing: '0px',
      color: '#FF6854',
    }}
  >
    Add Fun to Photos & Videos!
  </p>
);

const BottomShapeImg = () => (
  <img
    style={{ position: 'absolute', left: 0, top: 0, minWidth: '1445px', width: '100%', height: '258px' }}
    width={1445}
    height={258}
    src={edgyUpPng}
  />
);

const BottomWrapper = ({ children, height }) => (
  <div
    style={{
      position: 'relative',
      marginTop: '-35px',
      width: '100%',
      height,
      overflow: 'hidden',
      display: 'flex',
    }}
  >{children}</div>
);

export default function Origin(props) {
  const {
    external_paths,
    enable_web_editor_link,
    googlePlayImg,
    appStoreImg,
    apps_flyer_one_link_path,
  } = props;

  return (
    <>
      <section className="hidden-xs hidden-sm" style={{ backgroundColor: '#FAF2EA' }}>
        <FirstSection paddingBottom="0">
          <TopLeftDecoImage left="0" width={524} height={311} />
          <BottomRightDecoImage bottom="-52px" right="-26px" width={244} height={494} />
          <TitleAndVideoSection marginTop="93px" flexFlow="row" alignItems="unset">
            <TitleAndVideoLeftSection top="40px" width="375px" alignItems="unset">
              <Title fontSize="70px" lineHeight="77px" textAlign="unset" />
              <Subtitle marginTop="12px" fontSize="25px" lineHeight="27px" textAlign="unset" />
              <AppStoreButtonsWrapper marginTop="36px">
                <AppleStoreButton width={150} height={50} external_paths={external_paths} appStoreImg={appStoreImg} />
                <GooglePlayButton width={167} height={50} external_paths={external_paths} googlePlayImg={googlePlayImg} />
              </AppStoreButtonsWrapper>
              <WebEditorLink enable_web_editor_link={enable_web_editor_link} />
            </TitleAndVideoLeftSection>
            <Video borderRadius="28px" marginTop="0" marginLeft="10vw" width={305} height={659} />
          </TitleAndVideoSection>
        </FirstSection>
        <BottomWrapper height="230px" >
          <BottomShapeImg />
          <BottomText bottom="80px" fontSize="34px" lineHeight="41px" />
        </BottomWrapper>
      </section>
      <section className="hidden-xs hidden-md hidden-lg" style={{ backgroundColor: '#FAF2EA' }}>
        <FirstSection paddingBottom="98px">
          <TopLeftDecoImage left="-16px" width={421} height={250} />
          <BottomRightDecoImage bottom="-44px" right="-29px" width={196} height={397} />
          <TitleAndVideoSection marginTop="138px" flexFlow="row" alignItems="unset">
            <TitleAndVideoLeftSection top="15px" width="321px" alignItems="unset">
              <Title fontSize="54px" lineHeight="60px" textAlign="unset" />
              <Subtitle marginTop="9px" fontSize="19px" lineHeight="21px" textAlign="unset" />
              <AppStoreButtonsWrapper marginTop="28px">
                <AppleStoreButton width={117} height={39} external_paths={external_paths} appStoreImg={appStoreImg} />
                <GooglePlayButton width={130} height={39} external_paths={external_paths} googlePlayImg={googlePlayImg} />
              </AppStoreButtonsWrapper>
            </TitleAndVideoLeftSection>
            <Video borderRadius="28px" marginTop="0" marginLeft="14px" width={230} height={500} />
          </TitleAndVideoSection>
        </FirstSection>
        <BottomWrapper height="230px" >
          <BottomShapeImg />
          <BottomText bottom="80px" fontSize="30px" lineHeight="40px" />
        </BottomWrapper>
      </section>
      <section className="hidden-sm hidden-md hidden-lg" style={{ backgroundColor: '#FAF2EA' }}>
        <FirstSection paddingBottom="67px">
          <TopLeftDecoImage left="-27px" width={306} height={182} />
          <BottomRightDecoImage bottom="-16px" right="-26px" width={142} height={288} />
          <TitleAndVideoSection marginTop="67px" flexFlow="column" alignItems="center">
            <TitleAndVideoLeftSection top="0" width="100%" alignItems="center">
              <Title fontSize="40px" lineHeight="43px" textAlign="center" />
              <Subtitle marginTop="9px" fontSize="17px" lineHeight="18px" textAlign="center" />
            </TitleAndVideoLeftSection>
            <Video borderRadius="28px" marginTop="31px" marginLeft="0" width={239} height={518} />
          </TitleAndVideoSection>
          <DownloadAppButtonWrapper>
            <DownloadAppButton apps_flyer_one_link_path={apps_flyer_one_link_path} />
          </DownloadAppButtonWrapper>
        </FirstSection>
        <BottomWrapper height="122px" >
          <BottomShapeImg />
          <BottomText bottom="32px" fontSize="18px" lineHeight="22px" />
        </BottomWrapper>
      </section>
    </>
  );
}
