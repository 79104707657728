import * as React from 'react';

type Props = {
  imgSrc: string;
  width: string | number;
  maxWidth?: string | number;
  height?: string | number;
  maxHeight?: string | number;
};

export function CardImageSection({ imgSrc, width, maxWidth, height, maxHeight }: Props) {
  return (
    <div
      style={{
        position: 'relative',
        width,
        maxWidth,
        height,
        maxHeight,
        display: 'inline-flex',
        borderRadius: '60px',
        overflow: 'hidden',
      }}
    >
      <img style={{ position: 'relative', zIndex: 1 }} src={imgSrc} width="100%" />
    </div>
  );
}
