export const SECTION_GAP = 120;

export const MOBILE_SIDE_PADDING = 16;
export const TABLET_SIDE_PADDING = 32;

export const DESKTOP_TITLE_FONT_SIZE = 28;
export const DESKTOP_TITLE_EMPHASIZE_FONT_SIZE = 40;
export const DESKTOP_TITLE_LINE_HEIGHT = 48;

export const DESKTOP_SUBTITLE_FONT_SIZE = 28;

export const DESKTOP_DESCRIPTION_FONT_SIZE = 20;

export const DESKTOP_DESCRIPTION_COLOR = '#666666';

export const DESKTOP_NAVBAR_LINK_FONT_SIZE = 16;
