import * as React from 'react';
import { Text } from '../../../text';
import { DESKTOP_NAVBAR_LINK_FONT_SIZE } from '../../../../styles/styles';

type Props = {
  width: string;
  flexFlow: string;
  justifyContent?: string;
  gap?: string;
};

export function LinksSection({ width, flexFlow, justifyContent, gap }: Props) {
  return (
    <div style={{ width, display: 'flex', flexFlow, justifyContent, alignItems: 'center', gap }}>
      <div style={{ display: 'flex' }}>
        <a href="https://blog.piccollage.com/" target="_blank" style={{ textDecoration: 'none' }}>
          <Text
            tag="span"
            color="#4D4D4D"
            fontSize={`${DESKTOP_NAVBAR_LINK_FONT_SIZE}px`}
            lineHeight="22px"
            fontFamily="Montserrat"
            fontWeight="500"
          >
            Blog
          </Text>
        </a>
      </div>
      <div style={{ display: 'flex' }}>
        <a href="https://picc.co/contact/" target="_blank" style={{ textDecoration: 'none' }}>
          <Text
            tag="span"
            color="#4D4D4D"
            fontSize={`${DESKTOP_NAVBAR_LINK_FONT_SIZE}px`}
            lineHeight="22px"
            fontFamily="Montserrat"
            fontWeight="500"
          >
            Contact Us
          </Text>
        </a>
      </div>
      <div style={{ display: 'flex' }}>
        <a href="https://picc.co/careers" target="_blank" style={{ textDecoration: 'none' }}>
          <Text
            tag="span"
            color="#4D4D4D"
            fontSize={`${DESKTOP_NAVBAR_LINK_FONT_SIZE}px`}
            lineHeight="22px"
            fontFamily="Montserrat"
            fontWeight="500"
          >
            Careers
          </Text>
        </a>
      </div>
      <div style={{ display: 'flex' }}>
        <a href="/login" target="_blank" style={{ textDecoration: 'none' }}>
          <Text
            tag="span"
            color="#4D4D4D"
            fontSize={`${DESKTOP_NAVBAR_LINK_FONT_SIZE}px`}
            lineHeight="22px"
            fontFamily="Montserrat"
            fontWeight="500"
          >
            Login
          </Text>
        </a>
      </div>
    </div>
  );
}
