import * as React from 'react';
import { CommonContextProvider } from '../features/common/contexts/common-context';
import { EditStartFeedCardPanel } from '../features/start-feed-cards/components/edit-start-feed-card-panel';
import { StartFeedCardTypeEnum } from '../features/start-feed-cards/models/start-feed-card-type-enum';
import { NewStartFeedCardModel } from '../features/start-feed-cards/models/new-start-feed-card-model';
import { NewLinkStartFeedCardModel } from '../features/start-feed-cards/models/link-start-feed-cards/new-link-start-feed-card-model';
import { NewTemplateCategoryStartFeedCardModel } from '../features/start-feed-cards/models/template-category-start-feed-cards/new-template-category-start-feed-card-model';
import { PicButton, PicConfirmer, PicDivider, PicLink, PicNotifier } from '@cardinalblue/pic-collage-cms-ui';
import { ActionableHeader } from '../features/common/components/headers/actionable-header';
import { EditLinkStartFeedCardPanel } from '../features/start-feed-cards/components/edit-link-start-feed-card-panel';
import { StartFeedCardApi } from '../features/start-feed-cards/apis/start-feed-card-api';
import { dispatchNewStartFeedCardModel } from '../features/start-feed-cards/models/utils';
import { EditTemplateCategoryStartFeedCardPanel } from '../features/start-feed-cards/components/edit-template-category-start-feed-card-panel';
import { TemplateTagDto, parseTemplateTagDto } from '../features/common/dtos/template-tag-dto';
import { CountryDto, parseCountryDto } from '../features/common/dtos/country-dto';

type Props = {
  type: StartFeedCardTypeEnum;
  countryDtos: CountryDto[];
  templateTagDtos: TemplateTagDto[];
};

const NewStartFeedCardPageContent: React.FC<Props> = ({ type, countryDtos, templateTagDtos }) => {
  const countries = React.useMemo(() => countryDtos.map((dto) => parseCountryDto(dto)), [countryDtos]);
  const templateTags = React.useMemo(() => templateTagDtos.map((dto) => parseTemplateTagDto(dto)), [templateTagDtos]);

  const picConfirmer = React.useMemo(() => new PicConfirmer(), []);
  const picNotifier = React.useMemo(() => new PicNotifier(), []);

  const startFeedCardApi = React.useMemo(() => StartFeedCardApi.create(), []);
  const [newStartFeedCard, setNewStartFeedCard] = React.useState<NewStartFeedCardModel>(() => {
    switch (type) {
      case StartFeedCardTypeEnum.Link:
        return NewLinkStartFeedCardModel.createNew();
      case StartFeedCardTypeEnum.TemplateCategory:
        return NewTemplateCategoryStartFeedCardModel.createNew();
    }
  });

  const handleSaveClick = React.useCallback(async () => {
    const [confirmed, close] = await picConfirmer.pop({
      title: 'Create Start Feed Card',
      message: 'Are you sure you want to create this new start feed card?',
    });
    if (!confirmed) {
      close();
      return;
    }

    const [error, newStartFeedCardId] = await startFeedCardApi.create(newStartFeedCard);
    if (error) {
      close();
      picNotifier.notify({
        type: 'error',
        message: error.message,
      });
      return;
    }

    window.location.href = `/admin/start_feed_cards/${newStartFeedCardId}`;
  }, [startFeedCardApi, newStartFeedCard]);

  return (
    <div>
      <PicLink
        copy="< Back"
        onClick={() => {
          window.location.href = '/admin/start_feed_cards';
        }}
      />
      <ActionableHeader
        title="New Start Feed Card"
        tags={[newStartFeedCard.getType()]}
        ctaButtons={
          <>
            <PicButton
              disabled={!newStartFeedCard.isComplete()}
              type="primary"
              outlined={false}
              copy="Save"
              onClick={handleSaveClick}
            />
            <PicButton
              type="primary"
              outlined
              copy="Cancel"
              onClick={() => {
                window.location.href = '/admin/start_feed_cards';
              }}
            />
          </>
        }
      />
      <PicDivider marginTop="12px" marginBottom="12px" />
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          gap: '20px',
        }}
      >
        <div style={{ flexBasis: '50%' }}>
          <EditStartFeedCardPanel
            countries={countries}
            startFeedCard={newStartFeedCard}
            onStartFeedCardUpdate={(updatedNewStartFeedCard) => {
              setNewStartFeedCard(updatedNewStartFeedCard);
            }}
            startFeedCardCloner={(startFeedCard) => startFeedCard.clone()}
          />
        </div>
        <div style={{ flexBasis: '50%' }}>
          {dispatchNewStartFeedCardModel(newStartFeedCard, {
            [StartFeedCardTypeEnum.Link]: (model) => (
              <EditLinkStartFeedCardPanel
                linkStartFeedCard={model}
                onLinkStartFeedCardUpdate={(updatedNewStartFeedCard) => {
                  setNewStartFeedCard(updatedNewStartFeedCard);
                }}
                linkStartFeedCardCloner={(linkStartFeedCard) => linkStartFeedCard.clone()}
              />
            ),
            [StartFeedCardTypeEnum.TemplateCategory]: (model) => (
              <EditTemplateCategoryStartFeedCardPanel
                templateTags={templateTags}
                templateCategroyStartFeedCard={model}
                onTemplateCategoryStartFeedCardUpdate={(updatedNewStartFeedCard) => {
                  setNewStartFeedCard(updatedNewStartFeedCard);
                }}
                templateCategoryStartFeedCardCloner={(templateCategoryStartFeedCard) =>
                  templateCategoryStartFeedCard.clone()
                }
              />
            ),
          })}
        </div>
      </div>
    </div>
  );
};

const NewStartFeedCardPage: React.FC<Props> = (props) => {
  return (
    <CommonContextProvider>
      <NewStartFeedCardPageContent {...props} />
    </CommonContextProvider>
  );
};

export default NewStartFeedCardPage;
