import * as React from 'react';
import { ScreenSizeEnum } from '../../../models/screen-size-enum';
import { BackgroundSection } from './background-section';
import { MobileSection } from './mobile-section';
import { Header } from './header-section';
import { DescriptionSection } from './description-section';
import { DownloadAppButtons } from './download-app-buttons';

type Props = {
  screenSize: ScreenSizeEnum;
};

export const HeroSection: React.FC<Props> = ({ screenSize }) => {
  return (
    <section style={{ backgroundColor: '#FBF2EB', overflow: 'hidden' }}>
      {screenSize === ScreenSizeEnum.Desktop && (
        <section style={{ position: 'relative', padding: '32px 0 96px', display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              position: 'relative',
              display: 'inline-flex',
              justifyContent: 'center',
              width: 'calc(100vw - 178px)',
              maxWidth: '1221px',
              height: 'calc((100vw - 178px) * 57 / 100)',
              maxHeight: '699px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 2,
                left: '-24px',
                bottom: 'calc(50% + 80px)',
                transform: 'translateY(100%)',
              }}
            >
              <section
                style={{
                  position: 'relative',
                  display: 'inline-flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                  width: '622px',
                }}
              >
                <Header width="618px" height="74px" titleFontSize="60px" titleLineHeight="67px" />
                <section style={{ marginTop: '16px', display: 'flex' }}>
                  <DescriptionSection fontSize="24px" lineHeight="26px" />
                </section>
                <section style={{ marginTop: '41px' }}>
                  <DownloadAppButtons buttonWidth="182px" buttonHeight="52px" />
                </section>
              </section>
            </div>
            <div style={{ position: 'relative', zIndex: 1, width: '100%', height: '100%' }}>
              <BackgroundSection hasDecorations />
            </div>
            <div
              style={{
                position: 'absolute',
                zIndex: 3,
                bottom: '-22px',
                right: 'calc(50% - 114px)',
                transform: 'translateX(100%)',
              }}
            >
              <MobileSection width="312px" height="636px" />
            </div>
          </div>
        </section>
      )}
      {screenSize === ScreenSizeEnum.Tablet && (
        <section style={{ position: 'relative', display: 'block' }}>
          <div
            style={{
              position: 'relative',
              marginTop: '-280px',
              marginLeft: '50%',
              transform: 'translateX(-50%)',
              width: '1171px',
              minWidth: '110vw',
              height: '671px',
              minHeight: '63vw',
            }}
          >
            <div style={{ position: 'absolute', zIndex: 2, left: '50%', bottom: '20%', transform: 'translateX(-50%)' }}>
              <section
                style={{
                  position: 'relative',
                  display: 'inline-flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                }}
              >
                <Header width="427px" height="56px" titleFontSize="40px" titleLineHeight="44px" />
                <section style={{ marginTop: '15px', display: 'flex' }}>
                  <DescriptionSection fontSize="20px" lineHeight="22px" />
                </section>
                <section style={{ marginTop: '34px' }}>
                  <DownloadAppButtons buttonWidth="140px" buttonHeight="46px" />
                </section>
              </section>
            </div>
            <div style={{ position: 'relative', zIndex: 1, width: '100%', height: '100%' }}>
              <BackgroundSection />
            </div>
          </div>
          <div
            style={{
              position: 'relative',
              zIndex: 1,
              marginTop: '-80px',
              paddingBottom: '40px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <MobileSection width="312px" height="636px" />
          </div>
        </section>
      )}
      {screenSize === ScreenSizeEnum.Mobile && (
        <section style={{ position: 'relative', display: 'block' }}>
          <div
            style={{
              position: 'relative',
              marginTop: '-65px',
              marginLeft: '50%',
              transform: 'translateX(-50%)',
              minWidth: '110vw',
              width: '568px',
              minHeight: '63vw',
              height: '325px',
            }}
          >
            <div
              style={{ position: 'absolute', zIndex: 2, left: '50%', bottom: '130px', transform: 'translateX(-50%)' }}
            >
              <section
                style={{
                  position: 'relative',
                  display: 'inline-flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                }}
              >
                <Header width="270px" height="35px" titleFontSize="28px" titleLineHeight="31px" />
                <section style={{ marginTop: '8px', display: 'flex' }}>
                  <DescriptionSection fontSize="14px" lineHeight="15px" />
                </section>
              </section>
            </div>
            <div style={{ position: 'relative', zIndex: 1, width: '100%', height: '100%' }}>
              <BackgroundSection />
            </div>
          </div>
          <div
            style={{
              position: 'relative',
              zIndex: 1,
              marginTop: '-104px',
              paddingBottom: '40px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <MobileSection width="240px" height="489px" />
          </div>
        </section>
      )}
    </section>
  );
};
