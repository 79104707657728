// Generated by CoffeeScript 2.6.1
(function() {
  // Place all the behaviors and hooks related to the matching controller here.
  // All this logic will automatically be available in application.js.
  // You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
  (function($) {
    // ---------------------------------------------------------------
    // ---------------------------------------------------------------
    // Define custom reusable functional CSS classes 
    // ---------------------------------------------------------------
    // ---------------------------------------------------------------

    // ---------------------------------------------------------------
    // Triggers an ajax request upon click.

    // Usage:
    //   .click-ajax{ 'data-ajax-url'     => 'https://myapi.com/foo',
    //                'data-ajax-method'  => 'POST',
    //                'data-ajax-data'    => '{ "mydata": 1 }'
    //                'data-ajax-confirm' => 'are you sure you want to do this?'
    //                'data-ajax-href'    => 'https://mysite.com/redirect_url'
    //                'data-ajax-disable' => 'true' }

    // Will trigger the event "ajax-success(response)" OR "ajax-error(error)".
    // Adds classes 'ajax-success' or 'ajax-error'.

    // Options:
    // * ajax-confirm: opens a confirmation dialog before doing the ajax request
    // * ajax-href:    redirects to the value, just like a ordinary non-ajax request
    // * ajax-disable: disables the button instead of turning it into a success state

    $(document).on('click', '.click-ajax', function() {
      var clicked, confirmtext, data, href, method, url;
      clicked = $(this);
      url = clicked.data('ajax-url');
      method = clicked.data('ajax-method') || 'POST';
      data = clicked.data('ajax-data') || {};
      confirmtext = clicked.data('ajax-confirm') || '';
      href = clicked.data('ajax-href') || '';
      if ((confirmtext === '') || (confirm(confirmtext))) {
        // Button-specific behavior
        if (clicked.is('.btn')) {
          clicked.button('loading');
        }
        return $.ajax({
          url: url,
          method: method,
          data: data,
          error: function(xhr, status, error) {
            clicked.addClass('ajax-error');
            return clicked.trigger('ajax-error', [xhr, status, error]);
          },
          success: function(response) {
            clicked.addClass('ajax-success');
            clicked.trigger('ajax-success', [response]);
            if (href !== '') {
              return window.location = href;
            }
          }
        });
      }
    });
    // ---------------------------------------------------------------
    // Add 'data-reclick=<jQuery selector>' to make a click on an element
    // send the click event to another.
    // Usage:
    //   <a href='http://somewhere.com' id='original-link'>Original</a>
    //   <a data-reclick='#original-link'>Click me!</a>

    $(document).on('click', '[data-reclick]', function(e) {
      e.preventDefault();
      return $($(this).attr('data-reclick')).click();
    });
    // Button-specific handling

    $(document).on('ajax-success', '.btn.click-ajax', function() {
      if ($(this).data('ajax-disable')) {
        // Set the text manually from the resetText instead of using button('reset'),
        // because otherwise (for some reason) the disable doesn't work.
        // We cannot disable and use the `button` functionality at the same time.
        $(this).text($(this).data('resetText'));
        return $(this).prop('disabled', true);
      } else {
        $(this).button('reset');
        return $(this).button('success');
      }
    });
    $(document).on('ajax-error', '.btn.click-ajax', function() {
      $(this).button('reset');
      return $(this).button('error');
    });
    // Catch errors that bubble to the top

    $(document).on('ajax-error', function(event, xhr, status, error) {
      return alert(status + ': ' + error);
    });
    // -------------------------------------------------------------
    // Useful content update classes

    // Updates the contents of the element when an `ajax-success` event happens.
    // Extracts the data from the JSON via the `ajax-struct`.

    // Usage:
    //   %span.ajax-success-updates{ 'data-ajax-struct'     => 'mykey.2.blah',
    //                               'data-ajax-source'     => '#myajaxsource' }

    $(document).on('ajax-success', function(event, response) {
      return $('.ajax-success-updates').each(function() {
        var d, dataAjaxStruct, i, len, s, selector, structs;
        dataAjaxStruct = $(this).data('ajax-struct');
        if (dataAjaxStruct) {
          structs = dataAjaxStruct.split('.');
        }
        // Abort if selector doesn't match
        selector = $(this).data('ajax-source') || '*';
        if (!$(event.target).is(selector)) {
          return;
        }
        // Extract data and set
        d = response;
        if (structs) {
          for (i = 0, len = structs.length; i < len; i++) {
            s = structs[i];
            d = d[s];
          }
        }
        return $(this).html(d);
      });
    });
    $(document).on('ajax-success', '.ajax-success-toggles-siblings', function() {
      var elems;
      elems = $(this).siblings().andSelf();
      return elems.each(function(index, e) {
        e = $(e);
        if (e.hasClass('hide')) {
          return e.toggleClass('hide');
        } else if (e.hasClass('show')) {
          return e.toggleClass('show');
        } else {
          return e.toggle();
        }
      });
    });
    // Will fade out the closes the element given by the when an `ajax-success`
    // event happens.

    // Usage:

    //   %span.ajax-success-removes{ 'data-ajax-selector' => '#myclosest' }

    $(document).on('ajax-success', '.ajax-success-removes', function(event, _) {
      var selector, target, victim;
      target = event.target;
      selector = $(this).data('ajax-selector');
      victim = target.closest(selector);
      return $(victim).fadeOut();
    });
    (function() {      // -------------------------------------------------------------
      // Add .display-image-after-select class to an <input type='file'> tag 
      // to display a chosen image in a new <img> element that will be appended right
      // after the input.

      var holder;
      holder = {};
      return $(document).on('change', 'input[type=file].display-image-after-select', function(e) {
        var file, input, target_id;
        input = e.target;
        target_id = input.id;
        file = input.files[0];
        if (!holder[target_id]) {
          holder[target_id] = {
            reader: new FileReader(),
            image: new Image(),
            div: document.createElement('div')
          };
          holder[target_id].reader.onload = function(loadEvent) {
            holder[target_id].image.src = loadEvent.target.result;
            return $(input).after(holder[target_id].div, holder[target_id].image);
          };
        }
        holder[target_id].div.innerHTML = file.name;
        return holder[target_id].reader.readAsDataURL(file);
      });
    })();
    // jQuery plugin
    // Disables the target if the subject given by the selector is empty.

    $.fn.disableIfEmpty = function(subject) {
      var runCheck, target;
      target = this;
      runCheck = function() {
        var empty;
        empty = $(subject).val().trim() === '';
        return target.prop('disabled', empty);
      };
      // Set initial state
      runCheck();
      // Set up future changes
      return $(subject).on('keyup', runCheck);
    };
    // -------------------------------------------------------------
    // Change 'cb_changed' attribute to true if the value of fields
    // having 'cb_changed' attribute is changed.
    return $(document).on('change', '[cb_changed]', function(e) {
      return $(this).attr('cb_changed', true);
    });
  })(window.jQuery);

}).call(this);
