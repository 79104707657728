import React from "react";
import Carousel from "react-bootstrap/lib/Carousel";
import classNames from "classnames";
import OnImagesLoaded from "react-on-images-loaded";

export default class Showcase extends React.Component {
  constructor(props) {
    super(props);
    this.phoneCaseImg = React.createRef();

    this.state = {
      activeIndex: 0,
      prevIndex: 2,
      phoneCaseImgWidth: 400
    };
    this.handleResize = this.handleResize.bind(this);
  }
  handleResize() {
    let phoneCaseImgWidth = 0;
    if (this.phoneCaseImg && this.phoneCaseImg.current) {
      phoneCaseImgWidth = this.phoneCaseImg.current.getBoundingClientRect()
        .width;
    }
    this.setState({phoneCaseImgWidth: phoneCaseImgWidth});
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
  componentWillUnMount() {
    window.removeEventListener("resize", this.handleResize);
  }
  updateSlide(slideData) {
    const SLIDE_COUNT = slideData.length;
    this.setState(prevState => {
      return {
        prevIndex: prevState.activeIndex,
        activeIndex: (prevState.activeIndex + 1) % SLIDE_COUNT
      };
    });
  }

  renderSlideCaption(slideData) {
    return slideData.map((slide, idx) => {
      return <h3 key={`caption-${idx}`}>{slide.caption}</h3>;
    });
  }

  renderSlideCaptionForMobile(slideData, {activeIndex, prevIndex}) {
    const cpations = this.renderSlideCaption(slideData);
    return React.Children.map(cpations, (child, idx) => {
      let elm = React.cloneElement(child, {
        className: classNames(
          "item-caption",
          idx === activeIndex && "fade-in",
          idx === prevIndex && "fade-out"
        )
      });
      return elm;
    });
  }

  renderMainSlideImgs(slideData, {activeIndex, prevIndex}) {
    const titles = this.renderSlideCaption(slideData);
    return slideData.map((slide, idx) => {
      return (
        <div
          key={`slide-${idx}`}
          className={classNames(
            "item",
            idx === activeIndex && "fade-in",
            idx === prevIndex && "fade-out"
          )}>
          {titles[idx]}
          <img src={slide.image} />
        </div>
      );
    });
  }

  renderPhoneImgs(slideData) {
    return slideData.map((data, idx) => (
      <Carousel.Item key={`phone-img-${idx}`}>
        <div style={{ width: 250 }}>
          <img src={data.phone} alt="" />
        </div>
      </Carousel.Item>
    ));
  }

  render() {
    const slideData = [
      {
        caption: "Choose From the Latest Free and Premium Fonts",
        phone: this.props.phoneImg1,
        image: this.props.phoneMainImg1
      },
      {
        caption: "Bring Collages to Life with Videos and Animation",
        phone: this.props.phoneImg2,
        image: this.props.phoneMainImg2
      },
      {
        caption: "Get Access to All Premium Content with a VIP Subscription",
        phone: this.props.phoneImg3,
        image: this.props.phoneMainImg3
      }
    ];
    const {phoneCaseImgWidth} = this.state;
    return (
      <OnImagesLoaded onLoaded={ () => { setTimeout(this.handleResize, 0); }} >
        <div className="showcase">
          <div>
            <div className="phone-wrapper">
              {this.renderSlideCaptionForMobile(
                slideData,
                this.state
              )}
            </div>
            <div className="phone-inner">
              <Carousel
                controls={false}
                onSlideEnd={() => {
                  this.updateSlide(slideData);
                }}>
                {this.renderPhoneImgs(slideData)}
              </Carousel>
              <div className="phone-case">
                <div
                  style={{width: `${phoneCaseImgWidth}px`}}
                  className="mask-clip"
                />
                <div className="phone-inner-wrapper">
                  <img
                    ref={this.phoneCaseImg}
                    onLoad={this.handleResize}
                    src={this.props.phoneInnerImg}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="slide-area">
            {this.renderMainSlideImgs(slideData, this.state)}
          </div>
        </div>
      </OnImagesLoaded>
    );
  }
}
