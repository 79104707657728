type TimezoneOffset =
  | -12
  | -11
  | -10
  | -9
  | -8
  | -7
  | -6
  | -5
  | -4
  | -3
  | -2
  | -1
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;
export class DateVo {
  private date: Date;

  constructor(date: Date) {
    this.date = date;
  }

  static create(date: Date) {
    return new DateVo(date);
  }

  static parseTimeString(timeString: string): DateVo {
    const date = new Date(timeString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date string');
    }

    return new DateVo(date);
  }

  static parseDateString(dateString: string, timezoneOffset: TimezoneOffset): DateVo {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      throw new Error('Invalid date format, expected yyyy-mm-dd');
    }

    const date = new Date(`${dateString}T00:00:00Z`);

    const targetTimezoneOffsetInMilliseconds = timezoneOffset * 60 * 60 * 1000;
    const adjustedDate = new Date(date.getTime() - targetTimezoneOffsetInMilliseconds);

    return new DateVo(adjustedDate);
  }

  public toISO8601String(timezoneOffset: TimezoneOffset): string {
    const timezoneOffsetInMinutes = timezoneOffset * 60;
    const dateWithTimezone = new Date(this.date.getTime() + timezoneOffsetInMinutes * 60 * 1000);

    const year = dateWithTimezone.getUTCFullYear();
    const month = (dateWithTimezone.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = dateWithTimezone.getUTCDate().toString().padStart(2, '0');
    const hours = dateWithTimezone.getUTCHours().toString().padStart(2, '0');
    const minutes = dateWithTimezone.getUTCMinutes().toString().padStart(2, '0');
    const seconds = dateWithTimezone.getUTCSeconds().toString().padStart(2, '0');

    const timezoneSign = timezoneOffset >= 0 ? '+' : '-';
    const absTimezoneOffset = Math.abs(timezoneOffset);
    const timezoneHours = Math.floor(absTimezoneOffset).toString().padStart(2, '0');
    const timezoneMinutes = ((absTimezoneOffset % 1) * 60).toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneSign}${timezoneHours}:${timezoneMinutes}`;
  }

  public isAfter(date: DateVo): boolean {
    return this.date >= date.date;
  }

  public isBetween(startDate: DateVo, endDate: DateVo): boolean {
    return this.date >= startDate.date && this.date <= endDate.date;
  }

  public toDateString(timezoneOffset: TimezoneOffset): string {
    const timezoneOffsetInMinutes = timezoneOffset * 60;
    const dateWithTimezone = new Date(this.date.getTime() + timezoneOffsetInMinutes * 60 * 1000);

    const year = dateWithTimezone.getUTCFullYear().toString();
    const month = (dateWithTimezone.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = dateWithTimezone.getUTCDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  /**
   * Shift date to the end of day of the timezone.
   */
  public toEndOfDay(timezoneOffset: TimezoneOffset): DateVo {
    const timezoneOffsetInMinutes = timezoneOffset * 60;
    const dateWithTimezone = new Date(this.date.getTime() + timezoneOffsetInMinutes * 60 * 1000);
    dateWithTimezone.setUTCHours(23, 59, 59, 999);

    return new DateVo(new Date(dateWithTimezone.getTime() - timezoneOffsetInMinutes * 60 * 1000));
  }
}
