import * as React from 'react';
import { Text } from '../../../text';

interface Props {
  href?: string;
  target?: '_blank';
  copy?: string;
  type: 'primary' | 'dangerous';
  outlined?: boolean;
  size?: 'sm' | 'lg';
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

export function AcceptCookieButton({
  href,
  target,
  copy,
  type,
  outlined = false,
  size = 'sm',
  disabled = false,
  loading = false,
  onClick,
}: React.PropsWithChildren<Props>) {
  const [hovered, setHovered] = React.useState(false);

  const PicColor = {
    Teal300: '#D8EFEF',
    Teal500: '#4FC3C4',
    Teal700: '#21A4A5',
    Red500: '#FF4C4C',
    Red700: '#AF0100',
    Grey300: '#CCCCCC',
    White: '#FFFFFF',
  };

  const handleColor = (type: string, outlined: boolean, disabled: boolean, hovered: boolean, loading: boolean) => {
    const picColors = {
      // light and dark
      primary: [PicColor.Teal500, PicColor.Teal700],
      dangerous: [PicColor.Red500, PicColor.Red700],
      disabled: PicColor.Grey300,
      white: PicColor.White,
    };

    let backgroundColor = PicColor.Teal500;
    let borderColor = PicColor.Teal500;
    let textColor = PicColor.Teal500;

    if (disabled) {
      backgroundColor = outlined ? picColors.white : picColors.disabled;
      borderColor = picColors.disabled;
      textColor = outlined ? picColors.disabled : picColors.white;
      return { backgroundColor, borderColor, textColor };
    }
    if (type === 'primary') {
      if (outlined) {
        backgroundColor = picColors.white;
        borderColor = picColors.primary[0];
        textColor = picColors.primary[0];
        if (hovered && !loading) {
          backgroundColor = picColors.primary[0];
          borderColor = picColors.primary[0];
          textColor = picColors.white;
        }
      } else {
        backgroundColor = picColors.primary[0];
        borderColor = picColors.primary[0];
        textColor = picColors.white;
        if (hovered && !loading) {
          backgroundColor = picColors.primary[1];
          borderColor = picColors.primary[1];
        }
      }
    } else if (type === 'dangerous') {
      if (outlined) {
        backgroundColor = picColors.white;
        borderColor = picColors.dangerous[0];
        textColor = picColors.dangerous[0];
        if (hovered && !loading) {
          backgroundColor = picColors.dangerous[0];
          borderColor = picColors.dangerous[0];
          textColor = picColors.white;
        }
      } else {
        backgroundColor = picColors.dangerous[0];
        borderColor = picColors.dangerous[0];
        textColor = picColors.white;
        if (hovered && !loading) {
          backgroundColor = picColors.dangerous[1];
          borderColor = picColors.dangerous[1];
        }
      }
    }
    return { backgroundColor, borderColor, textColor };
  };

  const { backgroundColor, borderColor, textColor } = handleColor(type, outlined, disabled, hovered, loading);

  const buttonText = loading ? null : copy;

  const handleButtonClick: React.MouseEventHandler = React.useCallback(
    (evt) => {
      if (loading || disabled) return;

      evt.stopPropagation();
      onClick?.();
    },
    [onClick, loading, disabled],
  );

  const Wrapper = React.useMemo(() => {
    return ({
      href,
      target,
      style,
      onButtonClick,
      onMouseEnter,
      onMouseLeave,
      children,
    }: React.PropsWithChildren<{
      href?: string;
      target?: '_blank';
      style: React.CSSProperties;
      onButtonClick: React.MouseEventHandler;
      onMouseEnter: () => void;
      onMouseLeave: () => void;
    }>) => {
      if (href === undefined) {
        return (
          <button style={style} onClick={onButtonClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {children}
          </button>
        );
      } else {
        return (
          <a
            href={href}
            target={target}
            style={style}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
          </a>
        );
      }
    };
  }, []);

  return (
    <Wrapper
      href={href}
      target={target}
      style={{
        borderRadius: '10px',
        padding: '0 24px',
        outline: 'none',
        background: backgroundColor,
        border: `1px solid ${borderColor}`,
        height: size === 'lg' ? '48px' : '32px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        cursor: loading || disabled ? 'not-allowed' : 'pointer',
      }}
      onButtonClick={handleButtonClick}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      {buttonText && (
        <Text
          tag="span"
          fontSize={size === 'lg' ? '16px' : '14px'}
          fontFamily="NewAvertaPE"
          color={textColor}
          fontWeight="700"
        >
          {buttonText}
        </Text>
      )}
    </Wrapper>
  );
}
