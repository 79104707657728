import * as React from 'react';
import { ScreenSizeEnum } from '../../../models/screen-size-enum';
import { CookiesSection } from './cookies-section';
import { DownloadAppButton } from '../../buttons/download-app-button';
import { getCookie, setCookie } from '../../../../common/utils/cookie';

const HIDE_COOKIE_CONSENT_NOTICE_KEY = 'hide-cookie-consent-notice';
const HIDE_COOKIE_CONSENT_NOTICE_VALUE = 'confirmed';

type Props = { screenSize: ScreenSizeEnum };

export const FixedBottomSection: React.FC<Props> = ({ screenSize }) => {
  const [displayCookiesSection, setDisplayCookiesSection] = React.useState(false);
  React.useEffect(() => {
    const consent = getCookie(HIDE_COOKIE_CONSENT_NOTICE_KEY);
    if (!consent) {
      setDisplayCookiesSection(true);
    }
  }, []);

  const handleCookiesConsent = React.useCallback(() => {
    setCookie(HIDE_COOKIE_CONSENT_NOTICE_KEY, HIDE_COOKIE_CONSENT_NOTICE_VALUE, 365 * 10);
    setDisplayCookiesSection(false);
  }, []);

  const handleCookiesCancel = React.useCallback(() => {
    setDisplayCookiesSection(false);
  }, []);

  return (
    <div style={{ position: 'fixed', zIndex: 9999 }}>
      {screenSize === ScreenSizeEnum.Desktop && (
        <div
          style={{
            position: 'fixed',
            left: 0,
            bottom: '34px',
            width: '100vw',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          {displayCookiesSection && (
            <CookiesSection
              onCancel={handleCookiesCancel}
              onConsent={handleCookiesConsent}
              copy="We use cookies to provide you with a better service and for promotional purposes. By continuing to use this
          site you consent to our use ad cookies as described on our "
              link="Terms of Service"
              width="1123px"
            />
          )}
        </div>
      )}
      {screenSize === ScreenSizeEnum.Tablet && (
        <div
          style={{
            position: 'fixed',
            left: 0,
            bottom: '20px',
            width: '100vw',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          {displayCookiesSection && (
            <CookiesSection
              onCancel={handleCookiesCancel}
              onConsent={handleCookiesConsent}
              copy="We use cookies to provide you with a better service and for promotional purposes. By continuing to use this
          site you consent to our use ad cookies as described on our "
              link="Terms of Service"
              width="calc(100vw - 32px)"
            />
          )}
        </div>
      )}
      {screenSize === ScreenSizeEnum.Mobile && (
        <div
          style={{
            position: 'fixed',
            left: 0,
            bottom: '24px',
            width: '100vw',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            gap: '13px',
          }}
        >
          {displayCookiesSection && (
            <CookiesSection
              onCancel={handleCookiesCancel}
              onConsent={handleCookiesConsent}
              copy="By browsing this site, you accept our "
              link="cookie policy"
              width="calc(100vw - 16px)"
            />
          )}
          <DownloadAppButton copy="Donwload PicCollage App" width="294px" height="44px" />
        </div>
      )}
    </div>
  );
};
