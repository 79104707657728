import React from "react";
import Header from "../legacy/components/header.js";
import BottomCta from "../legacy/components/BottomCta.js";
import {TextBanner} from "../legacy/components/textBanner.js";
import Showcase from "../legacy/components/showcase.js";
import {Footer} from "../legacy/components/footer.js";
import GridImages from "../legacy/components/gridImages.js";
import MainSection from "../legacy/components/MainSection/index.js";
import WindowSizeContext, { Provider as WindowSizeContextProvider } from "../legacy/contexts/WindowSizeContext.js";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
    };
    this.top = React.createRef();
    this.picCollageText = React.createRef();
    this.handleMainSectionResize = this.handleMainSectionResize.bind(this);
    this.apps_flyer_one_link_path = this.props.paths['apps_flyer_one_link_path'];
    const { asset_cdn_paths } = this.props;
    this.bottomDownloadBtnData = [
      {
        name: "appstore",
        eventTrackId: "bottom-apple-store-button",
        gaTagName: "bottom_ios_download_click",
        gaCategory: "apple_store_opens",
        gaLabel: "bottom download",
        imageSrc: asset_cdn_paths["badge-app-store"],
        imageAlt: "Download on the App Store",
        url: this.props.external_paths["appstore"]
      },
      {
        name: "googleplay",
        eventTrackId: "bottom-google-play-button",
        gaTagName: "bottom_android_download_click",
        gaCategory: "google_store_opens",
        gaLabel: "bottom download",
        imageSrc: asset_cdn_paths["badge-google"],
        imageAlt: "Get it on google play",
        url: this.props.external_paths["googleplay"]
      }
    ];
  }
  handleMainSectionResize() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleMainSectionResize);
    setTimeout(this.handleMainSectionResize, 0);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleMainSectionResize);
  }
  render() {
    const { asset_cdn_paths, windowSize } = this.props;
    const isOnMobileDevice = windowSize.width < 600;

    return (
      <div
        ref={this.top}
        className="landing-page-wrapper"
      >
        <Header
          {...this.props}
          windowWidth={this.state.windowWidth}
        />
        <main>
          <MainSection
            handleMainSectionResize={this.handleMainSectionResize}
            picCollageTextRef={this.picCollageText}
            external_paths={this.props.external_paths}
            enable_web_editor_link={this.props.enable_web_editor_link}
            apps_flyer_one_link_path={this.apps_flyer_one_link_path}
            appStoreImg={asset_cdn_paths["badge-app-store"]}
            googlePlayImg={asset_cdn_paths["badge-google"]}
            collageImg={asset_cdn_paths["collage"]}
          />
          <section className="block showcase-block">
            <Showcase
              phoneImg1={asset_cdn_paths["interface-1"]}
              phoneImg2={asset_cdn_paths["interface-2"]}
              phoneImg3={asset_cdn_paths["interface-3"]}
              phoneMainImg1={asset_cdn_paths["carousel-main-01"]}
              phoneMainImg2={asset_cdn_paths["carousel-main-02"]}
              phoneMainImg3={asset_cdn_paths["carousel-main-03"]}
              phoneInnerImg={asset_cdn_paths["phone"]}
              asset_cdn_paths={asset_cdn_paths}
            />
          </section>
          <TextBanner
            headline="Unleash Your Creativity"
            blockName="pen-icon icon-block with-triangle-up-bg"
          />
          <GridImages
            gridStickerImg={asset_cdn_paths["grid-image-1"]}
            gridCardImg={asset_cdn_paths["grid-image-2"]}
            gridBgImg={asset_cdn_paths["grid-image-3"]}
            gridLargeImg={asset_cdn_paths["grid-image-4"]}
            gridFontImg={asset_cdn_paths["grid-image-5"]}
          />
          <TextBanner
            headline="Come see what all the FUN is about! <br />Join the 200 Million PicCollage users around the world."
            blockName="download-app-text"
            btn={
              {
                text: "Download App",
                className: "download-btn",
                link: this.apps_flyer_one_link_path,
                eventTrackId: "bottom-download-app-button",
                gaTagName: "bottom_download_button_click",
                gaCategory: "mobile_store_opens",
                gaLabel: "bottom smartlink",
                buttons_info: this.bottomDownloadBtnData
              }
            }
          />
        </main>
        <Footer
          {...this.props.paths}
          {...this.props.external_paths}
          facebook_img={asset_cdn_paths["facebook_img"]}
          instagram_img={asset_cdn_paths["instagram_img"]}
          twitter_img={asset_cdn_paths["twitter_img"]}
          pinterest_img={asset_cdn_paths["pinterest_img"]}
          youtube_img={asset_cdn_paths["youtube_img"]}
          footer_logo_img={asset_cdn_paths["footer-logo"]}
        />
        {isOnMobileDevice && (
          <>
            <BottomCta apps_flyer_one_link_path={this.apps_flyer_one_link_path} />
            <div style={{ height: '65px' }} />
          </>
        )}
      </div>
    );
  }
}

export default class LandingPageWrapper extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <WindowSizeContextProvider>
        <WindowSizeContext.Consumer>
          {({ windowSize }) => {
            return <LandingPage
              {...this.props}
              windowSize={windowSize}
            />;
          }}
        </WindowSizeContext.Consumer>
      </WindowSizeContextProvider>
    );
  }
}