import * as React from 'react';
import { Text } from '../../../text';
import wordmark from './wordmark.webp';

type Props = {};

export function PatentSection({}: Props) {
  return (
    <div style={{ display: 'flex', flexFlow: 'row', gap: '9px', alignItems: 'center' }}>
      <Text tag="span" fontFamily="Montserrat" color="#4D4D4D" fontSize="14px" fontWeight="500">
        © 2024
      </Text>
      <img style={{ position: 'relative', top: '1px' }} src={wordmark} width="78px" height="12px" />
    </div>
  );
}
