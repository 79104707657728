import * as React from 'react';
import backgroundFrame from './background-frame.webp';
import backgroundVideo from './background-video.mp4';
import decoHero1 from './deco-hero-1.webp';
import decoHero2 from './deco-hero-2.webp';

type Props = {
  hasDecorations?: boolean;
};

export function BackgroundSection({ hasDecorations }: Props) {
  return (
    <section style={{ position: 'relative', width: '100%', height: '100%' }}>
      <img
        style={{ position: 'relative', zIndex: 2 }}
        alt="hero background border"
        src={backgroundFrame}
        width="100%"
      />
      <video
        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', objectFit: 'cover' }}
        width="100%"
        height="100%"
        autoPlay
        loop
        muted
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      {hasDecorations && (
        <img
          style={{ position: 'absolute', bottom: '-94px', left: '-36px', width: '248px', height: '248px', zIndex: 3 }}
          width="248px"
          height="248px"
          alt="hero decoration trees"
          src={decoHero1}
        />
      )}
      {hasDecorations && (
        <img
          style={{ position: 'absolute', bottom: '-60px', right: '-60px', width: '200px', height: '200px', zIndex: 3 }}
          width="200px"
          height="200px"
          alt="hero decoration gingerbread man"
          src={decoHero2}
        />
      )}
    </section>
  );
}
