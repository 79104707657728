import * as React from 'react';
import phoneMockup from './phone-mockup.webp';
import mobileImage from './mobile-image.webp';
import shadow from './shadow.webp';

type Props = {
  width: string;
  height?: string;
};

export function MobileSection({ width, height }: Props) {
  return (
    <section style={{ position: 'relative', display: 'inline-flex', borderRadius: '55px' }}>
      <img
        style={{ position: 'relative', zIndex: 3 }}
        alt="hero mobile"
        src={phoneMockup}
        width={width}
        height={height}
      />
      <img
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          width: '91%',
          borderRadius: '20px',
          zIndex: 2,
        }}
        alt="hero mobile collage screenshot"
        src={mobileImage}
        width="91%"
      />
      <img
        style={{
          position: 'absolute',
          left: '50%',
          bottom: '-41px',
          transform: 'translateX(-50%)',
          width: '500px',
          height: '98px',
          zIndex: 1,
        }}
        alt="hero mobile shadow"
        src={shadow}
        width="500px"
        height="98px"
      />
    </section>
  );
}
