import * as React from 'react';
import { ScreenSizeEnum } from '../../../models/screen-size-enum';
import { TitleSection } from './title-section/index';
import { CardInfoSection } from './card-info-section';
import { cardInfos } from './card-infos';
import { CardImageSection } from './card-image-section';
import { Desktop } from './desktop';
import { MOBILE_SIDE_PADDING, SECTION_GAP, TABLET_SIDE_PADDING } from '../../../styles/styles';

type Props = {
  screenSize: ScreenSizeEnum;
};

export const KeySellingPointsSection: React.FC<Props> = ({ screenSize }) => {
  return (
    <div>
      {screenSize === ScreenSizeEnum.Desktop && <Desktop />}
      {screenSize === ScreenSizeEnum.Tablet && (
        <div
          style={{
            padding: `${SECTION_GAP / 2}px ${TABLET_SIDE_PADDING}px ${SECTION_GAP / 2}px`,
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column',
          }}
        >
          <TitleSection
            fontSize="32px"
            lineHeight="40px"
            emphasisFontSize="32px"
            emphasisLineHeight="40px"
            decoImgTop="-32px"
            decoImgRight="-48px"
            breakLine={false}
          />
          <div
            style={{
              marginTop: '74px',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              gap: '68px',
            }}
          >
            {cardInfos.map((cardInfo) => (
              <div
                key={cardInfo.title}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                  gap: '24px',
                }}
              >
                <CardImageSection
                  imgSrc={cardInfo.imageSrc}
                  width={`calc(100vw - ${TABLET_SIDE_PADDING * 2}px)`}
                  maxWidth="712px"
                  height={`calc((100vw - ${TABLET_SIDE_PADDING}px) * 405 / 712)`}
                  maxHeight="405px"
                />
                <div style={{ display: 'inline-flex', width: '100%', maxWidth: '712px' }}>
                  <CardInfoSection
                    title={cardInfo.title}
                    titleFontSize="28px"
                    titleLineHeight="35px"
                    titleTextAlign="center"
                    description={cardInfo.description}
                    descriptionFontSize="18px"
                    descriptionLineHeightInPixel={28}
                    descriptionTextAlign="center"
                    hasDescription
                    titleDescriptionGap="8px"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {screenSize === ScreenSizeEnum.Mobile && (
        <div
          style={{
            padding: `${SECTION_GAP / 2}px ${MOBILE_SIDE_PADDING}px ${SECTION_GAP / 2}px`,
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column',
          }}
        >
          <TitleSection
            fontSize="28px"
            lineHeight="35px"
            emphasisFontSize="28px"
            emphasisLineHeight="28px"
            decoImgTop="-46px"
            decoImgRight="-32px"
            breakLine={true}
          />
          <div
            style={{
              marginTop: '32px',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              gap: '56px',
            }}
          >
            {cardInfos.map((cardInfo) => (
              <div
                key={cardInfo.title}
                style={{ width: '100%', display: 'flex', flexFlow: 'column', alignItems: 'center', gap: '28px' }}
              >
                <CardImageSection
                  imgSrc={cardInfo.imageSrc}
                  width={`calc(100vw - ${MOBILE_SIDE_PADDING * 2}px)`}
                  height={`calc((100vw - ${MOBILE_SIDE_PADDING * 2}px) * 405 / 712)`}
                />
                <CardInfoSection
                  title={cardInfo.title}
                  titleFontSize="24px"
                  titleLineHeight="25px"
                  titleTextAlign="center"
                  description={cardInfo.description}
                  descriptionFontSize="16px"
                  descriptionLineHeightInPixel={20}
                  descriptionTextAlign="center"
                  hasDescription
                  titleDescriptionGap="12px"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
