// Generated by CoffeeScript 2.6.1
(function() {
  (function($) {
    var base, base1;
    // -----------------------------------------------------------------------
    // Helpful utilities
    window.CB || (window.CB = {});
    (base = window.CB).Util || (base.Util = {});
    (base1 = window.CB).CookieHelper || (base1.CookieHelper = {});
    // Adapted from the javascript implementation at
    // http://sedition.com/perl/javascript-fy.html

    // Randomizes the order of elements in the passed in Array in place.
    window.CB.Util.shuffleArray = function(arr) {
      var i, j, temp;
      i = arr.length;
      while (--i >= 1) {
        j = Math.floor(Math.random() * (i + 1));
        temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
      }
      return arr;
    };

    window.CB.Util.copyToClipboard = function (text) {
      const tempInput = document.createElement('input');
      tempInput.value = text;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      tempInput.remove();
    }

    // -----------------------------------------------------------------------
    // CookieHelper
    window.CB.CookieHelper.getCookie = function(key) {
      var cookie, cookieArray, cookieName, k, len;
      cookieName = key + "=";
      cookieArray = document.cookie.split(';');
      for (k = 0, len = cookieArray.length; k < len; k++) {
        cookie = cookieArray[k];
        cookie = cookie.trim();
        if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(key.length + 1, cookie.length);
        }
      }
      return "";
    };
    window.CB.CookieHelper.setCookie = function(key, value, expirationDay, path) {
      var cookie, date, expiration, keyValue;
      // Key-value
      keyValue = key + "=" + value;
      
      // Expiration
      expirationDay || (expirationDay = 30); // default 30 days
      date = new Date();
      date.setTime(date.getTime() + expirationDay * 24 * 60 * 60 * 1000);
      expiration = "expires=" + date.toUTCString();
      // Path 
      path = "path=/" + (path || "");
      // Cookie example `consent=accept;expires=Fri, 22 Jun 2018 03:53:42 GMT;path=/`
      cookie = [keyValue, expiration, path].join(';');
      return document.cookie = cookie;
    };
    // -----------------------------------------------------------------------
    // jQuery extensions

    // Reorders lines of content on a textarea using a given filter
    return $.fn.reorderLines = function(reorderer) {
      var currentOrder;
      currentOrder = this.val().split('\n');
      return this.val(reorderer(currentOrder).join('\n'));
    };
  })(jQuery);

}).call(this);
