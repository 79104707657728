import React from "react";

const Context = React.createContext({
    windowSize: {
      width: undefined,
      height: undefined,
    },
});

export function Provider({ children }) {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    <Context.Provider
      value={{
        windowSize,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
