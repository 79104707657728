import * as React from 'react';

type DomRect = {
  top: number;
  left: number;
  right: number;
  bottom: number;
  x: number;
  y: number;
  width: number;
  height: number;
};

export function useDomRect(elem: HTMLElement | null): DomRect {
  const [domRect, setDomRect] = React.useState<DomRect>(() => ({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }));

  React.useEffect(() => {
    if (!elem) return;

    setDomRect(elem.getBoundingClientRect());
  }, [elem]);

  React.useEffect(() => {
    const handleScroll = () => {
      if (!elem) return;

      setDomRect(elem.getBoundingClientRect());
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [elem]);

  React.useEffect(() => {
    const handleResize = () => {
      if (!elem) return;

      setDomRect(elem.getBoundingClientRect());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [elem]);

  return domRect;
}
