import * as React from 'react';
import facebook from './facebook.webp';
import instagram from './instagram.webp';
import pinterest from './pinterest.webp';
import twitter from './twitter.webp';
import youtube from './youtube.webp';

type Props = {
  size: string;
  gap: string;
};

export function SocialsSection({ size, gap }: Props) {
  return (
    <div style={{ display: 'flex', flexFlow: 'row', gap }}>
      <a href="https://instagram.com/piccollage" target="__blank">
        <img src={instagram} width={size} height={size} />
      </a>
      <a href="https://www.pinterest.com/piccollage/" target="__blank">
        <img src={pinterest} width={size} height={size} />
      </a>
      <a href="https://www.facebook.com/piccollage" target="__blank">
        <img src={facebook} width={size} height={size} />
      </a>
      <a href="https://www.youtube.com/user/piccollage" target="__blank">
        <img src={youtube} width={size} height={size} />
      </a>
      <a href="https://twitter.com/PicCollage" target="__blank">
        <img src={twitter} width={size} height={size} />
      </a>
    </div>
  );
}
