import * as React from 'react';
import { TitleSection } from './title-section';
import { ScreenSizeEnum } from '../../../models/screen-size-enum';
import { cards } from './cards';
import { KeyMatrixCard } from './key-matrix-card';
import KeyMatricesCarousell from './key-matrices-carousell';
import {
  DESKTOP_SUBTITLE_FONT_SIZE,
  DESKTOP_TITLE_EMPHASIZE_FONT_SIZE,
  DESKTOP_TITLE_FONT_SIZE,
  DESKTOP_TITLE_LINE_HEIGHT,
  MOBILE_SIDE_PADDING,
  SECTION_GAP,
  TABLET_SIDE_PADDING,
} from '../../../styles/styles';

type Props = {
  screenSize: ScreenSizeEnum;
};

export const KeyCreativeToolsSection: React.FC<Props> = ({ screenSize }) => {
  return (
    <section>
      {screenSize === ScreenSizeEnum.Mobile && (
        <section style={{ padding: `${SECTION_GAP / 2}px ${MOBILE_SIDE_PADDING}px ${SECTION_GAP / 2}px` }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0px' }}>
            <TitleSection fontSize="24px" lineHeight="35px" emphasisFontSize="28px" emphasisLineHeight="35px" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '42px 0 0' }}>
            <div style={{ display: 'flex', flexFlow: 'column', gap: '16px' }}>
              {cards.map(({ image, imageWidth, imageHeight, iconImage, title, color }) => (
                <div
                  key={title}
                  style={{
                    scrollSnapAlign: 'center',
                    flexShrink: 0,
                  }}
                >
                  <KeyMatrixCard
                    image={image}
                    imageWidth={`${imageWidth}px`}
                    imageHeight={`${imageHeight}px`}
                    iconImage={iconImage}
                    iconSize="24px"
                    title={title}
                    titleFontSize="18px"
                    titleLineHeight="22px"
                    titleTop="32px"
                    titleLeft="32px"
                    width={`calc(100vw - ${MOBILE_SIDE_PADDING * 2}px)`}
                    height={`calc((100vw - ${MOBILE_SIDE_PADDING * 2}px) * 260 / 325)`}
                    borderRadius="18px"
                    backgroundColor={color}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      {screenSize === ScreenSizeEnum.Tablet && (
        <section
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            padding: `${SECTION_GAP / 2}px ${TABLET_SIDE_PADDING}px ${SECTION_GAP / 2}px`,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TitleSection fontSize="32px" lineHeight="48px" emphasisFontSize="36px" emphasisLineHeight="48px" />
          </div>
          <div
            style={{
              marginTop: '54px',
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <KeyMatricesCarousell />
          </div>
        </section>
      )}
      {screenSize === ScreenSizeEnum.Desktop && (
        <section style={{ padding: `${SECTION_GAP / 2}px 0 ${SECTION_GAP / 2}px` }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TitleSection
              fontSize={`${DESKTOP_TITLE_FONT_SIZE}px`}
              lineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
              emphasisFontSize={`${DESKTOP_TITLE_EMPHASIZE_FONT_SIZE}px`}
              emphasisLineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
            />
          </div>
          <div style={{ marginTop: '46px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              style={{
                width: '1240px',
                display: 'flex',
                flexFlow: 'column',
                gap: '32px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '32px',
                }}
              >
                <KeyMatrixCard
                  image={cards[0].image}
                  iconImage={cards[0].iconImage}
                  imageWidth={`${cards[0].imageWidth}px`}
                  imageHeight={`${cards[0].imageHeight}px`}
                  iconSize="40px"
                  title={cards[0].title}
                  titleFontSize={`${DESKTOP_SUBTITLE_FONT_SIZE}px`}
                  titleLineHeight="30px"
                  titleTop="52px"
                  titleLeft="57px"
                  width="768px"
                  height="324px"
                  borderRadius="50px"
                  backgroundColor={cards[0].color}
                />
                <KeyMatrixCard
                  image={cards[1].image}
                  iconImage={cards[1].iconImage}
                  imageWidth={`${cards[1].imageWidth}px`}
                  imageHeight={`${cards[1].imageHeight}px`}
                  iconSize="40px"
                  title={cards[1].title}
                  titleFontSize={`${DESKTOP_SUBTITLE_FONT_SIZE}px`}
                  titleLineHeight="30px"
                  titleTop="52px"
                  titleLeft="57px"
                  width="496px"
                  height="324px"
                  borderRadius="50px"
                  backgroundColor={cards[1].color}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '32px',
                }}
              >
                <KeyMatrixCard
                  image={cards[2].image}
                  iconImage={cards[2].iconImage}
                  imageWidth={`${cards[2].imageWidth}px`}
                  imageHeight={`${cards[2].imageHeight}px`}
                  iconSize="40px"
                  title={cards[2].title}
                  titleFontSize={`${DESKTOP_SUBTITLE_FONT_SIZE}px`}
                  titleLineHeight="30px"
                  titleTop="52px"
                  titleLeft="57px"
                  width="496px"
                  height="324px"
                  borderRadius="50px"
                  backgroundColor={cards[2].color}
                />
                <KeyMatrixCard
                  image={cards[3].image}
                  iconImage={cards[3].iconImage}
                  imageWidth={`${cards[3].imageWidth}px`}
                  imageHeight={`${cards[3].imageHeight}px`}
                  iconSize="40px"
                  title={cards[3].title}
                  titleFontSize={`${DESKTOP_SUBTITLE_FONT_SIZE}px`}
                  titleLineHeight="30px"
                  titleTop="52px"
                  titleLeft="57px"
                  width="768px"
                  height="324px"
                  borderRadius="50px"
                  backgroundColor={cards[3].color}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </section>
  );
};
