import * as React from 'react';
import { ScreenSizeEnum } from '../models/screen-size-enum';

/**
 * @returns The size of the current screen
 */
export function useScreenSize(): ScreenSizeEnum {
  const [windowWidth, setWindowWidth] = React.useState<number | null>(null);

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth || 0);
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (windowWidth === null) {
    return ScreenSizeEnum.None;
  } else if (windowWidth > 1280) {
    return ScreenSizeEnum.Desktop;
  } else if (windowWidth > 640) {
    return ScreenSizeEnum.Tablet;
  } else {
    return ScreenSizeEnum.Mobile;
  }
}
