import * as React from 'react';
import { TitleSection } from './title-section/index';
import { CardInfoSection } from './card-info-section';
import { cardInfos } from './card-infos';
import { CardImageSection } from './card-image-section';
import { useWindowSize } from '../../../hooks/use-window-size';
import {
  DESKTOP_SUBTITLE_FONT_SIZE,
  DESKTOP_TITLE_EMPHASIZE_FONT_SIZE,
  DESKTOP_TITLE_FONT_SIZE,
  DESKTOP_TITLE_LINE_HEIGHT,
} from '../../../styles/styles';

type Props = {};

export const Desktop: React.FC<Props> = ({}) => {
  const windowSize = useWindowSize();
  const windowHeight = windowSize?.height || 0;

  const wrapperHeight = 6000;

  const [wrapperDom, setWrapperDom] = React.useState<HTMLElement | null>(null);

  const cardCount = React.useMemo(() => cardInfos.length, [cardInfos]);

  /**
   * The scroll interval is the distance between
   * Start: when the top of wrapperDom touches the top of the screen
   * End: when the bottom of wrapperDom leaves the bottom of the screen
   */
  const animationScrollDistance = React.useMemo(() => wrapperHeight - windowHeight, [wrapperHeight, windowHeight]);
  const animationScrollInterval = React.useMemo(
    () => animationScrollDistance / cardCount,
    [animationScrollDistance, cardCount],
  );

  const cardIndexRef = React.useRef(0);
  const [normalizedCardIndex, setNormalizedCardIndex] = React.useState(0);

  const updateCardIndex = React.useCallback(() => {
    if (!wrapperDom) return;
    const wrapperDomRect = wrapperDom.getBoundingClientRect();

    const newCardIndex = -wrapperDomRect.top / animationScrollInterval;
    cardIndexRef.current = newCardIndex;

    const index = Math.floor(newCardIndex);
    if (index < 0) {
      setNormalizedCardIndex(0);
    } else if (index >= cardCount) {
      setNormalizedCardIndex(cardCount - 1);
    } else {
      setNormalizedCardIndex(index);
    }
  }, [wrapperDom, animationScrollInterval]);

  const [contentDom, setContentDom] = React.useState<HTMLElement | null>(null);
  const updateContentDomTop = React.useCallback(() => {
    if (!contentDom || !wrapperDom) return;

    const wrapperDomRect = wrapperDom.getBoundingClientRect();

    if (wrapperDomRect.top > 0) {
      contentDom.style.position = 'relative';
      contentDom.style.top = '0';
    } else if (wrapperDomRect.top < -animationScrollDistance) {
      contentDom.style.position = 'relative';
      contentDom.style.top = `${animationScrollDistance}px`;
    } else {
      contentDom.style.position = 'fixed';
      contentDom.style.top = '0';
    }
  }, [contentDom, animationScrollDistance]);

  const cardInfoSectionHeightWithoutDescription = 64;
  const cardInfoSectionFullHeight = 180;
  const cardImageSectionWidth = 720;
  const cardImageSectionHeight = 410;

  const [cardInfosSectionDom, setCardInfosSectionDom] = React.useState<HTMLElement | null>(null);

  const updateCardInfoSectionDomTop = React.useCallback(() => {
    if (!cardInfosSectionDom || !wrapperDom) return;

    const wrapperDomRect = wrapperDom.getBoundingClientRect();

    const animationScrollRatio = (animationScrollDistance / 2 + wrapperDomRect.top) / animationScrollDistance;
    const offset = Math.round(animationScrollRatio * (cardInfoSectionHeightWithoutDescription * (cardCount - 1)));
    cardInfosSectionDom.style.top = `${offset}px`;
  }, [cardInfosSectionDom, animationScrollDistance, cardInfoSectionFullHeight, cardCount]);

  const cardTitleElementId = React.useId();
  const getCardDoms = React.useCallback(() => {
    const doms: HTMLElement[] = [];

    cardInfos.forEach((cardInfo) => {
      const dom = document.getElementById(`${cardTitleElementId}-${cardInfo.title}`);
      if (dom) doms.push(dom);
    });

    return doms;
  }, [cardTitleElementId, cardInfos]);

  const updateCardDomOpacity = React.useCallback(() => {
    const cardDoms = getCardDoms();

    cardDoms.forEach((cardDom, cardDomIndex) => {
      cardDom.style.opacity = `${Math.max(1 - Math.abs(cardIndexRef.current - cardDomIndex - 0.5) * 0.7, 0.3)}`;
    });
  }, [getCardDoms]);

  /**
   * Because of the performance issue, we will directly update the style to the doms.
   */
  React.useEffect(() => {
    const handleWindowScroll = () => {
      updateCardIndex();
      updateContentDomTop();
      updateCardInfoSectionDomTop();
      updateCardDomOpacity();
    };

    handleWindowScroll();

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, [updateCardIndex, updateContentDomTop, updateCardInfoSectionDomTop, updateCardDomOpacity]);

  const handleCardInfoSectionClick = React.useCallback(
    (cardInfoTitle: string) => {
      return () => {
        if (!wrapperDom) return;

        const cardIndex = cardInfos.findIndex((cardInfo) => cardInfo.title === cardInfoTitle);
        if (cardIndex === -1) return;

        const wrapperDomRect = wrapperDom.getBoundingClientRect();
        window.scrollTo({
          top: Math.ceil(
            document.documentElement.scrollTop + wrapperDomRect.top + (cardIndex + 0.5) * animationScrollInterval,
          ),
          behavior: 'smooth',
        });
      };
    },
    [wrapperDom, cardInfos, animationScrollDistance],
  );

  return (
    <section ref={setWrapperDom} style={{ position: 'relative', height: wrapperHeight }}>
      <section
        ref={setContentDom}
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TitleSection
            fontSize={`${DESKTOP_TITLE_FONT_SIZE}px`}
            lineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
            emphasisFontSize={`${DESKTOP_TITLE_EMPHASIZE_FONT_SIZE}px`}
            emphasisLineHeight={`${DESKTOP_TITLE_LINE_HEIGHT}px`}
            decoImgTop="-32px"
            decoImgRight="-48px"
            breakLine={false}
          />
        </div>
        <section
          style={{
            marginTop: '123px',
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'center',
            gap: '32px',
          }}
        >
          <section
            style={{
              width: '499px',
              height: cardImageSectionHeight,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              ref={setCardInfosSectionDom}
              style={{
                position: 'relative',
                top: 0,
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
              }}
            >
              {cardInfos.map((cardInfo, cardInfoIndex) => (
                <section
                  id={`${cardTitleElementId}-${cardInfo.title}`}
                  key={cardInfo.title}
                  style={{
                    height:
                      cardInfoIndex === normalizedCardIndex
                        ? cardInfoSectionFullHeight
                        : cardInfoSectionHeightWithoutDescription,
                    display: 'inline-flex',
                    flexShrink: 0,
                    WebkitTransition: 'height 0.3s, opacity 0.3s',
                  }}
                >
                  <CardInfoSection
                    title={cardInfo.title}
                    titleFontSize={`${DESKTOP_SUBTITLE_FONT_SIZE}px`}
                    titleLineHeight="35px"
                    hasTitleIndex={cardInfoIndex === normalizedCardIndex}
                    description={cardInfo.description}
                    descriptionFontSize="20px"
                    descriptionLineHeightInPixel={28}
                    hasDescription={cardInfoIndex === normalizedCardIndex}
                    titleDescriptionGap="8px"
                    onClick={handleCardInfoSectionClick(cardInfo.title)}
                  />
                </section>
              ))}
            </div>
          </section>
          <section
            style={{
              position: 'relative',
              width: cardImageSectionWidth,
              height: cardImageSectionHeight,
              display: 'flex',
            }}
          >
            {cardInfos.map((cardInfo, cardInfoIndex) => (
              <section
                key={cardInfo.title}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  opacity: cardInfoIndex === normalizedCardIndex ? 1 : 0,
                  WebkitTransition: 'opacity 0.3s',
                }}
              >
                <CardImageSection
                  imgSrc={cardInfo.imageSrc}
                  width={cardImageSectionWidth}
                  height={cardImageSectionHeight}
                />
              </section>
            ))}
          </section>
        </section>
      </section>
    </section>
  );
};
