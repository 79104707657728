import * as React from 'react';
import {
  PicButton,
  PicColor,
  PicConfirmer,
  PicDivider,
  PicFontSize,
  PicFontWeight,
  PicTag,
  PicText,
} from '@cardinalblue/pic-collage-cms-ui';

type Props = {
  mode: 'edit' | 'read';
  itemName: { singular: string; plural: string };
  confirmSaveTitle?: string;
  confirmSaveMessage?: string;
  confirmCancelTitle?: string;
  confirmCancelMessage?: string;
  reorganizeButtonCopy?: string;
  title: string | React.ReactNode;
  subtitle?: string;
  tags?: string[];
  onSaveConfirm: (close: () => void) => void;
  onCancelConfirm: () => void;
  isEditButtonDisabled?: boolean;
  onEditClick: () => void;
  upperPanel?: React.ReactNode;
  panel?: React.ReactNode;
};

export function ReorganizeDataController({
  mode,
  itemName,
  confirmSaveTitle,
  confirmSaveMessage,
  confirmCancelTitle,
  confirmCancelMessage,
  reorganizeButtonCopy,
  title,
  tags = [],
  subtitle,
  onSaveConfirm,
  onCancelConfirm,
  isEditButtonDisabled = false,
  onEditClick,
  upperPanel,
  panel,
}: Props) {
  const isInEditMode = mode === 'edit';
  const picConfirmer = React.useMemo(() => new PicConfirmer(), []);

  return (
    <div style={{ display: 'flex', flexFlow: 'column' }}>
      <div
        style={{
          height: '35px',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          {typeof title === 'string' ? (
            <PicText size={PicFontSize.Lg} weight={PicFontWeight.Medium}>
              {title}
            </PicText>
          ) : (
            title
          )}
          {subtitle && <PicText size={PicFontSize.Lg}>{`(${subtitle})`}</PicText>}
          <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
            {tags.map((tag) => (
              <PicTag key={tag} copy={tag} color={PicColor.Teal500} />
            ))}
          </div>
        </div>
        <div style={{ display: 'flex', gap: '4px' }}>
          {!isInEditMode && (
            <PicButton
              type="primary"
              copy={reorganizeButtonCopy || 'Reorganize'}
              disabled={isEditButtonDisabled}
              onClick={onEditClick}
            />
          )}
          {isInEditMode && (
            <PicButton
              type="primary"
              copy="Save"
              onClick={async () => {
                let message = `Are you sure you want to save your changes to the ${itemName.plural}?`;
                if (confirmSaveMessage !== undefined) message = confirmSaveMessage;

                let title = 'Save Reorganization';
                if (confirmSaveTitle !== undefined) title = confirmSaveTitle;

                const [confirmed, close] = await picConfirmer.pop({ title, message });
                if (!confirmed) {
                  close();
                  return;
                }
                onSaveConfirm(close);
              }}
            />
          )}
          {isInEditMode && (
            <PicButton
              type="primary"
              outlined
              copy="Cancel"
              onClick={async () => {
                let message = `By canceling, you will lose your changes to the ${itemName.plural}.`;
                if (confirmCancelMessage !== undefined) message = confirmCancelMessage;

                let title = 'Cancel Reorganization';
                if (confirmCancelTitle !== undefined) title = confirmCancelTitle;

                const [confirmed, close] = await picConfirmer.pop({ title, message });
                close();
                if (!confirmed) {
                  return;
                }
                onCancelConfirm();
              }}
            />
          )}
        </div>
      </div>
      <PicDivider marginTop="16px" marginBottom={!!upperPanel || isInEditMode ? '16px' : '0'} />
      <div style={{ display: 'flex', flexFlow: 'column', gap: '20px' }}>
        {upperPanel}
        {isInEditMode && panel}
      </div>
    </div>
  );
}
