import React from "react";

export default class GridImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth:  0, 
      gridWrapperWidth: 0
    };
    this.gridWrapper = React.createRef();
    this.handleResize = this.handleResize.bind(this);
  }
  handleResize() {
    this.setState({
      screenWidth: window.innerWidth,
      gridWrapperWidth: this.gridWrapper.current.offsetWidth
    });
  }
  componentDidMount() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
  }
  componentWillUnmount() {
      window.removeEventListener("resize", this.handleResize);
  }
  render() {
    const BREAK_POINT_WIDTH = 650;
    const imageRatio = {w: 4, h: 3.37};

    let breakPoint = this.state.screenWidth <= BREAK_POINT_WIDTH ? 2 : 4;
    let gridHeight =
            (this.state.gridWrapperWidth / breakPoint / imageRatio.w) *
            imageRatio.h;

    return (
      <section ref={this.gridWrapper} className="grid-block block">
        <div
          className="gird-block-left"
          style={{
            gridTemplate: `${gridHeight}px ${gridHeight}px ${gridHeight}px/ 1fr 1fr`
          }}>
          <div style={{ backgroundImage: `url("${this.props.gridStickerImg}")`, backgroundSize: "cover" }} />
          <div>
            <span>New Stickers and <br/>Backgrounds <br/> Each Week</span>
          </div>
          <div>
            <span>Templates <br/>and Grids for <br/>Every Occasion</span>
          </div>
          <div style={{ backgroundImage: `url("${this.props.gridCardImg}")`, backgroundSize: "cover" }} />
          <div style={{ backgroundImage: `url("${this.props.gridBgImg}")`, backgroundSize: "cover" }} />
          <div>
            <span>Free to <br/> Download <br/> and Edit</span>
          </div>
        </div>
        <div
          className="grid-block-right"
          style={{
            gridTemplate: `${gridHeight *
                            2}px ${gridHeight}px/ 1fr 1fr`
          }}>
          <div className="large-image-in-grid">
            <img src={this.props.gridLargeImg} />
          </div>
          <div>
            <img src={this.props.gridFontImg} />
          </div>
          <div>
            <span>Customize <br/> with Cutouts <br/> and Doodles</span>
          </div>
        </div>
      </section>
    );
  }
}
