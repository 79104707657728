import { TemplateFilterVipStatusEnum } from './template-filter-vip-status-enum';
import { TemplateFilterAnimatedTypeEnum } from './template-filter-animated-type-enum';
import { TemplateFilterSizeCategoryEnum } from './template-filter-size-category-enum';
import { TemplateFilterSlotCountEnum } from './template-filter-slot-count-enum';

export class TemplateFilterSettingsVo {
  constructor(
    private vipStatus: TemplateFilterVipStatusEnum,
    private animatedType: TemplateFilterAnimatedTypeEnum,
    private sizeCategory: TemplateFilterSizeCategoryEnum,
    private slotCount: TemplateFilterSlotCountEnum,
  ) {}

  static createDefault() {
    return new TemplateFilterSettingsVo(
      TemplateFilterVipStatusEnum.All,
      TemplateFilterAnimatedTypeEnum.All,
      TemplateFilterSizeCategoryEnum.All,
      TemplateFilterSlotCountEnum.All,
    );
  }

  public isDefault(): boolean {
    return (
      this.vipStatus === TemplateFilterVipStatusEnum.All &&
      this.animatedType === TemplateFilterAnimatedTypeEnum.All &&
      this.sizeCategory === TemplateFilterSizeCategoryEnum.All &&
      this.slotCount === TemplateFilterSlotCountEnum.All
    );
  }

  public getVipStatus() {
    return this.vipStatus;
  }

  public updateVipStatus(vipStatus: TemplateFilterVipStatusEnum) {
    return new TemplateFilterSettingsVo(vipStatus, this.animatedType, this.sizeCategory, this.slotCount);
  }

  public getAnimatedType() {
    return this.animatedType;
  }

  public updateAnimatedType(animatedType: TemplateFilterAnimatedTypeEnum) {
    return new TemplateFilterSettingsVo(this.vipStatus, animatedType, this.sizeCategory, this.slotCount);
  }

  public getSizeCategory() {
    return this.sizeCategory;
  }

  public updateSizeCategory(sizeCategory: TemplateFilterSizeCategoryEnum) {
    return new TemplateFilterSettingsVo(this.vipStatus, this.animatedType, sizeCategory, this.slotCount);
  }

  public getSlotCount() {
    return this.slotCount;
  }

  public updateSlotCount(slotCount: TemplateFilterSlotCountEnum) {
    return new TemplateFilterSettingsVo(this.vipStatus, this.animatedType, this.sizeCategory, slotCount);
  }
}
