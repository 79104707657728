import { images } from './images';

export const usecases = [
  {
    key: 'Custom Birthdays',
    title: 'Custom Birthdays',
    description:
      'Making a birthday card with PicCollage means your memories with the special star of the day are front and center.',
    deco1: images.decos[5],
    deco2: images.decos[6],
    images: [
      {
        key: 'image-1',
        src: images.images[0],
      },
      {
        key: 'image-2',
        src: images.images[1],
      },
      {
        key: 'image-3',
        src: images.images[2],
      },
      {
        key: 'image-4',
        src: images.images[3],
      },
      {
        key: 'image-5',
        src: images.images[4],
      },
      {
        key: 'image-6',
        src: images.images[5],
      },
      {
        key: 'image-7',
        src: images.images[6],
      },
    ],
  },
  {
    key: 'Holiday Cards That Wow',
    title: 'Holiday Cards That Wow',
    description:
      'Create a memorable holiday card - even if you didn’t get that perfect pro photo this year. We have great templates and magic features to dress up any photo to make it holiday-card special. ',
    deco1: images.decos[0],
    deco2: images.decos[0],
    images: [
      {
        key: 'image-1',
        src: images.images[7],
      },
      {
        key: 'image-2',
        src: images.images[8],
      },
      {
        key: 'image-3',
        src: images.images[9],
      },
      {
        key: 'image-4',
        src: images.images[10],
      },
      {
        key: 'image-5',
        src: images.images[11],
      },
      {
        key: 'image-6',
        src: images.images[12],
      },
      {
        key: 'image-7',
        src: images.images[13],
      },
    ],
  },
  {
    key: 'Unique Photo Dumps',
    title: 'Unique Photo Dumps',
    description:
      'Share wrap-ups of big trips, monthly highlights, or your year-end-review with customizable layouts and templates that can support your whole camera roll! ',
    deco1: images.decos[1],
    deco2: images.decos[2],
    images: [
      {
        key: 'image-1',
        src: images.images[14],
      },
      {
        key: 'image-2',
        src: images.images[15],
      },
      {
        key: 'image-3',
        src: images.images[16],
      },
      {
        key: 'image-4',
        src: images.images[17],
      },
      {
        key: 'image-5',
        src: images.images[18],
      },
      {
        key: 'image-6',
        src: images.images[19],
      },
      {
        key: 'image-7',
        src: images.images[20],
      },
    ],
  },
  {
    key: 'Celebrate Everything',
    title: 'Celebrate Everything',
    description:
      'We love a holiday! But we also believe every moment is worth romanticizing. Our templates & stickers are curated to inspire you to document the little things in life too. ',
    deco1: images.decos[3],
    deco2: images.decos[4],
    images: [
      {
        key: 'image-1',
        src: images.images[21],
      },
      {
        key: 'image-2',
        src: images.images[22],
      },
      {
        key: 'image-3',
        src: images.images[23],
      },
      {
        key: 'image-4',
        src: images.images[24],
      },
      {
        key: 'image-5',
        src: images.images[25],
      },
      {
        key: 'image-6',
        src: images.images[26],
      },
      {
        key: 'image-7',
        src: images.images[27],
      },
    ],
  },
];

export function getUsecaseKeyByImage(imageKey: string) {
  const usecase = usecases.find((usecase) => {
    return usecase.images.some((image) => image.key === imageKey);
  });
  if (!usecase) return null;

  return usecase.key;
}

export function getUsecase(key: string) {
  const usecase = usecases.find((usecase) => usecase.key === key);
  if (!usecase) throw Error('Usecase not found!');

  return usecase;
}

export function getUsecaseImages(key: string) {
  const usecase = usecases.find((usecase) => usecase.key === key);
  if (!usecase) throw Error('Usecase not found!');

  return usecase.images;
}

export function getNextUsecase(key: string) {
  const usecaseIndex = usecases.findIndex((usecase) => usecase.key === key);
  if (usecaseIndex === -1) throw Error('Usecase not found!');

  return usecases[(usecaseIndex + 1) % usecases.length];
}

export function getPrevUsecase(key: string) {
  const usecaseIndex = usecases.findIndex((usecase) => usecase.key === key);
  if (usecaseIndex === -1) throw Error('Usecase not found!');

  return usecases[(usecaseIndex - 1 + usecases.length) % usecases.length];
}
