export const deduplicateArray = <T, K extends number | string>(arr: T[], identifier: (item: T) => K): T[] => {
  const map = new Map<string, T>();
  arr.forEach((item) => map.set(identifier(item).toString(), item));
  return Array.from(map.values());
};

/**
 * Given old array and new array, get new items the appear in new array.
 */
export const getAddedItemsInArray = <T, K extends number | string>(
  oldArr: T[],
  newArr: T[],
  identifier: (item: T) => K,
): T[] => {
  const oldItemIdentifiers = oldArr.map(identifier);
  return newArr.filter((item) => oldItemIdentifiers.indexOf(identifier(item)) === -1);
};

/**
 * Given old array and new array, get items the disappear in new array.
 */
export const getRemovedItemsInArray = <T, K extends number | string>(
  oldArr: T[],
  newArr: T[],
  identifier: (item: T) => K,
): T[] => {
  const newsItemIdentifiers = newArr.map(identifier);
  return oldArr.filter((item) => newsItemIdentifiers.indexOf(identifier(item)) === -1);
};

/**
 * Convert an array to key value map
 */
export const convertArrayToMap = <Item, ItemKey extends number | string>(
  items: Item[],
  itemKeyGetter: (item: Item) => ItemKey,
): Record<ItemKey, Item> => {
  return items.reduce<Record<ItemKey, Item>>(
    (prev, curr) => {
      prev[itemKeyGetter(curr)] = curr;
      return prev;
    },
    {} as Record<ItemKey, Item>,
  );
};
