import * as React from 'react';

type Props = {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'em' | 'strong';
  fontFamily?: 'Weekly' | 'NewAvertaPE' | 'Montserrat' | 'Rubik';
  lineHeight?: string;
  fontSize?: string;
  fontWeight?: string;
  underline?: boolean;
  whiteSpace?: string;
  textAlign?: 'center';
  color?: string;
  background?: string;
  backgroundClip?: string;
  textFillColor?: string;
  textShadow?: string;
};

export function Text({
  tag,
  fontFamily,
  lineHeight,
  fontSize,
  fontWeight,
  underline,
  whiteSpace,
  textAlign,
  color,
  background,
  backgroundClip,
  textFillColor,
  textShadow,
  children,
}: React.PropsWithChildren<Props>) {
  const Tag = React.useMemo(() => tag, [tag]);

  return (
    <Tag
      style={{
        margin: 0,
        fontFamily,
        lineHeight,
        fontSize,
        fontWeight,
        textDecoration: underline ? 'underline' : 'undefined',
        whiteSpace,
        textAlign,
        color,
        background,
        backgroundClip,
        WebkitBackgroundClip: backgroundClip,
        WebkitTextFillColor: textFillColor,
        textShadow,
      }}
    >
      {children}
    </Tag>
  );
}
