import * as React from 'react';
import { CommonContextProvider } from '../features/common/contexts/common-context';
import { StartFeedCardDto, parseStartFeedCardDto } from '../features/start-feed-cards/dtos/start-feed-card-dto';
import { PersistedStartFeedCardModel } from '../features/start-feed-cards/models/persisted-start-feed-card-model';
import { StartFeedCardCard } from '../features/start-feed-cards/components/start-feed-card-card';
import {
  PicButton,
  PicColor,
  PicConfirmer,
  PicCountriesInput,
  PicDivider,
  PicDropdown,
  PicFontSize,
  PicFontWeight,
  PicIconButton,
  PicIconName,
  PicNotifier,
  PicReorderItemsPanel,
  PicRouter,
  PicText,
  usePicBulkEditItems,
} from '@cardinalblue/pic-collage-cms-ui';
import { StartFeedCardApi } from '../features/start-feed-cards/apis/start-feed-card-api';
import { ActionableHeader } from '../features/common/components/headers/actionable-header';
import { CountryDto, parseCountryDto } from '../features/common/dtos/country-dto';
import { TemplateTagDto, parseTemplateTagDto } from '../features/common/dtos/template-tag-dto';
import { ReorganizeDataController } from '../features/common/components/controllers/reorganize-data-controller';
import { ContentRenderer } from '../features/common/components/renderers/content-renderer';

type Props = {
  formAuthenticityToken: string;
  countryDtos: CountryDto[];
  startFeedCardDtos: StartFeedCardDto[];
  templateTagDtos: TemplateTagDto[];
};

const ListStartFeedCardsPageContent: React.FC<Props> = ({
  formAuthenticityToken,
  countryDtos,
  startFeedCardDtos,
  templateTagDtos,
}) => {
  const picNotifier = React.useMemo(() => new PicNotifier(), []);
  const picRouter = React.useMemo(() => new PicRouter(), []);

  const startFeedCardApi = React.useMemo(() => StartFeedCardApi.create(), []);
  const templateTags = React.useMemo(() => templateTagDtos.map((dto) => parseTemplateTagDto(dto)), [templateTagDtos]);

  const countries = React.useMemo(() => countryDtos.map((dto) => parseCountryDto(dto)), [countryDtos]);

  const startFeedCardKeyGetter = React.useCallback(
    (startFeedCard: PersistedStartFeedCardModel) => startFeedCard.getId(),
    [],
  );
  const startFeedCardCloner = React.useCallback(
    (startFeedCard: PersistedStartFeedCardModel) => startFeedCard.clone(),
    [],
  );

  const sourceStartFeedCards = React.useMemo<PersistedStartFeedCardModel[]>(
    () => startFeedCardDtos.map((dto) => parseStartFeedCardDto(countries, dto)),
    [],
  );

  // Start feed cards that are currently live
  const liveStartFeedCards = React.useMemo(
    () => sourceStartFeedCards.filter((startFeedCard) => startFeedCard.isLive()),
    [sourceStartFeedCards],
  );

  const [filteredCountries, setFilteredCountries] = React.useState<string[]>([]);
  React.useEffect(() => {
    console.log(filteredCountries);
  }, [filteredCountries]);

  const startFeedCardFilter = React.useCallback(
    (startFeedCard: PersistedStartFeedCardModel) => {
      if (filteredCountries.length > 0) {
        if (startFeedCard.getIsCountriesExclusive()) {
          return filteredCountries.every((filteredCountry) => {
            return !startFeedCard.getCountries().includes(filteredCountry);
          });
        } else {
          return filteredCountries.every((filteredCountry) => {
            return startFeedCard.getCountries().includes(filteredCountry);
          });
        }
      }
      return true;
    },
    [filteredCountries],
  );

  const {
    draftItems: draftLiveStartFeedCards,
    filteredDraftItems: filteredDraftLiveStartFeedCards,
    selectedDraftItemKeys: selectedDraftLiveStartFeedCardKeys,
    selectDraftItems: selectDraftLiveStartFeedCards,
    hasFilteredOutDraftItems: hasFilteredOutDraftLiveStartFeedCards,
    reorderDraftItems: reorderDraftLiveStartFeedCards,
    resetAllDraftItems: resetAllDraftLiveStartFeedCards,
    deselectAllSelectedDraftItems: deselectAllSelectedDraftLiveStartFeedCards,
    moveSelectedDraftItemsToTop: moveSelectedDraftLiveStartFeedCardsToTop,
    moveSelectedDraftItemsToBottom: moveSelectedDraftLiveStartFeedCardsToBottom,
    updateSelectedDraftItems: updateSelectedDraftLiveStartFeedCards,
    updateDraftItem: updateDraftLiveStartFeedCard,
    canUndoDraftItemsChange: canUndoDraftLiveStartFeedCardsChange,
    undoDraftItemsChange: undoDraftLiveStartFeedCardsChange,
    canRedoDraftItemsChange: canRedoDraftLiveStartFeedCardsChange,
    redoDraftItemsChange: redoDraftLiveStartFeedCardsChange,
  } = usePicBulkEditItems({
    items: liveStartFeedCards,
    itemCloner: startFeedCardCloner,
    itemKeyGetter: startFeedCardKeyGetter,
    itemFilter: startFeedCardFilter,
  });

  // Start feed cards that will be live shorlty, for us also known as "draft"
  const preLiveStartFeedCards = React.useMemo(
    () => sourceStartFeedCards.filter((startFeedCard) => !startFeedCard.isLive()),
    [sourceStartFeedCards],
  );

  const filteredPreLiveStartFeedCards = React.useMemo(
    () => preLiveStartFeedCards.filter(startFeedCardFilter),
    [preLiveStartFeedCards, startFeedCardFilter],
  );

  const handleDraftLiveStartFeedCardIsPinnedToggle = React.useCallback(
    (draftLiveStartFeedCardId: number) => {
      return (isPinned: boolean) => {
        updateDraftLiveStartFeedCard(draftLiveStartFeedCardId, (prev) => {
          prev.updateIsPinned(isPinned);
          return prev;
        });
      };
    },
    [updateDraftLiveStartFeedCard],
  );

  const handleLiveStartFeedCardsSave = React.useCallback(async () => {
    const draftLiveStartFeedCardsWithPinning = draftLiveStartFeedCards.filter((card) => {
      const originalCard = liveStartFeedCards.find((c) => c.getId() === card.getId());
      if (!originalCard) return false;

      return originalCard.getIsPinned() !== card.getIsPinned();
    });

    await startFeedCardApi.bulkUpdate(draftLiveStartFeedCardsWithPinning);

    const error = await startFeedCardApi.updateOrder(draftLiveStartFeedCards.map((card) => card.getId()));
    if (error) {
      picNotifier.notify({
        type: 'error',
        message: error.message,
      });
      return;
    }

    window.location.reload();
  }, [startFeedCardApi, liveStartFeedCards, draftLiveStartFeedCards]);

  const picConfirmer = React.useMemo(() => new PicConfirmer(), []);

  const [liveStartFeedReorganizeMode, setLiveStartFeedReorganizeMode] = React.useState<'edit' | 'read'>('read');

  const [collapsed, setCollapsed] = React.useState(false);

  const handleStartFeedCardsDeleteClick = React.useCallback(
    (startFeedCard: PersistedStartFeedCardModel) => {
      return async (): Promise<void> => {
        const [confirmed, close] = await picConfirmer.pop({
          title: 'Delete Start Feed Card',
          message: `Are you sure you want to delete start feed card "${startFeedCard.getTitle()}"?`,
        });
        if (!confirmed) {
          close();
          return;
        }

        const error = await startFeedCardApi.delete(formAuthenticityToken, startFeedCard.getId());
        if (error) {
          close();
          return;
        }
        window.location.reload();
      };
    },
    [formAuthenticityToken, startFeedCardApi, picConfirmer],
  );

  const createStartFeedCardItemGroups = React.useMemo(
    () => [
      {
        title: null,
        items: [
          { label: 'Template Category', value: 'StartFeedTemplateCategoryCard' },
          { label: 'Link', value: 'StartFeedLinkCard' },
        ],
      },
    ],
    [],
  );
  const handleCreateStartFeedCardSelect = React.useCallback(
    (option: { label: string; value: string }) => {
      picRouter.redirect(`/admin/start_feed_cards/new?payload_type=${option.value}`);
    },
    [picRouter],
  );

  return (
    <div>
      <div style={{ marginBottom: '28px', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <PicButton
            type="primary"
            outlined={false}
            copy="Toggle Collapse"
            onClick={() => {
              setCollapsed((prev) => !prev);
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <PicText weight={PicFontWeight.Medium}>Include Countries</PicText>
            <div style={{ position: 'relative', width: '300px', display: 'flex', flexFlow: 'column', gap: '4px' }}>
              <PicCountriesInput
                value={filteredCountries}
                countries={countries.map((c) => ({ label: c.getIsoCode(), value: c.getIsoCode() }))}
                onInput={(val) => {
                  setFilteredCountries(val);
                }}
              />
              <div style={{ position: 'absolute', left: 0, bottom: -4, transform: 'translateY(100%)' }}>
                <PicText size={PicFontSize.Xs}>
                  If multiple countries are selected, only the cards that include all of them will be displayed
                </PicText>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '300px' }}>
          <PicDropdown
            value={null}
            placeholder="Create Card"
            itemGroups={createStartFeedCardItemGroups}
            itemLabelGetter={(item) => item.label}
            itemValueGetter={(item) => item.value}
            onSelect={handleCreateStartFeedCardSelect}
          />
        </div>
      </div>
      <div style={{ marginBottom: '48px', display: 'flex', flexFlow: 'column', gap: '20px' }}>
        <ReorganizeDataController
          mode={liveStartFeedReorganizeMode}
          title="Live Start Feed Cards"
          itemName={{ singular: 'live start feed card', plural: 'live start feed cards' }}
          onCancelConfirm={() => {
            resetAllDraftLiveStartFeedCards();
            deselectAllSelectedDraftLiveStartFeedCards();
            setLiveStartFeedReorganizeMode('read');
          }}
          onEditClick={() => {
            setLiveStartFeedReorganizeMode('edit');
          }}
          onSaveConfirm={handleLiveStartFeedCardsSave}
          panel={
            <div style={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', alignItems: 'center' }}>
                <PicIconButton
                  size="large"
                  iconName={PicIconName.Undo}
                  disabled={!canUndoDraftLiveStartFeedCardsChange}
                  onClick={undoDraftLiveStartFeedCardsChange}
                />
                <PicIconButton
                  size="large"
                  iconName={PicIconName.Redo}
                  disabled={!canRedoDraftLiveStartFeedCardsChange}
                  onClick={redoDraftLiveStartFeedCardsChange}
                />
                <PicButton type="primary" copy="Deselect" onClick={deselectAllSelectedDraftLiveStartFeedCards} />
                <PicButton
                  type="primary"
                  iconName={PicIconName.Up}
                  onClick={moveSelectedDraftLiveStartFeedCardsToTop}
                />
                <PicButton
                  type="primary"
                  iconName={PicIconName.Down}
                  onClick={moveSelectedDraftLiveStartFeedCardsToBottom}
                />
                <PicButton
                  type="primary"
                  copy="Pin / Unpin"
                  onClick={() => {
                    updateSelectedDraftLiveStartFeedCards((previousDraftLiveStartFeedCards) => {
                      const hasAnyPinnedCards = previousDraftLiveStartFeedCards.some((card) => card.getIsPinned());

                      previousDraftLiveStartFeedCards.forEach((card) => {
                        card.updateIsPinned(!hasAnyPinnedCards);
                      });

                      return previousDraftLiveStartFeedCards;
                    });
                  }}
                />
              </div>
              {hasFilteredOutDraftLiveStartFeedCards && (
                <PicText color={PicColor.Yellow500} weight={PicFontWeight.Medium}>
                  You cannot drag to reorder when any cards are filtered out by country filter
                </PicText>
              )}
            </div>
          }
        />

        <ContentRenderer
          loading={false}
          noContent={filteredDraftLiveStartFeedCards.length === 0}
          noContentCopy={
            draftLiveStartFeedCards.length > 0 ? 'No live start feed cards after filtering' : 'No live start feed cards'
          }
        >
          <PicReorderItemsPanel
            items={filteredDraftLiveStartFeedCards}
            column={1}
            selectedItemKeys={selectedDraftLiveStartFeedCardKeys}
            onSelectedItemKeysChange={selectDraftLiveStartFeedCards}
            onItemsReorder={(items) => {
              reorderDraftLiveStartFeedCards(items.map(startFeedCardKeyGetter));
            }}
            disableSelection={liveStartFeedReorganizeMode === 'read'}
            disableDragging={liveStartFeedReorganizeMode === 'read' || hasFilteredOutDraftLiveStartFeedCards}
            itemKeyGetter={startFeedCardKeyGetter}
            itemRenderer={({ item, itemIndex, selected, onSelect }) => (
              <StartFeedCardCard
                order={itemIndex + 1}
                collapsed={collapsed}
                selected={selected}
                startFeedCard={item}
                templateTags={templateTags}
                isEditing={liveStartFeedReorganizeMode === 'edit'}
                onClick={liveStartFeedReorganizeMode === 'edit' ? onSelect : undefined}
                onDeleteClick={
                  liveStartFeedReorganizeMode === 'read' ? handleStartFeedCardsDeleteClick(item) : undefined
                }
                onIsPinnedToggle={handleDraftLiveStartFeedCardIsPinnedToggle(item.getId())}
              />
            )}
          />
        </ContentRenderer>
      </div>
      <ActionableHeader title="Draft Start Feed Cards" />
      <PicDivider marginTop="12px" marginBottom="12px" />
      <ContentRenderer
        loading={false}
        noContent={filteredPreLiveStartFeedCards.length === 0}
        noContentCopy={
          preLiveStartFeedCards.length > 0 ? 'No draft start feed cards after filtering' : 'No draft start feed cards'
        }
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: '8px',
          }}
        >
          {filteredPreLiveStartFeedCards.map((startFeedCard, startFeedCardIndex) => (
            <StartFeedCardCard
              order={startFeedCardIndex + 1}
              collapsed={collapsed}
              selected={false}
              startFeedCard={startFeedCard}
              templateTags={templateTags}
              isEditing={liveStartFeedReorganizeMode === 'edit'}
              hidePinning
              onDeleteClick={
                liveStartFeedReorganizeMode === 'read' ? handleStartFeedCardsDeleteClick(startFeedCard) : undefined
              }
            />
          ))}
        </div>
      </ContentRenderer>
    </div>
  );
};

const ListStartFeedCardsPage: React.FC<Props> = (props) => {
  return (
    <CommonContextProvider>
      <ListStartFeedCardsPageContent {...props} />
    </CommonContextProvider>
  );
};

export default ListStartFeedCardsPage;
